import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes

// import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";
import componentsStyle from "../core-ui/core-styles/jss/general-component-style";
//import withStyles from "@material-ui/core/styles/withStyles";

import { withStyles } from "@material-ui/core/styles";

import DashboardContent from "./DashboardContent/DashboardContent";
import { withRouter } from "react-router";

//const defaultGlobalState = React.createContext(this.props.userName);

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pathname: "",
        };
    }
    //==================================================================================

    goTo = (pathname) => {
        this.props.history.push({
            pathname: pathname,
            state: {
                id: this.props.id,
            },
        });
    };
    //==================================================================================
    render() {
        return (
            <div
                style={{
                    margin: "0px",
                    padding: "0px",
                    height: "100vh",
                }}
            >
                <DashboardContent goTo={this.goTo} supplierId={this.props.id} />
            </div>
        );
    }
}
Dashboard.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(componentsStyle)(withRouter(Dashboard));
