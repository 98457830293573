import { alpha } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import COLORS from "../../../constants/colors";

// const CARD_HEIGHT = 330;
// const CARD_CONTENT_HEIGHT = 230;
// const CARD_HEADER_HEIGHT = (CARD_HEIGHT - CARD_CONTENT_HEIGHT) / 2;
// const CARD_FOOTER_HEIGHT = CARD_HEADER_HEIGHT;
const CARD_HEADER_HEIGHT = 51;
const CARD_FOOTER_HEIGHT = 51;

const styles = makeStyles({
    cardContainer: {
        height: "100%",
    },
    card: {
        overflow: "visible",
        position: "relative",
        height: `100%`,
    },
    // cardContent: {
    //     height: "100%",
    //     width: "100%",
    //     padding: "0",
    //     paddingBottom: "0 !important",
    //     display: "flex",
    //     flexDirection: "column",
    //     justifyContent: "center",
    //     alignItems: "center",
    // },

    cardHeader: {
        height: `${CARD_HEADER_HEIGHT}px`,
    },

    cardContent: {
        maxHeight: `calc(100% - ${CARD_HEADER_HEIGHT}px - ${CARD_FOOTER_HEIGHT}px)`,
        position: "relative",
    },

    cardActionArea: {
        height: `${CARD_FOOTER_HEIGHT}px`,
        textAlign: "right",
    },

    button: {
        padding: 0,
        marginRight: 16,
        color: COLORS.ACCENT,
        backgroundColor: "transparent",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },

    loader: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: `100%`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: alpha(COLORS.WHITE, 0.7),
    },
});

export default styles;
