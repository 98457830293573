import React, { useCallback, useEffect } from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";
import { DashboardCard } from "../../../core-ui/custom";
import EnhancedTable from "../../../components-lagacy/Table/EnhancedTable";
import { TASKS_STATUS } from "../../../constants/constants-lagacy";
import { formatDate } from "../../../helpers/date-utils";
import { getMerchandiserName } from "../../../services/firestore/Merchandiser";
import { Link } from "react-router-dom";

const TABLE_HEADERS = [
    { id: "id", label: "Visit No." },
    { id: "outletBranchName", label: "Outlet Branch", textEllepsis: true },
    { id: "date", label: "Date" },
    { id: "merchandiser", label: "Merchandiser" },
];

const MissedVisitsTable = ({ tasks, outletBranches, loading = false }) => {
    const classes = styles();
    const [rowsData, setRowsData] = React.useState([]);

    const init = useCallback(async () => {
        try {
            //reset
            setRowsData([]);
            if (tasks.length > 0 && outletBranches.length > 0) {
                setup(tasks, outletBranches);
            }
        } catch (error) {
            console.log(error);
        }
    }, [tasks, outletBranches]);

    const setup = async (tasks, outletBranches) => {
        //filter tasks by state
        const tasks_filtered = tasks.filter((task) => task.state === TASKS_STATUS.NO_SHOW);
        setupTableData(tasks_filtered, outletBranches);
    };

    const setupTableData = (tasks, outletBranches) => {
        const rowsData = tasks.map((task) => {
            const outletBranch = task.outlet_branch;
            return {
                id: task.task_id,
                outletBranchName: outletBranch.En_short_name,
                date: formatDate(task.date_time_from.toDate()),
                merchandiser: task.merchandiser ? getMerchandiserName(task.merchandiser) : "-",
            };
        });

        setRowsData(rowsData);
    };
    useEffect(() => {
        init();
    }, [init]);

    return (
        <DashboardCard
            title={`Missed Visits (${rowsData.length})`}
            type="table"
            classes={{
                card: classes.card,
                cardHeader: classes.cardHeader,
                cardContent: classes.cardContent,
                cardActionArea: classes.cardActionArea,
            }}
            footerComponent={
                <Link
                    className={classes.link}
                    // to={{
                    //     pathname: PATHS.INSOURCE_VISITS_MANAGEMENT,
                    //     // search: generateQueryParams(filterValues).toString(),
                    // }}
                >
                    {`View Details >`}
                </Link>
            }
        >
            <div className={classes.tableWrapper}>
                <EnhancedTable
                    columns={TABLE_HEADERS}
                    rows={rowsData}
                    actions={[]}
                    updateSelected={() => {}}
                    loading={loading}
                    selectionDisabled
                    toolbarDisabled
                    paginationDisabled
                    emptyMessage="No Missed Visits"
                />
            </div>
        </DashboardCard>
    );
};

MissedVisitsTable.propTypes = {
    classes: PropTypes.object,
};

export default MissedVisitsTable;
