// JS developers can check out:
// https://www.npmjs.com/package/iso3166-2-db

// to get country list.
import countryList from "iso3166-2-db/countryList/en";

let COUNTRY_LIST = Object.keys(countryList).map((countryKey) => ({
    value: countryList[countryKey].name,
    label: countryList[countryKey].name,
}));
//list of countries to put on top
let leadingCountries = ["Saudi Arabia", "Yemen", "Sudan", "Egypt", "Lebanon"];
COUNTRY_LIST = COUNTRY_LIST.sort((a, b) => {
    if (a.label === b.label) return 0;

    //if both a and b are from the leadingCountries, sort according to leadingCountries's order
    if (leadingCountries.includes(a.label) && leadingCountries.includes(b.label)) {
        const indexA = leadingCountries.findIndex((lc) => lc === a.label);
        const indexB = leadingCountries.findIndex((lc) => lc === b.label);
        if (indexA < indexB) return -1;
        if (indexA > indexB) return 1;
        return 0;
    }
    //if a or b is from leadingCountries, they must go first
    if (leadingCountries.includes(a.label)) return -1;
    if (leadingCountries.includes(b.label)) return 1;

    //regular Ascending sort
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
});

export { COUNTRY_LIST };
