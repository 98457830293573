import { makeStyles } from "@material-ui/styles";

const styles = makeStyles({
    exclusivityContracts: {
        padding: "15px",
    },
    header: {
        margin: "10px 0",
    },
});

export default styles;
