import { firestore as db } from "../firebase";

export let getCity = async (id) => {
    let city = null;
    try {
        city = await db.collection("City").doc(`${id}`).get();
    } catch (error) {
        console.log(error);
    }

    return city;
};

export let getCities = async (IDs) => {
    let cities = [];

    try {
        cities = await Promise.all(IDs.map(getCity));
    } catch (error) {
        console.log(error);
    }

    return cities;
};

export let getAllCities = async () => {
    try {
        let cities = [];
        cities = (await db.collection("City").get()).docs;
        return cities;
    } catch (error) {
        throw error;
    }
};
