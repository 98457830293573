import React from "react";
import { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import { darken, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { COLORS } from "../../constants/constants-lagacy";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { formatDate } from "../../helpers/date-utils";
import { ButtonCell } from "../Cell/Cells";
import useSelectProductsDialog from "../../hooks/use-select-product-dialog";
import ProductsDialog from "./ProductsDialog";
import { findMissingNumbers } from "../../helpers/array-helpers";

const useStyles = makeStyles({
    table: { tableLayout: "auto", whiteSpace: "nowrap" },
    tableCell: {
        color: "#FFFFFF",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingBottom: "5px",
        paddingTop: "5px",
        textAlign: "center",
        backgroundColor: COLORS.primary,
        position: "sticky",
        top: -16,
        zIndex: 100,
    },
    addButton: {
        textTransform: "none",
        color: "#FFFFFF",
        backgroundColor: COLORS.submit,
        marginBottom: "10px",
        "&:hover": {
            backgroundColor: darken(COLORS.submit, 0.2),
        },
    },
    autoCompleteList: {
        width: "100%",
        height: "100%",
    },
    textCell: {
        textAlign: "center",
    },
    buttonCell: {
        textAlign: "center",
        width: "25px",
        padding: 0,
    },

    submitButton: {
        textTransform: "none",
        color: "#FFFFFF",
        backgroundColor: COLORS.submit,
        marginBottom: "10px",
        "&:hover": {
            backgroundColor: darken(COLORS.submit, 0.2),
        },
    },
});

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: "12px",
        top: "12px",
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);
let tableHeads = [
    { id: "number", label: "No." },
    { id: "contract_id", label: "Contract No." },
    { id: "type", label: "Type" },
    { id: "description", label: "Description" },
    { id: "total_products", label: "Total" },
    { id: "products", label: "Products" },
    { id: "start", label: "Start Date" },
    { id: "end", label: "End Date" },
    { id: "actions", label: "" },
];
let ExtraDisplayDialog = (props) => {
    const {
        extraDisplaysSnapshot,
        products,
        contracts,
        // onProductSelect,
        // onContractSelect,
        // onExtraDisplayTypeSelect,
        // onDeleteRow,
        disabled,
    } = props;
    const classes = useStyles(props);

    const [extraDisplays, setExtraDisplays] = useState(extraDisplaysSnapshot);
    const [selectedExtraDisplayIndex, setSelectedExtraDisplayIndex] = useState(null);

    const addDisplay = () => {
        let newExtraDisplays = [...extraDisplays];

        // map extra display ot an array of numbers of displayID
        let displayIDs = newExtraDisplays.map((extraDisplay) => extraDisplay.displayID);
        //find missing numbers of displayID
        let missing = findMissingNumbers(displayIDs, 1);

        if (products.length === 0 || contracts.length === 0) return;

        newExtraDisplays.push({
            displayID: missing.length > 0 ? missing[0] : newExtraDisplays.length + 1,
            productsList: products,
            contracts: contracts,
            selectedProducts: [],
            selectedContract: 0,
            selectedType: 0,
            disabled: false,
        });

        //sort extra display by displayID
        newExtraDisplays.sort((a, b) => a.displayID - b.displayID);

        setExtraDisplays(newExtraDisplays);
    };

    const onContractSelect = (index, contractInfo) => {
        let newExtraDisplays = [...extraDisplays];
        let contracts = newExtraDisplays[index].contracts;
        let contractIndex = contracts.findIndex((c) => c.contract.contract_id === contractInfo.contract.contract_id);
        newExtraDisplays[index].selectedContract = contractIndex;
        setExtraDisplays(newExtraDisplays);
    };

    const onExtraDisplayTypeSelect = (index, exType) => {
        let newExtraDisplays = [...extraDisplays];
        let selectedContract = newExtraDisplays[index].selectedContract;
        let contracts = newExtraDisplays[index].contracts;

        let types = contracts[selectedContract].types;
        let typeIndex = types.findIndex((type) => type.extra_display_price_id === exType.extra_display_price_id);
        newExtraDisplays[index].selectedType = typeIndex;

        setExtraDisplays(newExtraDisplays);
    };

    const deleteDisplay = (index) => {
        let newExtraDisplays = [...extraDisplays];
        newExtraDisplays.splice(index, 1);
        setExtraDisplays(newExtraDisplays);
    };

    const handleProductsDialogOpen = (index) => {
        productsDialog.open(products, extraDisplays[index].selectedProducts);
        setSelectedExtraDisplayIndex(index);
    };
    const handleSave = (left, right) => {
        let extraDisplay = extraDisplays[selectedExtraDisplayIndex];
        let oldSelectedProducts = extraDisplay.selectedProducts;

        let newSelectedProducts = right.map((p) => {
            let oldProduct = oldSelectedProducts.find((product) => products[product.selectedProductIndex].id === p.id);
            if (oldProduct) {
                return oldProduct;
            } else {
                return {
                    selectedProductIndex: products.findIndex((product) => product.id === p.id),
                    referenceID: null,
                    referenceImage: null,
                };
            }
        });

        extraDisplay.selectedProducts = newSelectedProducts;

        setExtraDisplays((old) => [
            ...old.slice(0, selectedExtraDisplayIndex),
            extraDisplay,
            ...old.slice(selectedExtraDisplayIndex + 1),
        ]);
    };

    const productsDialog = useSelectProductsDialog(handleSave);

    return (
        <Dialog onClose={props.handleClose} open={props.open} fullWidth={true} maxWidth="xl">
            <DialogTitle
                id="customized-dialog-title"
                onClose={props.handleClose}
                style={{ padding: "20px 56px", textAlign: "center" }}
            >
                {props.title}
            </DialogTitle>
            <DialogContent dividers style={{ padding: "16px 24px" }}>
                {disabled ? null : (
                    <Button onClick={addDisplay} className={classes.addButton}>
                        Add Display
                    </Button>
                )}
                <Table>
                    <TableHead>
                        <TableRow style={{ backgroundColor: COLORS.primary }}>
                            {tableHeads.map((head) => (
                                <TableCell
                                    className={classes.tableCell}
                                    key={head.id}
                                    style={head.style}
                                    colSpan={head.colSpan ? head.colSpan : 1}
                                    rowSpan={head.rowSpan ? head.rowSpan : 1}
                                >
                                    {head.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {extraDisplays.map((item, index) => (
                            <TableRow key={`row-${item.displayID}`}>
                                {/* display number */}
                                <TableCell className={classes.textCell}>{item.displayID}</TableCell>
                                {/* contract */}
                                <TableCell>
                                    <Autocomplete
                                        disabled={item.disabled || false}
                                        autoSelect
                                        disableClearable
                                        id={`select-contract-${index}`}
                                        className={classes.autoCompleteList}
                                        options={item.contracts}
                                        value={item.contracts[item.selectedContract]}
                                        getOptionLabel={(option) => option.contract.contract_no}
                                        renderInput={(params) => <TextField {...params} multiline variant="outlined" />}
                                        onChange={(event, contract) => onContractSelect(index, contract)}
                                    />
                                </TableCell>

                                {/* extra display type */}
                                <TableCell>
                                    <Autocomplete
                                        disabled={item.disabled || false}
                                        autoSelect
                                        disableClearable
                                        id={`select-extra-diaply-type-${index}`}
                                        className={classes.autoCompleteList}
                                        options={item.contracts[item.selectedContract].types}
                                        value={item.contracts[item.selectedContract].types[item.selectedType]}
                                        getOptionLabel={(option) => option.extra_display_type}
                                        renderInput={(params) => <TextField {...params} multiline variant="outlined" />}
                                        onChange={(event, exType) => onExtraDisplayTypeSelect(index, exType)}
                                    />
                                </TableCell>
                                {/* description */}
                                <TableCell className={classes.textCell}>
                                    {item.contracts[item.selectedContract].contract.description}
                                </TableCell>
                                {/* total number of products */}
                                <TableCell
                                    className={classes.textCell}
                                >{`${item.selectedProducts.length}/${products.length}`}</TableCell>
                                {/* products */}
                                {/* <TableCell>
                                    <Autocomplete
                                        disabled={item.disabled || false}
                                        autoSelect
                                        disableClearable
                                        id={`select-product-${index}`}
                                        className={classes.autoCompleteList}
                                        options={item.productsList}
                                        value={item.productsList[item.selectedProduct]}
                                        getOptionLabel={(option) => option.data().En_name}
                                        renderInput={(params) => <TextField {...params} multiline variant="outlined" />}
                                        onChange={(event, product) => onProductSelect(index, product)}
                                    />
                                </TableCell> */}
                                <ButtonCell
                                    className={classes.buttonCell}
                                    text={`Select`}
                                    colorVariant="primary"
                                    clicked={() => handleProductsDialogOpen(index)}
                                />

                                {/* start date */}
                                <TableCell className={classes.textCell}>
                                    {formatDate(item.contracts[item.selectedContract].contract.date_from.toDate())}
                                </TableCell>
                                {/* end date */}
                                <TableCell className={classes.textCell}>
                                    {formatDate(item.contracts[item.selectedContract].contract.date_to.toDate())}
                                </TableCell>
                                {/* actions */}
                                {disabled ? null : (
                                    <TableCell className={classes.buttonCell}>
                                        <Button onClick={() => deleteDisplay(index)}>
                                            <DeleteIcon />
                                        </Button>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions style={{ padding: "8px 24px" }}>
                <Button
                    onClick={() => props.handleAction(extraDisplays)}
                    color="primary"
                    style={{ padding: "6px 0px" }}
                    className={classes.submitButton}
                >
                    {props.submitText}
                </Button>
            </DialogActions>
            {productsDialog.isOpened && (
                <ProductsDialog
                    handleClose={productsDialog.close}
                    open={productsDialog.isOpened}
                    save={productsDialog.save}
                    left={productsDialog.leftList}
                    right={productsDialog.rightList}
                    checked={productsDialog.selected}
                    disabled={false}
                />
            )}
        </Dialog>
    );
};

export default ExtraDisplayDialog;
