import { makeStyles } from "@material-ui/styles";

export default makeStyles({
    card: {
        width: "100%",
        margin: "10px 0",
        overflow: "visible",
        "& .MuiGrid-spacing-xs-1 > .MuiGrid-item": {
            "@media (min-width: 960px)": {
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
    },
});
