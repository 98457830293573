import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell, TableRow } from "@material-ui/core";
import { COLORS } from "../../../constants/constants-lagacy";

const useStyles = makeStyles({
    row: { backgroundColor: COLORS.background, borderTop: `2px solid ${COLORS.primary}` },
    cell: { textAlign: "center", fontWeight: "600", padding: "5px" },
});

let TotalVisitsRow = (props) => {
    const classes = useStyles(props);
    const { totalVisits } = props;
    const entries = Object.entries(totalVisits); // [["sun", 4], ....]
    const weeklyTotalVisits = entries.reduce((sum, entry) => (sum += entry[1]), 0);

    return (
        <TableRow className={classes.row}>
            <TableCell className={classes.cell}>Total Visits</TableCell>
            {entries.map((entry) => {
                let [day, total] = entry;
                return (
                    <TableCell key={`total-visit-at-${day}`} className={classes.cell}>
                        {total}
                    </TableCell>
                );
            })}
            <TableCell className={classes.cell}>{weeklyTotalVisits}</TableCell>
            <TableCell className={classes.cell} colSpan={5}></TableCell>
        </TableRow>
    );
};

TotalVisitsRow.propTypes = {
    classes: PropTypes.object,
};

export default TotalVisitsRow;
