import { Card } from "@material-ui/core";
import React from "react";
import { useMemo } from "react";
import COLORS from "../../../constants/colors";
import AnimatedNumbers from "react-animated-numbers";

//related to meterial ui package
import styles from "./styles";
import ReactSpeedometer from "react-d3-speedometer";
import { useRef } from "react";
import { useLayoutEffect } from "react";
import { useState } from "react";
import { CircularLoader, DashboardCard } from "../../../core-ui/custom";
import { memo } from "react";

const SKUAvailabilityGauge = ({ loading = false, maxValue, value, classes: customClasses = {}, ...otherProps }) => {
    const classes = styles();
    const ref = useRef();

    const [size, setSize] = useState([0, 200]);

    useLayoutEffect(() => {
        const updateSize = () => {
            setSize([ref?.current?.offsetWidth, ref?.current?.offsetHeight]);
        };

        window.addEventListener("resize", updateSize);
        setTimeout(() => {
            updateSize();
        }, 100);

        return () => window.removeEventListener("resize", updateSize);
    }, []);

    return (
        <DashboardCard
            title={"SKUs Availability"}
            classes={{
                cardContainer: classes.cardContainer,
                card: classes.card,
                cardHeader: classes.cardHeader,
                cardContent: classes.cardContent,
            }}
        >
            <div ref={ref} className={classes.gaugeContainer}>
                {loading && <CircularLoader />}
                {!loading && (
                    <ReactSpeedometer
                        maxValue={maxValue}
                        value={value}
                        needleColor="grey"
                        ringWidth={15}
                        customSegmentStops={[0, value, maxValue]}
                        segmentColors={["#99e6c1", COLORS.ACCENT]}
                        currentValueText={`${maxValue !== 0 ? ((value / maxValue) * 100).toFixed(2) : 0}%`}
                        customSegmentLabels={[
                            {
                                text: "Available",
                                position: "OUTSIDE",
                                fontSize: "11px",
                            },
                            {
                                text: "Out Of Stock",
                                position: "OUTSIDE",
                                fontSize: "11px",
                            },
                        ]}
                        forceRender={true}
                        width={size[0] - 15 <= 0 ? 0 : size[0] - 15}
                        height={size[0] - 15 < 230 ? 138 : 200}
                        {...otherProps}
                    />
                )}
            </div>
        </DashboardCard>
    );
};

export default memo(SKUAvailabilityGauge);
