import { storage } from "../services/firebase";

export let uploadFile = (path, filename, file) => {
    try {
        const storageRef = storage.ref();

        let uploadTask = storageRef.child(`${path}/${filename}`).put(file, { contentType: file.type });
        return uploadTask;
    } catch (error) {
        console.log(error);
    }
};
