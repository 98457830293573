import { makeStyles } from "@material-ui/styles";

const styles = makeStyles({
    dialogRoot: {
        // width: "100%",
        // overflowY: "hidden",
    },

    dialogTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid lightgrey",
    },

    cardContent: {
        padding: 0,
        paddingTop: "0 !important",
    },

    legends: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: " 0.8rem",
        margin: "10px 0",

        "& > span": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 7px",
        },

        "& > span:first-child:before": {
            content: "''",
            display: "inline-block",
            width: 20,
            height: 20,
            marginRight: 7,
            backgroundColor: "red",
        },

        "& > span:last-child:before": {
            content: "''",
            display: "inline-block",
            width: 20,
            height: 20,
            marginRight: 7,
            backgroundColor: "orange",
        },
    },

    tableWrapper: {
        height: "100%",
        overflow: "auto",
        backgroundColor: "white",

        "& table": {
            whiteSpace: "normal",
            height: "100%",
        },

        "& table th": {
            fontSize: ".8rem",
            whiteSpace: "nowrap",
        },

        "& table td": {
            fontSize: ".7rem",
        },

        // "& table td, & table th": {
        //     padding: "0 0 0 15px",
        // },
    },
});

export default styles;
