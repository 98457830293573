import React from "react";
import GridContainer from "../Grid/GridContainer.jsx";
import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../constants/constants-lagacy";

const useStyles = makeStyles({
    root: {
        margin: 0,
    },
    Container: {
        width: "100%",
        padding: "10px 20px",
    },

    main: {
        background: "#FFFFFF",
        position: "relative",
        zIndex: "3",
    },

    textCenter: {
        textAlign: "center",
    },

    Content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "white",
        width: "100%",
        minWidth: "560px",
        padding: "10px",
        borderRadius: "5px",
    },

    header: {
        color: "black",
        fontSize: "2.5em",
        textAlign: "center",
        margin: "0 0 10px 0",
    },
});

let ContentHeader = (props) => {
    const classes = useStyles(props);
    return (
        <GridContainer justifyContent="center" alignItems="center" className={classes.root}>
            <div className={classes.title} style={{ width: "40px", height: "30px" }}>
                <div
                    style={{
                        fontWeight: "700",
                        // backgroundColor: "#91041C",
                        color: "#EEEEEE",
                        height: "100%",
                        width: "100%",
                        paddingTop: "3px",
                        paddingBottom: "3px",
                        border: "2px solid #575757",
                        // borderRightColor: "#91041C",
                        // borderBottomColor: "#91041C",
                        backgroundColor: COLORS.primary,
                        borderRightColor: COLORS.primary,
                        borderBottomColor: COLORS.primary,
                    }}
                ></div>
            </div>
            <div className={classes.title} style={{ width: "90%", marginLeft: "2px" }}>
                <h4
                    style={{
                        textAlign: "left",
                        fontWeight: "700",
                        backgroundColor: COLORS.background,
                        color: COLORS.primary,
                        // color: "#91041C",
                        height: "30px",
                        paddingLeft: "20px",
                        paddingTop: "3px",
                        paddingBottom: "3px",
                        border: "1px solid #000000",
                    }}
                >
                    {props.title || ""}
                </h4>
            </div>
        </GridContainer>
    );
};

export default ContentHeader;
