import { makeStyles } from "@material-ui/styles";
import { darken } from "@material-ui/core/styles";
import COLORS from "../../constants/colors";

const styles = makeStyles({
    visitsPool: {
        padding: 8,
        margin: "0 auto",
    },
    header: {
        marginBottom: 8,
    },
    filterBtn: {
        backgroundColor: "transparent",
        padding: 0,
        margin: 8,
        color: "#aaa",
        "&:hover": {
            backgroundColor: "lightgray",
        },
    },

    filters: {
        padding: "8px 8px 8px 8px",
    },

    saveBtn: {
        backgroundColor: COLORS.SUBMIT,
        "&:hover": {
            backgroundColor: darken(COLORS.SUBMIT, 0.3),
        },
    },
    actions: {
        borderBottom: `1px solid lightgray`,

        marginBottom: 16,
    },
});

export default styles;
