import React from "react";

//related to meterial ui package
import styles from "./styles";

const Tabs = ({ onSelect, tabs, value, classes: customClasses = {} }) => {
    const classes = styles();

    return (
        <div className={`${classes.tabsContainer} ${customClasses.root ?? ""}`}>
            <div className={`${classes.tabs} ${customClasses.tabs}`}>
                {tabs.map((tabInfo, index) => {
                    return (
                        <div
                            key={`${tabInfo.label}-tab-${index}`}
                            className={`${classes.tab} ${customClasses.tab ?? ""} ${
                                index === value ? classes.selectedTab : ""
                            }`}
                            onClick={() => onSelect(index)}
                        >
                            <div className={`${classes.tabIcon} ${customClasses.tabIcon ?? ""}`}>{tabInfo.icon}</div>
                            <input
                                type="radio"
                                id={`tab-radio-${index}`}
                                name="tabs"
                                defaultChecked={index === value}
                            />
                            <label
                                className={`${classes.tabLabel} ${customClasses.tabLabel ?? ""}`}
                                htmlFor={`tab-radio-${index}`}
                            >
                                {tabInfo.label}
                                {/* {tabInfo.notification > 0 && (
                                    <span className={`${classes.notification} ${customClasses.notification ?? ""}`}>
                                        {tabInfo.notification}
                                    </span>
                                )} */}
                            </label>
                        </div>
                    );
                })}
                {/* <span
                    className={`${classes.glider} ${customClasses.glider ?? ""}`}
                    style={{ transform: `translateX(${value * 100}%` }}
                ></span> */}
            </div>
        </div>
    );
};

export default Tabs;
