import { alpha } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import COLORS from "../../../constants/colors";

const styles = makeStyles({
    formControlLabel: (props) => ({
        backgroundColor: "white",
        boxShadow: "0px 0px 3px lightgrey",
        padding: "4px 6px",
        margin: 0,

        border: `1px solid lightgrey`,
        color: COLORS.BLACK,
        transition: `color 0.2s ease-out, background-color 0.3s ease-out`,
        "&:hover": {
            backgroundColor: alpha(props.color, 0.1),
            color: alpha(props.color, 0.9),
        },

        "&$selected": {
            backgroundColor: alpha(props.color, 0.4),
            border: `1px solid ${props.color}`,
            color: props.textColor,
        },
    }),
    selected: (props) => ({}),

    formControlLabelText: {
        letterSpacing: "1px",
    },
});

export default styles;
