import React, { useState } from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import logo from "../../../assets/images/logo.png";
import "../../../core-ui/core-styles/pdf/fonts";
import styles from "./styles";
import { REPORT_IDS } from "../../../constants/constants-lagacy";
import { formatDate } from "../../../helpers/date-utils";
let rowsPerPage = 12;
let counter =0;


function timestamp(time) {
    let date = time.toDate()
    return date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
}



let createRow = (row, style) => {
    if (!row)
        return (
            <View style={style} key={`PDF Row ${Math.random()}-${row.id}`}>
                <Text style={styles.lastCell}>Error getting the merchandiser's data</Text>
            </View>
        );
        if (row.damageQty!=0 || row.expiredQty!=0 || row.nearExpiry !=0){
            counter++;
            return (
                <View style={style} key={`PDF Row ${Math.random()}-${row.id}`}>
                <View style={styles.indexCell}>
                        <Text>{counter}</Text>
                    </View>
                    <View style={styles.branchCell}>
                        <Text>{row.outletBranchName.En_short_name}</Text>
                    </View>
                    <View style={styles.rowProductGroup}>
                        <View style={styles.lastDataRowGroup}>
                            <View style={styles.columnGroup}>
                                <View style={styles.barcodeCell}>
                                    <Text>{row.barcode}</Text>
                                   
                                </View>
                                <View style={styles.productCell}>
                                    <Text>{row.productName}</Text>
                                  
                                </View>
                                <View style={styles.packingCell}>
                                    <Text>{row.packing}</Text>
                                 
                                </View>
                            </View>
                        </View>
                    </View>
                    <View>
                        <View style={styles.lastDataRowGroup}>
                            <View style={styles.columnGroup}>
                                <View style={styles.qtyCell}>
                                    <Text>{row.damageQty}</Text>
                                   
                                </View>
                                <View style={styles.qtyCell}>
                                    <Text>{row.expiredQty}</Text>
                                  
                                </View>
                            </View>
                        </View>
                    </View>
        
                    <View style={styles.lastRemarksCell}>
                        <Text>{row.remarks}</Text>
                    </View>
                </View>
        
            );

        }
  
};


let createPage = (pdfRows, info, pageNum) => {
    let rowsToDisplay = pdfRows.slice(pageNum * rowsPerPage, pageNum * rowsPerPage + rowsPerPage);

    const companyLogo = info.companyData.logo;
    const companyName = info.companyData.company_name;
    const start = formatDate(info.startDate, "/");
    const end = formatDate(info.endDate, "/");

    return (
        <Page size="A4" orientation="landscape" style={styles.page} key={`Damage and Expiry Report ${Math.random()}-${pageNum}`}>
            <View style={styles.logoContainer}>
                {companyLogo && <Image style={styles.companyLogo} src={companyLogo} />}
                <View>
                    <Text style={styles.supplierName}>{companyName}</Text>
                </View>
            </View>

            <View style={styles.line}></View>

            <Text style={styles.header}>Damage and Expiry Report</Text>

            <View style={styles.headerContainer}>
                <View style={styles.left}>
                    <Text>
                        Date from: &nbsp; <Text style={styles.bold}>{start}</Text> <span> &nbsp;</span> | Date to: &nbsp;<Text style={styles.bold}>{end}</Text>
                    </Text>
                </View>
            </View>

            <View style={styles.content}>
                <View style={styles.table}>
                    {/* Table Header */}
                    <View style={styles.tableHeader}>
                        <View style={styles.headerRow}>
                        <View style={styles.indexHeader}>
                                <Text style={styles.headerCellText}></Text>
                            </View>
                            <View style={styles.headerWidth}>
                                <Text style={styles.headerCellText}>Outlet Name</Text>
                            </View>

                            {/* Cell Group spans mulitple rows */}
                            <View style={styles.rowGroupProduct}>
                                <View style={styles.rowGroupRow}>
                                    <View style={styles.lastHeaderCell}>
                                        <Text style={styles.headerCellText}>Product</Text>
                                    </View>
                                </View>
                                <View style={styles.lastRowGroupRow}>
                                    {/* Cell Group spans mulitple column */}
                                    <View style={styles.columnGroup}>
                                        <View style={styles.barcodeWidth}>
                                            <Text style={styles.headerCellText}>Barcode</Text>
                                        </View>
                                        <View style={styles.headerWidth}>
                                            <Text style={styles.headerCellText}>Discription</Text>
                                        </View>
                                        <View style={styles.packingWidth}>
                                            <Text style={styles.headerCellText}>Packing</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.stockRowGroup}>
                                <View style={styles.rowGroupRow}>
                                    <View style={styles.lastHeaderCell}>
                                        <Text style={styles.headerCellText}>Stock Position</Text>
                                    </View>
                                </View>
                                <View style={styles.lastRowGroupRow}>
                                    {/* Cell Group spans mulitple column */}
                                    <View style={styles.columnGroup}>
                                        <View style={styles.qtyHeader}>
                                            <Text style={styles.headerCellText}>Dmg</Text>
                                        </View>
                                        <View style={styles.lastHeaderCell}>
                                            <Text style={styles.headerCellText}>Exp</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>

                            <View style={styles.lastRemarksHeaderCell}>
                                <Text style={styles.headerCellText}>Remarks</Text>
                            </View>


                        </View>
                    </View>



                    <View style={styles.tableBody}>
                        {rowsToDisplay.map((row, index) => {
                            let style = styles.row;
                            if (index % 2 !== 0 && index === rowsToDisplay.length - 1) style = styles.lastRow;
                            else if (index % 2 === 0 && index !== rowsToDisplay.length - 1) style = styles.coloredRow;
                            else if (index % 2 === 0 && index === rowsToDisplay.length - 1)
                                style = styles.lastColoredRow;
                                return createRow(row, style);
                            
                        })}
                    </View>
                </View>
            </View>
            {/* Footer */}
            <View style={styles.footerLine}></View>
            <View style={styles.idContainer}>
                <Text>{REPORT_IDS.EXPIRY_AND_DAMAGE}</Text>
            </View>
            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
            />

            <View style={styles.logoFooterContainer}>
                <View style={styles.logoFooterFlex}>
                    <Text style={styles.logoFooterText}>Powered By:</Text>

                </View>
                <View style={styles.logoFooterFlex}>
                    <Image style={styles.logo} src={logo} />
                </View>


            </View>
        </Page>
    );
};

let createDoc = (info) => {
    const { pdfRows } = info;

    let pages = [];
    let productsPerPage = Math.ceil(pdfRows.length / rowsPerPage);

    for (let pageNum = 0; pageNum < productsPerPage; pageNum++) {
        pages[pageNum] = createPage(pdfRows, info, pageNum);
    }

    return pages;
};

// Create Document Component
const DamageAndExpiryReportPdf = (props) => {
    let document = createDoc(props.info);
    return <Document>{document}</Document>;
};

export default DamageAndExpiryReportPdf;
