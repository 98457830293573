import React from "react";

//related to meterial ui package
import styles from "./styles";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@material-ui/core";
import { DateRangePicker } from "../../core-ui/custom";
import { Button } from "../../core-ui/custom";

const DateRangeDialog = ({ open, onClose, buffer, changeHandler, cancelHandler, applyHandler, ...otherProps }) => {
    const classes = styles();

    return (
        <Dialog maxWidth="lg" open={open} onClose={onClose} className={classes.dialogRoot}>
            {/* <DialogTitle disableTypography>
                <Typography className={classes.titleText} variant="h5">
                    Create a Visit
                </Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle> */}

            <DialogContent className={classes.cardContent}>
                <div className={classes.datePicker}>
                    <DateRangePicker ranges={[buffer]} onChange={changeHandler} {...otherProps} />
                    <div className={classes.dateRangeFooter}>
                        <Button className={classes.cancelBtn} onClick={cancelHandler}>
                            Cancel
                        </Button>
                        <Button className={classes.applyBtn} onClick={applyHandler}>
                            Apply
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default DateRangeDialog;
