import { alpha } from "@material-ui/core";
import styles from "./styles";

const getColor = (color) => {
    switch (color) {
        case "green":
            return "#4CAF50";
        case "yellow":
            return "#FFC107";
        case "red":
            return "#F44336";
        case "grey":
            return "#9E9E9E";
        default:
            return "#4CAF50";
    }
};

const GlowingDot = (props) => {
    const classes = styles();
    const { color, style, ...otherProps } = props;

    const dotColor = getColor(color);
    const dotStyle = {
        backgroundColor: "#fff",
        borderRadius: "50%",
        width: "10px",
        height: "10px",
        marginRight: "5px",
        marginLeft: "5px",
        boxShadow: `inset 0 0 10px 2px ${alpha(dotColor, 0.5)}, inset 0 0 10px 2px ${dotColor}`,
    };

    return <div className={classes.dot} style={{ ...dotStyle, ...style }} {...otherProps}></div>;
};

export default GlowingDot;
