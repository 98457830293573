// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import { Pie, ResponsivePie } from "@nivo/pie";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const PieChart = ({ data, settings, responsive = false, ...otherProps }) => {
    const defaults = {
        data: data,
        colors: data.map((d) => d.color),
        innerRadius: 0.5,
        padAngle: 0,
        cornerRadius: 3,
        activeOuterRadiusOffset: 8,
        borderWidth: 1,
        borderColor: {
            from: "color",
            modifiers: [["darker", 0.2]],
        },
        arcLinkLabelsSkipAngle: 10,
        arcLinkLabelsTextColor: "#333333",
        arcLinkLabelsThickness: 2,
        arcLinkLabelsColor: { from: "color" },
        arcLabelsSkipAngle: 10,
        arcLabelsTextColor: "#dedede",
        defs: [
            {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.1)",
                size: 4,
                padding: 1,
                stagger: true,
            },
            {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.1)",
                rotation: -45,
                lineWidth: 1,
                spacing: 10,
            },
        ],
        ...settings,
        ...otherProps,
    };
    if (responsive) {
        return <ResponsivePie {...defaults} />;
    }

    return <Pie width={settings.width ?? 500} height={settings.height ?? 500} {...defaults} />;
};

export default PieChart;
