import { makeStyles } from "@material-ui/core";

export default makeStyles({
    imageFileButtonWrap: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        // "& > span": {
        //     color: "red",
        //     fontSize: "0.6rem",
        // },
    },

    title: {
        textAlign: "center",
        fontSize: "0.8rem",
        color: "grey",
    },

    imageFileButton: {
        padding: 0,
        minWidth: "auto",
        maxWidth: "98px",
        height: "98px",
        // overflow: "hidden",

        "& span": {
            height: "100%",
        },
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
        },
    },

    imageLink: {
        maxWidth: "98px",
        maxHeight: "98px",
        overflow: "hidden",
        borderRadius: "5px",

        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
        },
    },
});
