import { Step, StepLabel, Stepper } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { StepConnector } from "..";
import FormNavigation from "../form-navigation";

const MultiStepForm = ({ initialValues, children, onSubmit, stepIcons, dirtyRequired = true }) => {
    const [stepNumber, setStepNumber] = useState(0);
    const steps = React.Children.toArray(children);

    // const [snapshot, setSnapshot] = useState(initialValues);
    const step = steps[stepNumber];
    const totalSteps = steps.length;
    const isLastStep = stepNumber === totalSteps - 1;

    const next = (values) => {
        // setSnapshot(values);
        setStepNumber((currentStep) => currentStep + 1);
    };

    const previous = (values) => {
        // setSnapshot(values);
        setStepNumber((currentStep) => currentStep - 1);
    };

    const handleBackClick = (values, helpers) => {
        helpers.setTouched({});
        previous(values);
    };

    const handleSubmit = async (values, helpers) => {
        if (step.props.onSubmit) {
            const isOK = await step.props.onSubmit(values);
            if (!isOK) return;
        }

        if (isLastStep) {
            return onSubmit(values, helpers);
        } else {
            helpers.setTouched({});
            next(values);
        }
    };

    return (
        <div style={{ maxWidth: "768px", width: "100%" }}>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={handleSubmit}
                validationSchema={step.props.validationSchema}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit}>
                        <Stepper
                            style={{ backgroundColor: "transparent" }}
                            alternativeLabel
                            activeStep={stepNumber}
                            connector={<StepConnector />}
                        >
                            {steps.map((currentStep) => {
                                const { stepName } = currentStep.props;

                                return (
                                    <Step key={`form-step:${stepName}`}>
                                        <StepLabel StepIconComponent={stepIcons}>{stepName}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        {step}
                        <FormNavigation
                            isLastStep={isLastStep}
                            hasPrevious={stepNumber > 0}
                            onBackClick={() => handleBackClick(formik.values, formik)}
                            isValid={formik.isValid}
                            isDirty={dirtyRequired ? formik.dirty : true}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default MultiStepForm;
