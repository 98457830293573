import React from "react";
import styles from "./WeekdayTabs.module.css";

let WeekdayTabs = (props) => {
    // const classes = useStyles();

    return (
        <fieldset className={styles.WeekdayTabs}>
            <div>
                <label>
                    <input
                        type="radio"
                        name="radio"
                        checked={props.days.sun}
                        onChange={() => props.onDaySelect("sun")}
                    />
                    <span>Sunday</span>
                </label>
                <label>
                    <input
                        type="radio"
                        name="radio"
                        checked={props.days.mon}
                        onChange={() => props.onDaySelect("mon")}
                    />
                    <span>Monday</span>
                </label>
                <label>
                    <input
                        type="radio"
                        name="radio"
                        checked={props.days.tue}
                        onChange={() => props.onDaySelect("tue")}
                    />
                    <span>Tuesday</span>
                </label>
                <label>
                    <input
                        type="radio"
                        name="radio"
                        checked={props.days.wed}
                        onChange={() => props.onDaySelect("wed")}
                    />
                    <span>Wednesday</span>
                </label>
                <label>
                    <input
                        type="radio"
                        name="radio"
                        checked={props.days.thu}
                        onChange={() => props.onDaySelect("thu")}
                    />
                    <span>Thursday</span>
                </label>
                <label className={styles.friday}>
                    <input
                        type="radio"
                        name="radio"
                        checked={props.days.fri}
                        onChange={() => props.onDaySelect("fri")}
                    />
                    <span>Friday</span>
                </label>
                <label>
                    <input
                        type="radio"
                        name="radio"
                        checked={props.days.sat}
                        onChange={() => props.onDaySelect("sat")}
                    />
                    <span>saturday</span>
                </label>
            </div>
        </fieldset>
    );
};

export default WeekdayTabs;
