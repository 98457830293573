import { useCallback } from "react";
import { useState } from "react";

Storage.prototype.setObject = function (key, value) {
    this.setItem(key, JSON.stringify(value));
};

Storage.prototype.getObject = function (key) {
    var value = this.getItem(key);
    return value && JSON.parse(value);
};

let INIT_LAYOUTS = localStorage.getObject("dashboard-layouts");

if (!INIT_LAYOUTS)
    INIT_LAYOUTS = {
        lg: [
            { i: "osa-indicator", x: 0, y: 0, w: 3, h: 1, minW: 2, maxW: 3, maxH: 1 },
            { i: "expired-damaged-indicator", x: 3, y: 0, w: 3, h: 1, minW: 2, maxW: 3, maxH: 1 },
            { i: "near-expiry-indicator", x: 6, y: 0, w: 3, h: 1, minW: 2, maxW: 3, maxH: 1 },
            { i: "compeleted-visits-indicator", x: 9, y: 0, w: 3, h: 1, minW: 2, maxW: 3, maxH: 1 },

            { i: "tasks-pie", x: 0, y: 1, w: 4, h: 4, minW: 4, maxW: 6, maxH: 5, minH: 4 },
            { i: "availability-gauge", x: 4, y: 1, w: 2, h: 4, minW: 2, maxW: 2, maxH: 5, minH: 4 },
            { i: "out-of-stock-line", x: 6, y: 1, w: 6, h: 4, minW: 3, maxW: 12, maxH: 4, minH: 4 },

            { i: "sku-expired-near-expired-line", x: 0, y: 2, w: 6, h: 4, minW: 3, maxW: 12, maxH: 4, minH: 4 },
            { i: "units-expired-near-expired-line", x: 6, y: 2, w: 6, h: 4, minW: 3, maxW: 12, maxH: 4, minH: 4 },

            { i: "out-of-stock-table", x: 0, y: 3, w: 3, h: 4, minW: 3, maxW: 8, maxH: 4, minH: 4 },
            { i: "expired-damaged-table", x: 3, y: 3, w: 3, h: 4, minW: 3, maxW: 8, maxH: 4, minH: 4 },
            { i: "near-expiry-table", x: 6, y: 3, w: 6, h: 4, minW: 3, maxW: 8, maxH: 4, minH: 4 },

            { i: "under-construction-1", x: 0, y: 4, w: 3, h: 4, minW: 3, maxW: 3, maxH: 4, minH: 4 },
            { i: "under-construction-2", x: 3, y: 4, w: 3, h: 4, minW: 3, maxW: 3, maxH: 4, minH: 4 },
        ],

        md: [
            { i: "osa-indicator", x: 0, y: 0, w: 3, h: 1, minW: 2, maxW: 3, maxH: 1 },
            { i: "expired-damaged-indicator", x: 3, y: 0, w: 3, h: 1, minW: 2, maxW: 3, maxH: 1 },
            { i: "near-expiry-indicator", x: 6, y: 0, w: 2, h: 1, minW: 2, maxW: 3, maxH: 1 },
            { i: "compeleted-visits-indicator", x: 8, y: 0, w: 2, h: 1, minW: 2, maxW: 3, maxH: 1 },

            { i: "tasks-pie", x: 0, y: 1, w: 4, h: 4, minW: 4, maxW: 6, maxH: 5, minH: 4 },
            { i: "availability-gauge", x: 4, y: 1, w: 2, h: 4, minW: 2, maxW: 2, maxH: 5, minH: 4 },
            { i: "out-of-stock-line", x: 6, y: 1, w: 4, h: 4, minW: 3, maxW: 12, maxH: 4, minH: 4 },

            { i: "sku-expired-near-expired-line", x: 0, y: 2, w: 5, h: 4, minW: 3, maxW: 12, maxH: 4, minH: 4 },
            { i: "units-expired-near-expired-line", x: 5, y: 2, w: 5, h: 4, minW: 3, maxW: 12, maxH: 4, minH: 4 },

            { i: "out-of-stock-table", x: 0, y: 3, w: 3, h: 4, minW: 3, maxW: 8, maxH: 4, minH: 4 },
            { i: "expired-damaged-table", x: 3, y: 3, w: 3, h: 4, minW: 3, maxW: 8, maxH: 4, minH: 4 },
            { i: "near-expiry-table", x: 6, y: 3, w: 4, h: 4, minW: 3, maxW: 8, maxH: 4, minH: 4 },

            { i: "under-construction-1", x: 0, y: 4, w: 3, h: 4, minW: 3, maxW: 3, maxH: 4, minH: 4 },
            { i: "under-construction-2", x: 3, y: 4, w: 3, h: 4, minW: 3, maxW: 3, maxH: 4, minH: 4 },
        ],
        sm: [
            { i: "osa-indicator", x: 0, y: 0, w: 3, h: 1, minW: 2, maxW: 3, maxH: 1 },
            { i: "expired-damaged-indicator", x: 3, y: 0, w: 3, h: 1, minW: 2, maxW: 3, maxH: 1 },

            { i: "near-expiry-indicator", x: 0, y: 1, w: 3, h: 1, minW: 2, maxW: 3, maxH: 1 },
            { i: "compeleted-visits-indicator", x: 3, y: 1, w: 3, h: 1, minW: 2, maxW: 3, maxH: 1 },

            { i: "tasks-pie", x: 0, y: 2, w: 4, h: 4, minW: 4, maxW: 6, maxH: 5, minH: 4 },
            { i: "availability-gauge", x: 4, y: 2, w: 2, h: 4, minW: 2, maxW: 2, maxH: 5, minH: 4 },

            { i: "out-of-stock-line", x: 0, y: 3, w: 6, h: 4, minW: 3, maxW: 12, maxH: 4, minH: 4 },

            { i: "sku-expired-near-expired-line", x: 0, y: 4, w: 3, h: 4, minW: 3, maxW: 12, maxH: 4, minH: 4 },
            { i: "units-expired-near-expired-line", x: 3, y: 4, w: 3, h: 4, minW: 3, maxW: 12, maxH: 4, minH: 4 },

            { i: "out-of-stock-table", x: 0, y: 5, w: 6, h: 4, minW: 3, maxW: 8, maxH: 4, minH: 4 },

            { i: "expired-damaged-table", x: 0, y: 6, w: 6, h: 4, minW: 3, maxW: 8, maxH: 4, minH: 4 },

            { i: "near-expiry-table", x: 0, y: 7, w: 6, h: 4, minW: 3, maxW: 8, maxH: 4, minH: 4 },

            { i: "under-construction-1", x: 0, y: 8, w: 3, h: 4, minW: 3, maxW: 3, maxH: 4, minH: 4 },
            { i: "under-construction-2", x: 3, y: 8, w: 3, h: 4, minW: 3, maxW: 3, maxH: 4, minH: 4 },
        ],
        xs: [
            { i: "osa-indicator", x: 0, y: 0, w: 3, h: 1, minW: 2, maxW: 3, maxH: 1 },
            { i: "expired-damaged-indicator", x: 3, y: 0, w: 3, h: 1, minW: 2, maxW: 3, maxH: 1 },

            { i: "near-expiry-indicator", x: 0, y: 1, w: 3, h: 1, minW: 2, maxW: 3, maxH: 1 },
            { i: "compeleted-visits-indicator", x: 3, y: 1, w: 3, h: 1, minW: 2, maxW: 3, maxH: 1 },

            { i: "tasks-pie", x: 0, y: 2, w: 4, h: 4, minW: 4, maxW: 6, maxH: 5, minH: 4 },
            { i: "availability-gauge", x: 4, y: 2, w: 2, h: 4, minW: 2, maxW: 2, maxH: 5, minH: 4 },

            { i: "out-of-stock-line", x: 0, y: 3, w: 6, h: 4, minW: 3, maxW: 12, maxH: 4, minH: 4 },

            { i: "sku-expired-near-expired-line", x: 0, y: 4, w: 6, h: 4, minW: 3, maxW: 12, maxH: 4, minH: 4 },

            { i: "units-expired-near-expired-line", x: 3, y: 5, w: 6, h: 4, minW: 3, maxW: 12, maxH: 4, minH: 4 },

            { i: "out-of-stock-table", x: 0, y: 6, w: 6, h: 4, minW: 3, maxW: 8, maxH: 4, minH: 4 },

            { i: "expired-damaged-table", x: 0, y: 7, w: 6, h: 4, minW: 3, maxW: 8, maxH: 4, minH: 4 },

            { i: "near-expiry-table", x: 0, y: 7, w: 8, h: 4, minW: 3, maxW: 8, maxH: 4, minH: 4 },

            { i: "under-construction-1", x: 0, y: 9, w: 3, h: 4, minW: 3, maxW: 3, maxH: 4, minH: 4 },
            { i: "under-construction-2", x: 3, y: 9, w: 3, h: 4, minW: 3, maxW: 3, maxH: 4, minH: 4 },
        ],
    };

const useDashboardLayout = () => {
    const [breakpoint, setBreakPoint] = useState("lg");
    const [layouts, setLayouts] = useState({ ...INIT_LAYOUTS });

    const layoutChangeHandler = useCallback((newLayout, allLayouts) => {
        setLayouts(allLayouts);
        localStorage.setObject("dashboard-layouts", allLayouts);
    }, []);

    const gridLayoutProps = {
        className: "layout",
        rowHeight: 85,
        margin: [10, 10],
        breakpoints: { lg: 1280, md: 960, sm: 720, xs: 480 },
        cols: { lg: 12, md: 10, sm: 6, xs: 6 },
        initialLayout: INIT_LAYOUTS[breakpoint],
        layouts: layouts,
        onLayoutChange: layoutChangeHandler,
        compactType: "vertical",
        onBreakpointChange: (b) => {
            setBreakPoint(b);
        },
    };

    return { gridLayoutProps };
};

export default useDashboardLayout;
