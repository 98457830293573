import { COLORS } from "../../../constants/constants-lagacy";
import { StyleSheet } from "@react-pdf/renderer";
import { colors } from "@material-ui/core";



// Create styles
const styles = StyleSheet.create({
    page: {
        // flexDirection: "row",
        // backgroundColor: "#E4E4E4",
        padding: "20px",
    },
    section: {
        margin: 10,
        padding: 10,
        // flexGrow: 1,
    },

    logoContainer: {
        display: "flex",
        width: "100%",
        height: "50px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },

    logo: {
        flex: "1",
        objectFit: "contain",
        objectPosition: "0",
    },
    eMaham: {
        flex: "1",
        textAlign: "right",
    },

    line: {
        width: "100%",
        height: "2px",
        // padding: "0 20px",
        marginTop: "5px",
        backgroundColor: "grey",
    },

    header: {
        color: COLORS.accent,
        textAlign: "center",
        fontSize: 16,
        textDecoration: "underline",
        margin: "20px 0",
    },

    headerContainer: {
        width: "100%",
        fontSize: 8,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },

    left: {
        flex: 1,
        textAlign: "left",
    },
    right: {
        flex: 1,
        textAlign: "right",
    },

    bold: {
        fontFamily: "Roboto",
        fontWeight: "bold",
    },

    table: {
        width: "100%",
        margin: "5px 0 0 0",
        border: 1,
        fontFamily: "Roboto",
    },
    footerLine: {
        position: "absolute",
        bottom: 59,
        left: 20,
        width: "100%",
        height: "2px",
        marginTop: "5px",
        backgroundColor: "grey",
    },
    logoFooterText: {
        fontSize: 9,
        justifyContent: "center",
        textAlign: "center",
    },
    logoFooterFlex:{
        display:"inline-block",
        padding:"4px"
    },
    
    logoFooterContainer: {
        position: "absolute",
        bottom: 27,
        right: 30,
        fontSize: 8,
        color: "grey",
        display:"flex",
        flexDirection:"row",

        height: "30px",
        justifyContent: "center",
    },

    headerRow: {
        display: "flex",
        flexDirection: "row",
        fontSize: 8,
        borderBottom: 1,
        backgroundColor: COLORS.primary,
        color: "white",
        fontWeight: "bold",
    },

    row: {
        display: "flex",
        flexDirection: "row",
        fontSize: 8,
        borderBottom: 1,
        fontWeight: "bold",
    },

    coloredRow: {
        display: "flex",
        flexDirection: "row",
        fontSize: 8,
        borderBottom: 1,
        backgroundColor: "#eef5ff",
        fontWeight: "bold",
    },

    lastRow: {
        display: "flex",
        flexDirection: "row",
        fontSize: 8,
        fontWeight: "bold",
    },

    lastColoredRow: {
        display: "flex",
        flexDirection: "row",
        fontSize: 8,
        backgroundColor: "#eef5ff",
        fontWeight: "bold",
    },

    headerCell: {
        flex: 1,
        height: "100%",
        textAlign: "center",
        padding: "5px 0px",
        borderRight: 1,

        display: "flex",
        justifyContent: "center",
    },
    headerNameCell:{
        flex:2,
        height: "100%",
        textAlign: "center",
        padding: "5px 0px",
        borderRight: 1,

        display: "flex",
        justifyContent: "center",

    },

    lastHeaderCell: {
        flex: 1,
        height: "100%",
        textAlign: "center",
        padding: "5px 0px",

        display: "flex",
        justifyContent: "center",
    },
    cellName:{
        flex: 2,
        height: "100%",
        textAlign: "center",
        padding: "5px 0",
        borderRight: 1,
        display: "flex",
        justifyContent: "center",
    },
    companyLogo: {
        width: "auto",
        height: "100%",
        display: "block",
        objectFit: "contain",
    },
    idContainer:{
        position: "absolute",
        bottom: 45,
        left:20,
        textAlign:"left",
        color:"#413F42",
        fontSize:"6px"
    },
    qtyHeader:{
        width:"38px",
        height: "100%",
        textAlign: "center",
        borderRight: 1,
        justifyContent: "center",
    },
    qtyLastHeader:{
        width:"38px",
        height: "100%",
        textAlign: "center",
        padding: "5px 0px",
        justifyContent: "center",
    },
    branchCell: {
        width:"120px",
        height: "100%",
        textAlign: "left",
        padding: "0 3px",
        borderRight: 1,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    },

    cell: {
        flex: 1,
        height: "100%",
        textAlign: "center",
        padding: "5px 0",
        borderRight: 1,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    },

    lastCell: {
        flex: 1,
        height: "100%",
        textAlign: "center",
        padding: "5px 0",

        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    },

    leftAlignCell: {
        flex: 1,
        height: "100%",
        textAlign: "left",
        padding: "5px 0",
        borderRight: 1,

        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    },
    // product row group:
    rowGroupProduct: {
        width:"280px",
        height: "40px",
        textAlign: "center",
        borderRight: 1,

        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        flexDirection: "column",
    },
    rowProductGroup:{
        width:"280px",
        height: "27px",
        textAlign: "center",
        borderRight: 1,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        flexDirection: "column",

    },
    indexCell:{
        width:"20px",
        height: "100%",
        textAlign: "center",
        padding: "5px 3px",
        borderRight: 1,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    },
    indexHeader:{
        width:"20px",
        height: "100%",
        textAlign: "center",
        padding: "5px 0px",
        borderRight: 1,
        justifyContent: "center",
    },
    qtyCell: {
        width:"38px",
        height: "100%",
        borderRight: 1,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    },
    qtyLastCell:{
        width:"40px",
        borderRight:1,
        height: "100%",
        padding: "5px 3px",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    },
    productCell: {
        width:"120px",
        height: "100%",
        textAlign: "left",
        padding: "0 3px",
        borderRight: 1,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    },
    barcodeCell: {
        width:"80px",
        height: "100%",
        padding: "5px 3px",
        borderRight: 1,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    },
    packingCell: {
        width:"73px",
        height: "100%",
        padding: "5px 3px",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    },

    rowGroup: {
        flex: 1,
        height: "40px",
        textAlign: "center",
        borderRight: 1,

        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        flexDirection: "column",
    },
    rowDataGroup:{
        flex: 1,
        height: "27px",
        textAlign: "center",
        borderRight: 1,

        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        flexDirection: "column",

    },
    lastDataRowGroup:{
        flex: 1,
        height: "27px",
        textAlign: "center",


        display: "flex",
        flexWrap:"wrap",
        flexDirection: "column",

    },

    lastRowGroup: {
        flex: 1,
        height: "40px",
        textAlign: "center",

        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
    },

    columnGroup: {
        flex: 1,
        height: "100%",
        textAlign: "center",

        display: "flex",
        flexWrap:"wrap",
        flexDirection: "row",
    },

    rowGroupRow: {
        flex: 1,
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        borderBottom: 1,
    },
    
    lastRowGroupRow: {
        flex: 1,
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
    },

    cellText: {
        margin: "0 5px",
    },

    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    },
    headerWidth:{
        width:"120px",
        height: "100%",
        textAlign: "center",
        padding: "5px 0px",
        borderRight: 1,
        justifyContent: "center",
    },
    barcodeWidth:{
        width:"80px",
        height: "100%",
        textAlign: "center",
        padding: "5px 0px",
        borderRight: 1,
        justifyContent: "center",
    },
    rowStockGroup:{
        height: "27px",
        textAlign: "center",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        flexDirection: "column",

    },
    stockRowGroup:{
        width:"76px",
        height: "40px",
        textAlign: "center",
        borderRight: 1,

        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        flexDirection: "column",

    },
    lastRemarksHeaderCell:{
        flex:1,
        height: "100%",
        textAlign: "center",
        padding: "5px 0px",

        display: "flex",
        justifyContent: "center",

    },
    lastRemarksCell:{
        flex:1,
        height: "100%",
        textAlign: "left",
        paddingLeft:"4px",

        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",

    },
    columnStockGroup: {
        width:"140px",
        height: "100%",
        textAlign: "center",

        display: "flex",
        flexWrap:"wrap",
        flexDirection: "row",
    },
    lastStockRowGroup:{
        width:"140px",
        height: "27px",
        textAlign: "center",

        display: "flex",
        flexWrap:"wrap",
        flexDirection: "column",

    },
    rowStockGroup:{
        width:"140px",
        height: "27px",
        textAlign: "center",
        borderRight: 1,

        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        flexDirection: "column",

    },
    packingWidth:{
        width:"70px",
        height: "100%",
        textAlign: "center",
        padding: "5px 0px",

        display: "flex",
        justifyContent: "center",
    },
    authorInfo: {
        position: "absolute",
        fontSize: 12,
        bottom: 15,
        left: 15,
        right: 0,
        textAlign: "left",
        color: "grey",
    },
    footer: {
        position: "absolute",
        bottom: 20,
        left: 20,
        fontSize: 8,
        display: "flex",
        color: "grey",
    },
});

export default styles;
