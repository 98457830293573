import { makeStyles } from "@material-ui/core";

export default makeStyles({
    loadWrap: {
        textAlign: "center",
    },

    fullPage: {
        width: "100vw",
        height: "100vh",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    letterHolder: {
        // padding: "16px",
    },

    letter: {
        animationName: "$loadingF",
        animationDuration: "1.6s",
        animationIterationCount: "infinite",
        animationDirection: "linear",
        float: "left",
        fontSize: "14px",
        color: "#777",
    },

    l1: {
        animationDelay: "0.48s",
    },
    l2: {
        animationDelay: "0.6s",
    },
    l3: {
        animationDelay: "0.72s",
    },
    l4: {
        animationDelay: "0.84s",
    },
    l5: {
        animationDelay: "0.96s",
    },
    l6: {
        animationDelay: "1.08s",
    },
    l7: {
        animationDelay: "1.2s",
    },
    l8: {
        animationDelay: "1.32s",
    },
    l9: {
        animationDelay: "1.44s",
    },
    l10: {
        animationDelay: "1.56s",
    },

    "@keyframes loadingF": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        },
    },
});
