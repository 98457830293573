import { makeStyles } from "@material-ui/styles";

const styles = makeStyles({
    subscriptionTable: {
        borderCollapse: "separate",
        borderSpacing: "0px 10px",
        tabkeLayout: "fixed",
        "& th": {
            width: "16.67%",
        },
    },
});

export default styles;
