import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";
import { Card, TableCell, TableRow } from "@material-ui/core";
import GlowingDot from "../../core-ui/custom/glowing-dot";
import { formatDate } from "../../helpers/date-utils";
import { Button } from "../../core-ui/custom";

const statusColor = (status) => {
    switch (status) {
        case "0":
            return "grey";
        case "1":
            return "green";
        case "2":
            return "yellow";
        case "3":
            return "red";
        default:
            return "green";
    }
};

const SubscriptionItem = (props) => {
    const classes = styles();
    const { subscription, openDialog } = props;
    const { date, start_date, end_date, free_slots, total_slots, status } = subscription;

    const handleClick = () => {
        openDialog(subscription);
    };
    return (
        <TableRow className={classes.row}>
            <TableCell align="center">{formatDate(date, "-")}</TableCell>
            <TableCell align="center">{formatDate(start_date, "-")}</TableCell>
            <TableCell align="center">{formatDate(end_date, "-")}</TableCell>
            <TableCell align="center">{`${total_slots - free_slots}/${total_slots}`}</TableCell>
            <TableCell align="center">
                <GlowingDot color={statusColor(subscription.status)} style={{ display: "inline-block" }} />
            </TableCell>
            <TableCell align="center">
                <Button onClick={handleClick}>Manage</Button>
            </TableCell>
        </TableRow>
    );
};

SubscriptionItem.propTypes = {
    classes: PropTypes.object,
};

export default SubscriptionItem;
