import { makeStyles } from "@material-ui/styles";
import React from "react";

const styles = makeStyles({
    seperator: {
        width: "100%",
        height: "1px",
        backgroundColor: "lightgrey",
    },
});

const Seperator = (props) => {
    const classes = styles();

    return <div className={classes.seperator}></div>;
};

export default Seperator;
