import { makeStyles } from "@material-ui/styles";

const styles = makeStyles({
    cardContainer: {},

    dashboardCard: {
        height: "300px",
        overflow: "visible",
        boxShadow:
            "0 0.46875rem 2.1875rem rgb(90 97 105 / 10%), 0 0.9375rem 1.40625rem rgb(90 97 105 / 10%), 0 0.25rem 0.53125rem rgb(90 97 105 / 12%), 0 0.125rem 0.1875rem rgb(90 97 105 / 10%)",
    },

    shadow: {
        boxShadow: "0 0 3px lightgrey",
    },

    cardHeader: {
        borderBottom: `1px solid lightgrey`,
    },

    cardTitle: {
        fontSize: "0.85rem",
        fontWeight: "bold",
        color: "black",
        letterSpacing: 1,
    },
    cardContent: {
        height: "calc(100% - 0px)",
        width: "100%",
        padding: "0",
        paddingBottom: "0 !important",
    },
    cardActionArea: {
        borderTop: `1px solid lightgrey`,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        fontSize: "0.875rem",
    },
});

export default styles;
