import React, { useState, useCallback, useEffect } from "react";

//related to meterial ui package
import styles from "./styles";
import Tooltip from "@material-ui/core/Tooltip";
const EnhancedTooltip = ({ children, ...otherProps }) => {
    const classes = styles();
    const [position, setPosition] = useState({ x: null, y: null });

    return (
        <Tooltip
            onMouseMove={(e) => setPosition({ x: e.pageX, y: e.pageY })}
            PopperProps={{
                anchorEl: {
                    clientHeight: 0,
                    clientWidth: 0,
                    getBoundingClientRect: () => ({
                        top: position.y,
                        left: position.x,
                        right: position.x,
                        bottom: position.y,
                        width: 0,
                        height: 0,
                    }),
                },
            }}
            {...otherProps}
        >
            {children}
        </Tooltip>
    );
};

export default EnhancedTooltip;
