import { makeStyles } from "@material-ui/core";
import React from "react";
import { Button } from "../../../core-ui/custom";

const styles = makeStyles({
    formNavigation: {
        display: "flex",
        marginTop: 50,
        justifyContent: "space-between",
    },
});

const FormNavigation = (props) => {
    const { hasPrevious, isLastStep, onBackClick, isDirty = true, isValid } = props;
    const classes = styles();

    return (
        <div className={classes.formNavigation}>
            {hasPrevious && (
                <Button type="button" onClick={onBackClick} buttonVariant="cancel">
                    Back
                </Button>
            )}

            <Button disabled={!isValid || !isDirty} type="submit">
                {isLastStep ? "Submit" : "Next"}
            </Button>
        </div>
    );
};

export default FormNavigation;
