import React, { Component } from "react";
import ReportTable from "../ReportTable/ReportTable";
import styles from "./styles.module.css";
import { StylesProvider } from "@material-ui/styles";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import CloseIcon from "@material-ui/icons/Close";
import MDRPdf from "../../../components-lagacy/PDF/merchandiser-daily-report";
// import MDRPdf from "../MDRPdf/MDRPdf";
import { Button } from "@material-ui/core";
import { generatePDFinNewTab } from "../../../helpers/pdf-utils";
import { getScheduleProductsByTask } from "../../../services/firestore/Project";

import { firestore as db } from "../../../services/firebase";
import AuthContext from "../../../contexts/auth-context";
import MDRSimplePdf from "../../../components-lagacy/PDF/merchandiser-daily-report-simple";

const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

let addLeadingZero = (number) => {
    return number < 10 ? "0" + number : number;
};

let formatDate = (date) => {
    const day = addLeadingZero(date.getDate());
    const month = addLeadingZero(date.getMonth() + 1);
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

let formatTime = (date) => {
    const hours = addLeadingZero(date.getHours());
    const minutes = addLeadingZero(date.getMinutes());
    const seconds = addLeadingZero(date.getSeconds());
    return `${hours}:${minutes}:${seconds}`;
};

let ImageTitle = (props) => {
    const { location, time, description } = props;

    return (
        <span className={styles.imageTitle}>
            <div className={styles.imageTitleLocation}>{location}</div>
            <div>{time}</div>
            <div>{description}</div>
        </span>
    );
};

class TaskProductsReportContent extends Component {
    static contextType = AuthContext;

    state = {
        date: formatDate(this.props.date),
        day: dayNames[this.props.date.getDay()],
        loading: true,
        city: this.props.city,
        outletBranch: this.props.outletBranch,
        task: {}, // {outletBranchId, taskId, supplierBranchId, projectId, timeIn, timeOut, details[]},
        //taskDetails => {product{code, name}, shelfQuantity, displayQuantity, warehouseDraw, shelfQuantiyAdded, displayQuantityAdded, shelfTotal, displayTotal}
        images: [],
        showGallery: false,
        showPDFLink: false,
    };

    init = async () => {
        // let products = (await db.collection("Product").get()).docs;
        // console.log(products.length);
        // products = products.filter((product) => !product.data().display_unit);
        // console.log(products.length);
        // products.forEach((product) => product.ref.update({ display_unit: "both" }));
    };
    componentDidMount() {
        this.init();
        const d = new Date();
        d.setHours(0, 0, 0, 0);
        const t = new Date();
        t.setHours(23, 59, 59, 59);
        db.collection("Task")
            .where("task_id", "==", Number(this.props.taskId))
            .get()
            .then(async (snapshot) => {
                if (snapshot.empty) {
                    return;
                }
                const task = snapshot.docs[0].data();
                const projectId = task.project_id;
                const supplierBranchId = task.supplier_branch_id;
                const outletBranchId = task.outlet_branch_id;
                const timeIn = new Date(task.date_time_from.seconds * 1000);
                const timeOut = new Date(task.date_time_to.seconds * 1000);
                let taskData = {
                    taskId: task.task_id,
                    projectId: projectId,
                    supplierBranchId: supplierBranchId,
                    outletBranchId: outletBranchId,
                    timeIn: formatTime(timeIn),
                    timeOut: formatTime(timeOut),
                    details: [],
                    options: task.options,
                };

                //get products docs

                //// let products = (await db
                ////     .collection(`Project/${projectId}/Branch_Outlet_Product/${supplierBranchId}/Outlet_Product/${outletBranchId}/Product`)
                ////     .get()).docs.map((doc) => doc.data());
                let products = [];
                if (task.only_header) {
                    products = (await getScheduleProductsByTask(task)).map((p) => p.data());
                } else {
                    products = (await db.collection("Task").doc(`${taskData.taskId}`).collection("Task_Product").get())
                        .docs;

                    products = products.map((p) => p.data());
                }

                //get the code and name for each product
                //// let prodDetails = await Promise.all(products.map(async (prod) => this.getProdDetails(prod.product_id)));
                let prodDetails = await Promise.all(products.map(async (prod) => this.getProdDetails(prod.id)));
                // get task Details
                let details = await Promise.all(
                    prodDetails.map(async (product) => this.getTaskDetails(taskData.taskId, product))
                );
                taskData.details = details;
                //put the images into the gallery
                let gallery = [];
                for (const row of details) {
                    let images = row.images.map((image) => {
                        return {
                            original: image.url,
                            thumbnail: image.url,
                            description: image.title,
                        };
                    });

                    gallery = [...gallery, ...images];
                }

                let supplier = await db.collection("Supplier").doc(`${task.supplier_id}`).get();
                let user = await db.collection("User").doc(this.props.uid).get();

                // let mp = await db.collection("MP").doc(task.mp_id).get();

                // console.log(tasks[0]);
                this.setState({
                    supplier: supplier.data(),
                    user: user.data(),
                    // mp: mp.data(),
                    task: taskData,
                    images: gallery,
                    loading: false,
                    showPDFLink: true,
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getTaskDetails = async (taskId, product) => {
        let details = {
            product: product,
            shelfQuantity: 0,
            displayQuantity: 0,
            warehouseDraw: 0,
            shelfQuantiyAdded: 0,
            displayQuantityAdded: 0,
            shelfTotal: 0,
            displayTotal: 0,
            images: [],
        };
        //get task details from Task_Details collection
        let taskDetails = (
            await db
                .collection("Task_Details")
                .where("task_id", "==", taskId)
                .where("product_id", "==", product.id)
                .get()
        ).docs;
        // if (taskDetails.length <= 0) {
        //     //get task details from Task_Details collection
        //     taskDetails = (await db
        //         .collection("Task_Details")
        //         .where("task_id", "==", taskId)
        //         .where("product_id", "==", product.id)
        //         .get()).docs;
        // }

        if (taskDetails.length <= 0) {
            return details;
        }

        // console.log(taskDetails[0].data(), '================');

        // let {
        //     quantity_on_shelf,
        //     quantity_on_extra_display,
        //     warehouse_actual_draw,
        //     // shelf_warehouse_draw,
        //     // extra_dis_warehouse_draw,
        //     extra_display_picture_after,
        //     extra_display_picture_before,
        //     shelf_picture_after,
        //     shelf_picture_before,
        // } = taskDetails[0].data();

        let data = {
            quantity_on_shelf: 0,
            quantity_on_extra_display: 0,
            warehouse_actual_draw: 0,
            //! new
            quantity_added_on_shelf: 0,
            quantity_added_on_extra_display: 0,

            availability_on_shelf_before: false,
            availability_on_extra_display_before: false,
            availability_on_shelf_after: false,
            availability_on_extra_display_after: false,

            // shelf_warehouse_draw,
            // extra_dis_warehouse_draw,
            extra_display_picture_after: [],
            extra_display_picture_before: [],
            shelf_picture_after: [],
            shelf_picture_before: [],
        };

        for (const td of taskDetails) {
            let taskProductData = td.data();

            //for extra display
            if (taskProductData.extra_display) {
                if (taskProductData.picture_before)
                    data.extra_display_picture_before.push({
                        url: taskProductData.picture_before,
                        title: <ImageTitle location="Extra Display" time="Before" description={product.name} />,
                    });
                if (taskProductData.picture_after)
                    data.extra_display_picture_after.push({
                        url: taskProductData.picture_after,
                        title: <ImageTitle location="Extra Display" time="After" description={product.name} />,
                    });

                data.quantity_on_extra_display += taskProductData.quantity_on;
                data.quantity_added_on_extra_display += taskProductData.quantity_added || 0;

                data.availability_on_extra_display_before =
                    data.availability_on_extra_display_before || taskProductData.quantity_availability;
                data.availability_on_extra_display_after =
                    data.availability_on_extra_display_after || taskProductData.added_availability;

                //for shelf
            } else {
                if (taskProductData.picture_before)
                    data.shelf_picture_before.push({
                        url: taskProductData.picture_before,
                        title: <ImageTitle location="Shelf" time="Before" description={product.name} />,
                    });
                if (taskProductData.picture_after)
                    data.shelf_picture_after.push({
                        url: taskProductData.picture_after,
                        title: <ImageTitle location="Shelf" time="After" description={product.name} />,
                    });

                data.quantity_added_on_shelf += taskProductData.quantity_added || 0;
                data.quantity_on_shelf += taskProductData.quantity_on;

                data.availability_on_shelf_before =
                    data.availability_on_shelf_before || taskProductData.quantity_availability;
                data.availability_on_shelf_after =
                    data.availability_on_shelf_after || taskProductData.added_availability;
            }

            //this value is the same across the same product
            if (!data.warehouse_actual_draw) {
                data.warehouse_actual_draw = taskProductData.warehouse_actual_draw;
            }
        }
        // let convertToPieces =
        //     (product.outer_unit || 1) * (product.packing.packing.unit || 1) * (product.packing.no_units || 1);
        // let shelfQuantiyAdded = warehouse_actual_draw.shelf * convertToPieces;
        let shelfQuantiyAdded = data.quantity_added_on_shelf;
        // let displayQuantityAdded = warehouse_actual_draw.extra_display * convertToPieces;
        let displayQuantityAdded = data.quantity_added_on_extra_display;
        details = {
            product: product,
            // shelfQuantity: quantity_on_shelf,
            shelfQuantity: data.quantity_on_shelf,

            // displayQuantity: quantity_on_extra_display,
            displayQuantity: data.quantity_on_extra_display,
            // warehouseDraw: warehouse_actual_draw.shelf + warehouse_actual_draw.extra_display,
            warehouseDraw: data.warehouse_actual_draw,
            shelfQuantiyAdded: shelfQuantiyAdded,
            displayQuantityAdded: displayQuantityAdded,
            // shelfTotal: shelfQuantiyAdded + quantity_on_shelf,
            shelfTotal: shelfQuantiyAdded + data.quantity_on_shelf,
            // displayTotal: displayQuantityAdded + quantity_on_extra_display,
            displayTotal: displayQuantityAdded + data.quantity_on_extra_display,

            availability_on_shelf_before: data.availability_on_shelf_before,
            availability_on_extra_display_before: data.availability_on_extra_display_before,
            availability_on_shelf_after: data.availability_on_shelf_after,
            availability_on_extra_display_after: data.availability_on_extra_display_after,

            images: [
                ...data.shelf_picture_before,
                ...data.shelf_picture_after,
                ...data.extra_display_picture_before,
                ...data.extra_display_picture_after,
            ].filter((image) => image.url != null),
            // images: [
            //     {
            //         url: extra_display_picture_before,
            //         title: `Extra Display Before - ${product.name}`,
            //     },
            //     {
            //         url: extra_display_picture_after,
            //         title: `Extra Display After - ${product.name}`,
            //     },
            //     {
            //         url: shelf_picture_before,
            //         title: `Shelf Before - ${product.name}`,
            //     },
            //     {
            //         url: shelf_picture_after,
            //         title: `Shelf After - ${product.name}`,
            //     },
            // ].filter((image) => image.url != null),
        };

        return details;
    };

    getProdDetails = async (productId) => {
        let productData = (await db.collection("Product").doc(productId).get()).data();
        return {
            id: productId,
            code: productData.product_number,
            barcode: productData.barcode,
            name: productData.En_name,
            outer_unit: productData.outer_unit,
            packing: productData.packing,
        };
    };

    getOutletBranches = async (outletBranchId) => {
        return (await db.collection("Outlet_Branch").doc(outletBranchId).get()).data();
    };

    getbranchInfo = async (outletIDs) => {
        let result = await Promise.all(outletIDs.map(this.getOutletBranches));
        let outletBranches = result.map((outletBranch) => {
            return {
                id: outletBranch.branch_id,
                name: outletBranch.En_short_name,
                city_id: outletBranch.city_id,
            };
        });

        this.setState({ outletBranches: outletBranches });
    };

    getOutletBranchesIDs = (snapshot) => {
        return snapshot.docs.map((doc) => {
            return doc.data().branch_id;
        });
    };

    prepareOutletBranches = (supplierBranchID) => {
        return db.collection(`Branch_Outlet_Branch/${supplierBranchID}/Outlet_Branch`).get();
    };

    getSupplierbranches = (company_id) => {
        db.collection("Branch")
            .where("supplier_id", "==", company_id)
            .get()
            .then((snapshot) => {
                let supplierBranchIDs = snapshot.docs.map((doc) => doc.data().branch_id);
                let queryPromises = supplierBranchIDs.map(this.prepareOutletBranches);
                Promise.all(queryPromises).then((snapshots) => {
                    let outletIDs = [].concat(...snapshots.map(this.getOutletBranchesIDs));
                    outletIDs = [...new Set(outletIDs)];
                    this.getbranchInfo(outletIDs);
                });
            })
            .catch((e) => {
                console.log(e, "luqman");
            });
    };

    displayGallery = (images) => {
        // let temp = images.map((image) => {
        //     return {
        //         original: image.url,
        //         thumbnail: image.url,
        //         description: image.title,
        //     };
        // });

        // this.setState({ images: temp });
        this.setState({ showGallery: true });
    };

    _customAction() {
        // this.setState({ images: [] });
        this.setState({ showGallery: false });
    }

    _renderCustomControls() {
        return (
            <button className={styles.ExitBtn} onClick={this._customAction.bind(this)}>
                <CloseIcon />
            </button>
        );
    }

    render() {
        const info = {
            companyData: this.context.companyData,
            projectId: this.state.task.projectId,
            taskId: this.state.task.taskId,
            visitDate: this.props.date,
            visitDay: this.state.day,
            city: this.state.city,
            outletBranch: this.state.outletBranch,
        };

        return (
            <StylesProvider injectFirst>
                <div className={styles.Container}>
                    <div className={styles.Content}>
                        {/* labels */}

                        <h2 className={styles.Title}>Merchandiser Daily Report</h2>
                        <div className={styles.Headers}>
                            <div className={styles.Labels}>
                                <span>
                                    Visit: <b>{this.props.taskId}</b>
                                </span>
                                <span>
                                    Date: <b>{this.state.date}</b>
                                </span>
                                <span>
                                    Day: <b>{this.state.day}</b>
                                </span>
                                <span>
                                    City: <b>{this.state.city}</b>
                                </span>
                                <span>
                                    Outlet Branch: <b>{this.state.outletBranch}</b>
                                </span>
                            </div>
                            {/* {this.state.showPDFLink ? (
                                <PDFViewer width="1024" height="1024">
                                    <MDRPdf
                                        name={this.state.supplier.company_name}
                                        info={info}
                                        rows={this.state.task.details}
                                        exportedBy={`${this.state.user.first_name} ${this.state.user.surname}`}
                                    />
                                </PDFViewer>
                            ) : null} */}

                            {/* buttons */}
                            <div className={styles.Buttons}>
                                <Button onClick={() => this.displayGallery()}>
                                    <PhotoLibraryIcon className={styles.GalleryIcon} />
                                </Button>

                                {this.state.showPDFLink ? (
                                    <Button
                                        onClick={() =>
                                            generatePDFinNewTab(
                                                this.state.task.options?.availability.active ? (
                                                    <MDRSimplePdf
                                                        name={this.state.supplier.company_name}
                                                        // mpName={this.state.mp.company_name}
                                                        info={info}
                                                        rows={this.state.task.details}
                                                        availability={this.state.task.options?.availability.active}
                                                        exportedBy={`${this.state.user.first_name} ${this.state.user.surname}`}
                                                    />
                                                ) : (
                                                    <MDRPdf
                                                        name={this.state.supplier.company_name}
                                                        // mpName={this.state.mp.company_name}
                                                        info={info}
                                                        rows={this.state.task.details}
                                                        availability={this.state.task.options?.availability.active}
                                                        exportedBy={`${this.state.user.first_name} ${this.state.user.surname}`}
                                                    />
                                                ),
                                                `Merchandiser Daily Report - ${this.state.supplier.company_name}`
                                            )
                                        }
                                    >
                                        <PictureAsPdfIcon className={styles.PDFIcon} />
                                    </Button>
                                ) : null}
                            </div>
                        </div>
                        {/* table */}
                        <ReportTable task={this.state.task} loading={this.state.loading} />
                    </div>
                    {this.state.showGallery ? (
                        <ImageGallery
                            items={this.state.images}
                            additionalClass={styles.ImageGallery}
                            renderCustomControls={this._renderCustomControls.bind(this)}
                        />
                    ) : null}
                </div>
            </StylesProvider>
        );
    }
}

export default TaskProductsReportContent;
