import React from "react";
import PropTypes from "prop-types";

import { Checkbox, makeStyles, TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core";
import { COLORS } from "../../constants/constants-lagacy";

const useStyles = makeStyles({
    sticky: {
        position: "sticky",
        top: 0,
    },
    th: {
        whiteSpace: "nowrap",
        color: COLORS.primary,
        backgroundColor: COLORS.background,
        fontWeight: "500",
        fontSize: "14px",
    },

    noPadding: {
        padding: "15px, 0px",
    },
    noToolbar: {
        padding: "6px 24px 6px 16px",
    },
});

function EnhancedTableHead(props) {
    const {
        selectionDisabled,
        classes,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        columns,
        stickyHeader,
        toolbarDisabled,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const styles = useStyles();

    return (
        <TableHead>
            <TableRow style={{ backgroundColor: COLORS.background, padding: "0px", margin: "0px" }}>
                {selectionDisabled ? null : (
                    <TableCell
                        padding="checkbox"
                        className={`${styles.th} ${stickyHeader ? styles.sticky : ""} ${
                            toolbarDisabled ? styles.noToolbar : ""
                        }`}
                    >
                        <Checkbox
                            // disabled={true}
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ "aria-label": "select all desserts" }}
                        />
                    </TableCell>
                )}
                {columns.map((row) =>
                    row.hidden ? null : row.id === "time" ? (
                        <TableCell
                            key={row.id}
                            align={row.numeric ? "right" : "left"}
                            padding={row.disablePadding ? "none" : "normal"}
                            sortDirection={orderBy === row.id ? order : false}
                            className={`${styles.th} ${stickyHeader ? styles.sticky : ""} ${
                                row.disablePadding ? styles.noPadding : ""
                            }`}
                            style={row.style ?? {}}
                        >
                            {row.label}
                        </TableCell>
                    ) : (
                        <TableCell
                            key={row.id}
                            align={row.numeric ? "right" : "left"}
                            padding={row.disablePadding ? "none" : "normal"}
                            sortDirection={orderBy === row.id ? order : false}
                            className={`${styles.th} ${stickyHeader ? styles.sticky : ""} ${
                                row.disablePadding ? styles.noPadding : ""
                            }`}
                            style={row.style ?? {}}
                        >
                            <TableSortLabel
                                // style={{
                                //     color: COLORS.primary,
                                //     paddingBottom: "5px",
                                //     paddingTop: "5px",
                                //     fontWeight: "500",
                                //     fontSize: "14px",
                                // }}
                                active={orderBy === row.id}
                                direction={order}
                                onClick={createSortHandler(row.id)}
                            >
                                {row.label}
                                {orderBy === row.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    )
                )}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default EnhancedTableHead;
