import { makeStyles } from '@material-ui/styles';
import { COLORS } from "../../constants/constants-lagacy";
import { darken } from "@material-ui/core";

const styles = makeStyles({
    sectionContainer: {
        padding: 8,
        margin: "7px",
    },

    reportHeader: {
        marginBottom: 8,
    },

    contentCard: {
        padding: 8,
        margin: "0 auto",
        maxWidth: "100%",
    },

    galleryContent: {
        marginTop: 20,
    },

    checkboxGrid: {
        flexBasis: 0,
        whiteSpace: "nowrap",
    },
    container: {
        overflowX: "scroll",
        overflowY: "hidden",
        whiteSpace: "nowrap"
    },
    taskCol: {
        backgroundColor: "pink",
        margin: "0",
        padding: "0",
        border: "solid 1px",
    },

    // table styles
    pdfButton: {
        marginTop: "12px",
        float: "right",
    },

    pdfIcon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "400",
        cursor: "pointer",
        color: "white",
        backgroundColor: COLORS.accent,
        borderRadius: "50%",
        width: "50px",
        height: "50px",
    },
    excelIcon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "400",
        cursor: "pointer",
        color: "white",
        backgroundColor: "#1A4D2E",
        borderRadius: "50%",
        width: "50px",
        height: "50px",
    },
    boldFont: {
        fontWeight: "bold",
        color: "#1F4690"
    },
    containerDiv:{
        marginTop:"4em"

    },
    alignCenter:{
        textAlign:"center"
    },

    tableContainer: {
        maxHeight: "700px",
        overflow: "auto",
        width: "90%",
        minWidth: "560px",
    },
    table: {
        minWidth: "560px",
        borderCollapse: "collapse",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: "9px",

        "& th": {
            // border: '1px solid black',
            border: "1px solid rgba(224, 224, 224,1)",
            color: COLORS.font,
            backgroundColor: COLORS.primary,
            // backgroundColor: "#8b8b8b",
            textAlign: "center",
            fontSize: "1.2em",
            padding: "5px 7px",
            whiteSpace: "nowrap",
            zIndex: 100,
        },
        "& th:last-child": {
            paddingRight: 0,
        },
        "& td": {
            position: "relative",
            fontWeight: "700",
            fontSize: "1.2em",
            borderLeft: "2px solid rgba(224, 224, 224, 1)",
            borderRight: "2px solid rgba(224, 224, 224, 1)",
        },
        "& $textLeft": {
            textAlign: "left",
        },
    },
    textLeft: {
        textAlign: "left",
    },
    headerRow: {
        backgroundColor: COLORS.primary,
    },
    header: {
        position: "sticky",
        top: "0px",
    },
    indexHeader:{
        width:"20px"
    },
    indexCell:{
        width:"20px"
    },
    outletHeader:{
        width:"60px"
    },
    outletCell:{
        width:"60px",
        textAlign:"left",
        paddingLeft:"5px"
    },
    productHeader:{
        width:"150px"
    },
    barcodeHeader:{width:"40px"},
    discHeader:{width:"60px"},
    packingHeader:{width:"50px"},
    stockHeader:{
        width:"60px"
    },
    headerColor:{
        color:"#A9333A"
    },
    qtyHeader:{width:"25px", padding:"3px", lineHeight:1.3},
    qtyCell:{textAlign:"center",padding:0,margin:0},
    remarksHeader:{
        width:"500px"
    },
    secondHeader: {
        position: "sticky",
        top: "35px",
    },
    topContainer:{
        display:"block",
        marginTop:"5em"
    },
    dateContainer:{
        display:"inline-block",
        position:"relative",
        bottom:"-20px"
    },
    alignLeft:{
        textAlign:"left",
        paddingLeft:"5px"
    },
    alignRight:{
        textAlign:"right",
        display:"inline-block",
        float:"right"
    },
    pdfDiv: {
        display:"inline",
        textAlign: "right", 
        position: "relative", 
        bottom: "2.5em", 
        right: "15.6em", 
        marginLeft:"10px",
    },

    imageIcon: {
        color: "#606060",
        cursor: "pointer",
        position: "absolute",
        right: 0,
        "&:hover": {
            color: "#1a1a1a",
        },
    },
    blueCell: {},
    cellItem: {
        textAlign: "center",
        border: "solid 1px"
    },
    rowEven: {
        backgroundColor: "#E8F9FD",
    },
});

export default styles;