import React, { useEffect } from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";
import { useCallback } from "react";

import EnhancedTable from "../../../components-lagacy/Table/EnhancedTable";
import { DashboardCard } from "../../../core-ui/custom";
import { Link } from "react-router-dom";
import { memo } from "react";
import { isEqual } from "lodash";
import PATHS from "../../../constants/paths";
import { formatDate } from "../../../helpers/date-utils";

const TABLE_HEADERS = [
    { id: "id", label: "No.", hidden: true },
    { id: "outletBranchName", label: "Outlet Branch", textEllepsis: true },
    { id: "productName", label: "Product", textEllepsis: true },
    { id: "damagedQuantity", label: "Dmg" },
    { id: "expiredQuantity", label: "Exp" },
    // { id: "quantity", label: "Qty" },
    // { id: "status", label: "Status" },
];

const ExpiredDamagedProductsTable = ({ tasks, taskDetails, outletBranches, loading = false, filterValues = {} }) => {
    const classes = styles();
    const [rowsData, setRowsData] = React.useState([]);
    const [totalDmg, setTotalDmg] = React.useState(0);
    const [totalExp, setTotalExp] = React.useState(0);

    const init = useCallback(async () => {
        try {
            //reset
            setRowsData([]);
            setup(tasks, taskDetails, outletBranches);
        } catch (error) {
            console.log(error);
        }
    }, [tasks, taskDetails, outletBranches]);

    const setup = (tasks, taskDetails, outletBranches) => {
        let dmg = 0;
        let exp = 0;
        //compute all these products to get chart data
        let chartData = taskDetails
            .map((td) => {
                //get task info
                // const task = tasks.find((task) => `${task.task_id}` === `${td.task_id}`);
                const task = td.task;
                //get outlet branch name
                // const outletBranchName = outletBranches.find(
                //     (branch) => `${task.outlet_branch_id}` === `${branch.branch_id}`
                // ).En_short_name;
                const outletBranchName = task.outlet_branch.En_short_name;

                //determine if the product is Expired
                const expiredQuantity = td.removed_from?.expired || 0;
                exp += expiredQuantity;
                //determine if the product is damaged
                const damagedQuantity = td.removed_from?.damaged || 0;
                dmg += damagedQuantity;

                return {
                    id: td.id,
                    productID: td.product_id,
                    outletBranchID: `${task.outlet_branch_id}`,
                    productName: `${td.product_name?.en}`,
                    outletBranchName,
                    expiredQuantity,
                    damagedQuantity,
                };
            })
            .filter((td) => td.expiredQuantity > 0 || td.damagedQuantity > 0);

        chartData = process(chartData);
        setTotalDmg(dmg);
        setTotalExp(exp);
        setRowsData(chartData);
    };

    const process = (chartData) => {
        //filter out duplicates by product id and outlet branch id and add the sum of expired quantity and damaged quantity
        const props = ["productID", "outletBranchID"];
        chartData = chartData.filter((obj, pos, arr) => {
            // const foundIndex = arr.map((mapObj) => mapObj["productID"]).indexOf(obj["productID"]);
            //get the values to be compared with by props specified
            let values = arr.map((mapObj) => props.reduce((res, prop) => ({ ...res, [prop]: mapObj[prop] }), {}));
            //get the index of the object in the array
            const foundIndex = values.findIndex((mapObj) =>
                props.reduce((res, prop) => res && obj[prop] === mapObj[prop], true)
            );

            //if the product is found but not duplicated, return true
            if (foundIndex === pos) return true;
            //if the product is duplicated, add the sum of expired quantity and damaged quantity and return false
            if (foundIndex !== pos) {
                arr[foundIndex].expiredQuantity += obj.expiredQuantity;
                arr[foundIndex].damagedQuantity += obj.damagedQuantity;
                return false;
            }
        });

        return chartData;

        // //seperate the data into 2 arrays, one for expired products and one for damaged products
        // //and add property 'status' to each product
        // const expiredProducts = chartData
        //     .filter((td) => td.expiredQuantity > 0)
        //     .map((td) => ({ ...td, id: `${td.id}-expired`, quantity: td.expiredQuantity, status: "Expired" }));
        // const damagedProducts = chartData
        //     .filter((td) => td.damagedQuantity > 0)
        //     .map((td) => ({ ...td, id: `${td.id}-damaged`, quantity: td.damagedQuantity, status: "Damaged" }));
        // //then combine them
        // return [...expiredProducts, ...damagedProducts];
    };

    const generateQueryParams = (values) => {
        const { date, supplierBranch, project, outlet, outletBranch, city } = values;
        const urlParams = new URLSearchParams({});

        if (date) {
            urlParams.append("from", formatDate(date.startDate, "-", true));
            urlParams.append("to", formatDate(date.endDate, "-", true));
        }
        if (supplierBranch) urlParams.append("supplier_branch", supplierBranch.value);
        if (project) urlParams.append("project", project.value);
        if (outlet) urlParams.append("outlet", outlet.value);
        if (outletBranch) urlParams.append("outlet_branch", outletBranch.value);
        if (city) urlParams.append("city", city.value);

        return urlParams;
    };

    useEffect(() => {
        init();
    }, [init]);

    return (
        <DashboardCard
            title={`Expired & Damaged Products (${totalDmg}, ${totalExp})`}
            type="table"
            classes={{
                cardContainer: classes.cardContainer,
                card: classes.card,
                cardHeader: classes.cardHeader,
                cardContent: classes.cardContent,
                cardActionArea: classes.cardActionArea,
            }}
            footerComponent={
                <Link
                    className={classes.link}
                    to={{
                        pathname: PATHS.EXPIRY_AND_DAMAGE_REPORT,
                        search: generateQueryParams(filterValues).toString(),
                    }}
                >
                    {`View Details >`}
                </Link>
            }
        >
            <div className={classes.tableWrapper}>
                <EnhancedTable
                    columns={TABLE_HEADERS}
                    rows={rowsData}
                    actions={[]}
                    updateSelected={() => {}}
                    loading={loading}
                    selectionDisabled
                    toolbarDisabled
                    paginationDisabled
                    emptyMessage="No Expired Products"
                />
            </div>
        </DashboardCard>
    );
};

export default memo(ExpiredDamagedProductsTable, isEqual);
