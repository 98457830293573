import { COLORS } from "../../../constants/constants-lagacy";
import { StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
    page: {
        // flexDirection: "row",
        // backgroundColor: "#E4E4E4",
        padding: "20px",
    },
    section: {
        margin: 10,
        padding: 10,
        // flexGrow: 1,
    },

    logoContainer: {
        display: "flex",
        width: "100%",
        height: "50px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },

    companyLogo: {
        width: "auto",
        height: "100%",
        display: "block",
        objectFit: "contain",
    },

    supplierHeaderText: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "center",
    },

    alignStart: {
        alignItems: "flex-start",
    },

    supplierText: {
        fontSize: 8,
        color: COLORS.accent,
    },

    supplierName: {
        fontSize: 12,
        fontWeight: "bold",
    },

    logoFooterContainer: {
        position: "absolute",
        bottom: 27,
        right: 30,
        fontSize: 8,
        color: "grey",

        height: "30px",
        justifyContent: "center",
    },

    logoFooterText: {
        fontSize: 12,
        justifyContent: "center",
        textAlign: "center",
    },

    logo: {
        width: "100%",
        height: "100%",
        display: "block",
        objectFit: "contain",
    },
    eMaham: {
        flex: "1",
        textAlign: "right",
    },

    red: {
        color: COLORS.accent,
    },

    line: {
        width: "100%",
        height: "2px",
        // padding: "0 20px",
        marginTop: "5px",
        backgroundColor: "grey",
    },

    footerLine: {
        position: "absolute",
        bottom: 75,
        left: 20,
        width: "100%",
        height: "2px",
        marginTop: "5px",
        backgroundColor: "grey",
    },

    header: {
        textAlign: "center",
        fontSize: 16,
        color: COLORS.accent,
        textDecoration: "underline",
        margin: "20px 0",
    },

    headerContainer: {
        width: "100%",
        fontSize: 12,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },

    left: {
        flex: 1,
        textAlign: "left",
    },
    right: {
        flex: 1,
        textAlign: "right",
    },

    bold: {
        fontFamily: "Roboto",
        fontWeight: "bold",
    },

    content: {
        marginTop: "10px",
        fontSize: 12,
    },

    contentIntro: {
        margin: "5px 0",
    },

    list: {
        marginTop: "10px",
    },

    listTitle: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: 14,
        fontWeight: "bold",
        color: "#0070c0",
    },

    listDot: {
        width: "5px",
        height: "5px",
        marginRight: "5px",
        backgroundColor: "#0070c0",
        borderRadius: 50,
    },

    listContent: {
        marginLeft: "15px",
        fontSize: 12,
    },

    listItem: {
        margin: "5px 0",
    },

    contentFooter: {
        margin: "5px 0",
        fontSize: 12,
    },

    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    },
    authorInfo: {
        position: "absolute",
        fontSize: 12,
        bottom: 15,
        left: 15,
        right: 0,
        textAlign: "left",
        color: "grey",
    },
    footer: {
        position: "absolute",
        bottom: 20,
        left: 20,
        fontSize: 8,
        display: "flex",
        color: "grey",
    },
});

export default styles;
