import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";

export const generatePDF = async (PDF, fileName) => {
    const blob = await pdf(PDF).toBlob();
    saveAs(blob, fileName);
};

export const generatePDFinNewTab = async (PDF, fileName) => {
    const blob = await pdf(PDF).toBlob();
    const url = window.URL.createObjectURL(blob);
    window.open(url);
};
