import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";
import { TransitionAlert } from "../../core-ui/custom";
import { Button } from "@material-ui/core";
import useEmailVerification from "../../hooks/use-email-verification";
import { useAuth } from "../../contexts/auth-context";

const EmailVerificationBar = (props) => {
    const classes = styles();
    const { userData } = useAuth();
    const { ACCOUNT_VERIFICATION_STATUS, verificationStatus, resendVerificationEmail, isSendingEmail } =
        useEmailVerification();
    const [showInfo, setShowInfo] = React.useState(false);

    const handleResendClick = async () => {
        try {
            setShowInfo(false);
            await resendVerificationEmail();
            setShowInfo(true);
        } catch (error) {
            console.error(error.message);
        }
    };

    return (
        <section className={classes.root}>
            <TransitionAlert severity="info" variant="filled" open={showInfo} setOpen={setShowInfo}>
                A Verificaton link has been sent.
            </TransitionAlert>

            <TransitionAlert
                severity="warning"
                variant="filled"
                open={verificationStatus() === ACCOUNT_VERIFICATION_STATUS.PENDING}
                setOpen={() => {}}
            >
                Your Account {userData?.email} is not yet verified. Please go to your email inbox and verify.
                <Button
                    disabled={isSendingEmail}
                    className={classes.alertButton}
                    disableRipple
                    onClick={handleResendClick}
                >
                    Resend Link
                </Button>
            </TransitionAlert>
            <TransitionAlert
                severity="error"
                variant="filled"
                open={verificationStatus() === ACCOUNT_VERIFICATION_STATUS.EXPIRED}
                setOpen={() => {}}
                disableExit
            >
                Your Account {userData?.email} is not yet verified. Please go to your email inbox and verify.
                <Button
                    disabled={isSendingEmail}
                    className={classes.alertButton}
                    disableRipple
                    onClick={handleResendClick}
                >
                    Resend Link
                </Button>
            </TransitionAlert>
        </section>
    );
};

EmailVerificationBar.propTypes = {
    classes: PropTypes.object,
};

export default EmailVerificationBar;
