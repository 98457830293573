import { makeStyles } from "@material-ui/styles";

const CARD_HEIGHT = 330;
const CARD_CONTENT_HEIGHT = 230;
const CARD_HEADER_HEIGHT = (CARD_HEIGHT - CARD_CONTENT_HEIGHT) / 2;
const CARD_FOOTER_HEIGHT = CARD_HEADER_HEIGHT;

const styles = makeStyles({
    tableWrapper: {
        height: "230px",
        overflow: "auto",
        backgroundColor: "white",

        "& table": {
            whiteSpace: "normal",
            height: "100%",
        },

        "& table th": {
            fontSize: ".65rem",
            whiteSpace: "nowrap",
        },

        "& table td": {
            fontSize: ".6rem",
        },

        "& table td, & table th": {
            padding: "0 0 0 15px",
        },
    },

    card: {
        position: "relative",
        height: `${CARD_HEIGHT}px`,
    },

    cardHeader: {
        height: `${CARD_HEADER_HEIGHT}px`,
    },

    cardContent: {
        height: `${CARD_CONTENT_HEIGHT}px`,
        position: "relative",
    },

    cardActionArea: {
        height: `${CARD_FOOTER_HEIGHT}px`,
        textAlign: "right",
    },

    link: {
        marginRight: 16,
    },
});

export default styles;
