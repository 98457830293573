import { alpha } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import COLORS from "../../../constants/colors";

const styles = makeStyles({
    label: {
        fontSize: "0.9rem",
        fontWeight: "bold",
        marginRight: 10,
    },

    formControl: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },

    radioGroup: {
        flexDirection: "row",
        justifyContent: "flex-end",
    },
});

export default styles;
