/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { container } from "./material-kit-ui";

const componentsStyle = {
    container,
    pageContainer: {
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    containerInner: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    brand: {
        color: "#FFFFFF",
        textAlign: "left",
    },
    title: {
        fontSize: "4rem",
        fontWeight: "600",
        display: "inline-block",
        position: "relative",
    },
    subtitle: {
        fontSize: "1.313rem",
        maxWidth: "500px",
        margin: "10px 0 0",
    },
    main: {
        background: "#FFFFFF",
        position: "relative",
        zIndex: "3",
    },
    mainRaised: {
        margin: "-60px 30px 0px",
        borderRadius: "6px",
        boxShadow:
            "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    },
    link: {
        textDecoration: "none",
    },
    textCenter: {
        textAlign: "center",
    },
    backdrop: {
        position: "fixed",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        backgroundColor: "#ffffff4f",
        zIndex: 1,
    },
    loaderContainer: {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 20,
    },

    absolutePosition: {
        position: "absolute",
    },
    loadingHidden: {
        opacity: "0",
        pointerEvents: "none",
    },
    "@media (max-width: 500px)": {
        title: {
            fontSize: "2rem",
        },
        subtitle: {
            fontSize: "1rem",
        },
    },
    textFieldLabel: {
        color: "#000000",
        "&$disabled": {
            color: "#000000",
        },
    },
    textFieldRoot: {
        fontWeight: "600",
        backgroundColor: "white",
        "&$disabled": {
            fontWeight: "600",
            color: "#000000",
            backgroundColor: "white",
            "&::before": {
                border: 0,
            },
        },
    },
    textFieldNoLabel: {
        fontWeight: "600",
        backgroundColor: "white",
        padding: 0,
        "&$disabled": {
            padding: 0,
            fontWeight: "600",
            color: "#000000",
            backgroundColor: "white",
            "&::before": {
                border: 0,
            },
        },
    },
    //override with an empty style so the styles above take precedence
    disabled: {},
};

export default componentsStyle;
