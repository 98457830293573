import { makeStyles } from "@material-ui/styles";

const styles = makeStyles({
    refreshButton: {
        backgroundColor: "transparent",
        boxShadow: "none",
        padding: "0",
        minWidth: "auto",

        "&:hover": {
            boxShadow: "none",
        },
    },
});

export default styles;
