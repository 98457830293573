import { getSeasons } from "./Season";
import { firestore as db } from "../firebase";

export let getSeasonalEDPrices = async (extraDisplayIDs = [], startDate = null, endDate = null) => {
    let prices = [];
    try {
        let seasonIDs = [];
        let ref = db.collection("Seasonal_Extra_Display_Price");

        if (startDate && endDate) {
            seasonIDs = (await getSeasons(startDate, endDate)).map((season) => season.id);
            prices = await loadSeasonalExtraDisplayPrices(extraDisplayIDs, seasonIDs);
        } else {
            prices = await Promise.all(
                extraDisplayIDs.map(async (id) => {
                    return (await ref.where("extra_display_id", "==", id).get()).docs;
                })
            );
            prices = prices.reduce((arr, prices) => [...arr, ...prices], []);

            // for (const extraDisplayID of extraDisplayIDs) {
            //     let seasonalPrices = (await ref.where("extra_display_id", "==", extraDisplayID).get()).docs;
            //     prices = [...prices, ...seasonalPrices];
            // }
        }
    } catch (error) {
        console.error(error);
    }

    return prices;
};

export let loadSeasonalExtraDisplayPrices = async (extraDisplayIDs, seasonIDs) => {
    let seasonalPrices = [];
    try {
        let seasonalExtraDisplayPricesRef = db.collection("Seasonal_Extra_Display_Price");

        seasonalPrices = await Promise.all(
            extraDisplayIDs.map(async (exID) => {
                let prices = await Promise.all(
                    seasonIDs.map(async (seasonID) => {
                        const priceID = `${exID}-${seasonID}`;
                        return await seasonalExtraDisplayPricesRef.doc(priceID).get();
                    })
                );
                prices = prices.filter((p) => (p.exists ? true : false));
                return prices;
            })
        );
        seasonalPrices = seasonalPrices.reduce((arr, prices) => [...arr, ...prices], []);

        // for (const extraDisplayID of extraDisplayIDs) {
        //     for (const seasonID of seasonIDs) {
        //         const priceID = `${extraDisplayID}-${seasonID}`;
        //         let seasonalPrice = await seasonalExtraDisplayPricesRef.doc(priceID).get();
        //         if (seasonalPrice.exists) seasonalPrices.push(seasonalPrice);
        //     }
        // }
    } catch (err) {
        console.error(err);
    }
    return seasonalPrices;
};
