import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../logo";

const useStyles = makeStyles({
    container: {
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "115px",
        padding: 10,
    },

    logo: {
        height: "100%",
    },
});

let LogoHeader = (props) => {
    const classes = useStyles(props);
    const { variant = "default" } = props;
    return (
        <header className={classes.container}>
            <Logo className={classes.logo} variant={variant} />
        </header>
    );
};

LogoHeader.propTypes = {
    classes: PropTypes.object,
};

export default LogoHeader;
