import React from "react";
import { Redirect, Route } from "react-router";
import { DashboardHeader, EmailVerificationBar } from "..";
import PATHS from "../../constants/paths";
import { useAuth } from "../../contexts/auth-context";
import { getDaysSinceSpecificDate } from "../../helpers/date-utils";

const PrivateRoute = (props) => {
    const { currentUser, companyData, userData } = useAuth();
    const { children, path } = props;

    if (!currentUser || !companyData || !userData) {
        return <Redirect to={{ pathname: PATHS.SIGN_IN, state: { from: path } }} />;
    }

    // check if user is not verified and it's been 10 days since registration
    const daysElapsed = getDaysSinceSpecificDate(userData.created_at);
    if (!currentUser.emailVerified && daysElapsed > 10) {
        return <Redirect to={{ pathname: PATHS.SIGN_IN, state: { from: path } }} />;
    }

    let alertBars = [];
    if (!currentUser.emailVerified) {
        alertBars.push({
            type: "warning",
            message: "Please verify your email address to continue.",
            link: {
                text: "Resend verification email",
                url: PATHS.VERIFY_EMAIL,
            },
        });
    }

    return (
        <Route {...props}>
            <DashboardHeader />
            <EmailVerificationBar />
            {children}
        </Route>
    );
};

export default PrivateRoute;
