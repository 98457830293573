import { makeStyles } from "@material-ui/core";
import COLORS from "../../../constants/colors";

const borderStyle = `1px ${COLORS.NAV_COLOR_2} solid`;

export default makeStyles({
    // thirdMenuWrap: {
    //     position: "absolute",
    //     top: "0",
    //     left: "0",
    //     width: "100%",
    //     borderRight: borderStyle,
    //     borderBottom: borderStyle,
    //     backgroundColor: COLORS.NAV_COLOR_1,
    //     zIndex: 100,
    // },

    expandedSubMenu: {
        margin: "0 0 0 30px",
        padding: "0",
        borderLeft: borderStyle,
    },
});
