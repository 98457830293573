import { alpha, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import COLORS from "../../../constants/colors";

const useStyles = makeStyles({
    root: {
        fontSize: "1rem",
        display: "grid",
        gridTemplateColumns: "1fr max-content 1fr",
        gridColumnGap: "1.2rem",
        alignItems: "center",
        color: alpha(COLORS.BLACK, 0.6),
        fontWeight: "bold",

        "&:before, &:after": {
            content: "''",
            display: "block",
            height: "1px",
            backgroundColor: alpha(COLORS.BLACK, 0.2),
        },
    },
});

const Header = ({ className, children, ...otherProps }) => {
    const classes = useStyles();

    const config = {
        ...otherProps,
        className: `${classes.root} ${className}`,
    };
    return <Typography {...config}>{children}</Typography>;
};

export default Header;
