import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
    },
    backdrop: {
        position: "fixed",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        backgroundColor: "#ffffff4f",
        zIndex: 1,
    },

    loaderContainer: {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 20,
    },

    absolutePosition: {
        position: "absolute",
    },

    loadingHidden: {
        opacity: "0",
        pointerEvents: "none",
    },
});

let FullPageLoader = (props) => {
    const classes = useStyles(props);
    return (
        <div className={`${props.root} ${props.className}`}>
            <div
                className={`${classes.backdrop} ${classes.absolutePosition} ${
                    props.loading ? null : classes.loadingHidden
                }`}
            ></div>
            <div
                className={`${classes.loaderContainer} ${classes.absolutePosition} ${
                    props.loading ? null : classes.loadingHidden
                }`}
            >
                <CircularProgress />
            </div>
        </div>
    );
};

export default FullPageLoader;
