import { makeStyles } from "@material-ui/core";
import React from "react";

const styles = makeStyles({
    icon: {
        width: "100px",
        height: "100px",
        borderRadius: "50%",
        display: "block",
        strokeWidth: "2",
        stroke: "#4bb71b",
        strokeMiterlimit: "10",
        boxShadow: "inset 0px 0px 0px #4bb71b",
        animation: "$fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both",
        position: "relative",
        top: "5px",
        right: "5px",
        margin: "0 auto",
    },

    circle: {
        strokeDasharray: "166",
        strokeDashoffset: "166",
        strokeWidth: "2",
        strokeMiterlimit: "10",
        stroke: "#4bb71b",
        fill: "#fff",
        animation: "$stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards",
    },

    path: {
        transformOrigin: "50% 50%",
        strokeDasharray: "48",
        strokeDashoffset: "48",
        animation: "$stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards",
    },

    "@keyframes stroke": {
        "100%": {
            strokeDashoffset: "0",
        },
    },
    "@keyframes scale": {
        "0%, 100%": {
            transform: "none",
        },
        "50%": {
            transform: "scale3d(1.1, 1.1, 1)",
        },
    },
    "@keyframes fill": {
        "100%": {
            boxShadow: "inset 0px 0px 0px 30px #4bb71b",
        },
    },
});

const SuccessIcon = () => {
    const classes = styles();
    return (
        <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle className={classes.circle} cx="26" cy="26" r="25" fill="none" />
            <path className={classes.path} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
    );
};

export default SuccessIcon;
