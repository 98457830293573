import React from "react";
import { makeStyles } from "@material-ui/core";

const styles = makeStyles({
    text: {
        fontWeight: 400,
        fontSize: "1.15rem",
        lineHeight: "1.75em",
    },
    "@media screen and (min-width: 1200px)": {
        text: {
            fontSize: "1.4rem",
        },
    },
});
const TitleDescription = ({ children, className, ...otherProps }) => {
    const classes = styles();

    const config = {
        ...otherProps,
        className: `${classes.text} ${className ?? ""}`,
    };
    return <p {...config}>{children}</p>;
};

export default TitleDescription;
