import { Card, CardActionArea, CardContent, CardHeader, Typography } from "@material-ui/core";
import styles from "./styles";

const DashboardCard = ({ children, title, footerComponent = null, classes: customClasses = {}, type = "normal" }) => {
    const classes = styles();

    // if (type === "table") {
    //     content = <div className={classes.shadow}>{children}</div>;
    // }
    return (
        <div className={`${classes.cardContainer} ${customClasses.cardContainer ?? ""}`}>
            <Card className={`${classes.dashboardCard} ${customClasses.card ?? ""}`}>
                <CardHeader
                    className={`${classes.cardHeader} ${customClasses.cardHeader ?? ""}`}
                    title={title}
                    titleTypographyProps={{ className: `${classes.cardTitle} ${customClasses.cardTitle ?? ""}` }}
                />
                <CardContent className={`${classes.cardContent} ${customClasses.cardContent ?? ""}`}>
                    {children}
                </CardContent>
                {footerComponent && (
                    <div className={`${classes.cardActionArea} ${customClasses.cardActionArea ?? ""}`}>
                        {footerComponent}
                    </div>
                )}
            </Card>
        </div>
    );
};

export default DashboardCard;
