import { dateRangeOverlaps } from "../../helpers/date-utils";
import { firestore as db } from "../firebase";

export let getAllSeasons = async () => {
    let seasons = [];

    try {
        seasons = (await db.collection("Season").get()).docs;
    } catch (error) {
        console.log(error);
    }
    return seasons;
};

export let getSeasons = async (start, end) => {
    let seasons = [];
    try {
        seasons = (await db.collection("Season").get()).docs;
        seasons = seasons.filter((season) =>
            dateRangeOverlaps(start, end, season.data().start_date.toDate(), season.data().end_date.toDate())
        );
    } catch (err) {
        console.log(err);
    }

    return seasons;
};
