import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
// import styles from "./styles";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";

const DateRangePickerWrap = ({ onChange, ...otherProps }) => {
    const config = {
        onChange,
        ...otherProps,
    };

    return <DateRangePicker {...config} />;
};

DateRangePickerWrap.propTypes = {
    classes: PropTypes.object,
};

export default DateRangePickerWrap;
