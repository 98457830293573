import { alpha } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import COLORS from "../../../constants/colors";

// const CARD_HEIGHT = 330;
// const CARD_CONTENT_HEIGHT = 280;
// const CARD_HEADER_HEIGHT = CARD_HEIGHT - CARD_CONTENT_HEIGHT;
const CARD_HEADER_HEIGHT = 51;

const styles = makeStyles({
    cardContainer: {
        height: "100%",
    },

    card: {
        height: "100%",
        overflow: "visible",
        position: "relative",
        // height: `${CARD_HEIGHT}px`,
    },
    // cardContent: {
    //     height: "100%",
    //     width: "100%",
    //     padding: "0",
    //     paddingBottom: "0 !important",
    //     display: "flex",
    //     flexDirection: "column",
    //     justifyContent: "center",
    //     alignItems: "center",
    // },

    cardHeader: {
        height: `${CARD_HEADER_HEIGHT}px`,
    },

    cardContent: {
        // height: `${CARD_CONTENT_HEIGHT}px`,
        maxHeight: `calc(100% - ${CARD_HEADER_HEIGHT}px)`,
        position: "relative",
        padding: "15px",
        // display: "flex",
        // alignItems: "center",
        // justifyContent: "center",
    },

    gaugeContainer: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "calc(100% - 0px)",
        maxWidth: "360px",
        height: 200,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    link: {
        marginRight: 16,
    },

    loader: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: `100%`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: alpha(COLORS.WHITE, 0.7),
    },
});

export default styles;
