import { firestore as db } from "../firebase";

export let loadextraDisplayContractsFromProject = async (project) => {
    let uniqueIDs = [];
    let contracts = [];

    try {
        for (const supplierBranch of project.supplierBranches) {
            for (const outletBranch of supplierBranch.outletBranches) {
                for (const schedule of outletBranch.schedules) {
                    let contracts = schedule.data.extra_display_contracts || [];
                    let contractsIDs = contracts.map((contract) => contract.contract_id);
                    uniqueIDs = [...new Set(uniqueIDs.concat(...contractsIDs))];
                }
            }
        }

        contracts = await Promise.all(
            uniqueIDs.map(async (id) => await db.collection("Contract_Extra_Display").doc(id).get())
        );
    } catch (error) {
        console.log(error);
    }

    return contracts;
};

export let getExtraDisplayContracts = async (supplierID, outletBranchID = null) => {
    let contracts = [];

    try {
        let ref = db.collection("Contract_Extra_Display").where("supplier_id", "==", supplierID);

        if (outletBranchID) {
            ref = ref.where("outlet_id", "==", outletBranchID);
        }
        contracts = (await ref.where("status", "==", "1").get()).docs;
    } catch (error) {
        console.log(error);
    }
    return contracts;
};
