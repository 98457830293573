import { alpha, makeStyles } from "@material-ui/core";
import React from "react";
import COLORS from "../../../constants/colors";

const styles = makeStyles({
    header: {
        fontSize: "0.8rem",
        display: "grid",
        gridTemplateColumns: "1fr max-content 1fr",
        gridColumnGap: "1.2rem",
        alignItems: "center",
        color: alpha(COLORS.BLACK, 0.5),

        "&:before, &:after": {
            content: "''",
            display: "block",
            height: "1px",
            backgroundColor: alpha(COLORS.BLACK, 0.1),
        },
    },
});

const FormHeader = (props) => {
    const { children } = props;
    const classes = styles();

    return <span className={classes.header}>{children}</span>;
};

export default FormHeader;
