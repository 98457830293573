import { Font } from "@react-pdf/renderer";

// Register font
Font.register({
    family: "Roboto",
    fonts: [
        {
            src: `/fonts/Roboto/Roboto-Regular.ttf`,
        },
        {
            src: `/fonts/Roboto/Roboto-Bold.ttf`,
            fontWeight: "bold",
        },
        {
            src: `/fonts/Roboto/Roboto-Italic.ttf`,
            fontWeight: "normal",
            fontStyle: "italic",
        },
        {
            src: `/fonts/Roboto/Roboto-BoldItalic.ttf`,
            fontWeight: "bold",
            fontStyle: "italic",
        },
    ],
});

Font.register({
    family: "Noto-Naskh-Arabic",
    fonts: [
        {
            src: `/fonts/Noto-Naskh-Arabic/NotoNaskhArabic-Regular.ttf`,
        },
        {
            src: `/fonts/Noto-Naskh-Arabic/NotoNaskhArabic-Bold.ttf`,
            fontWeight: "bold",
        },
        {
            src: `/fonts/Noto-Naskh-Arabic/NotoNaskhArabic-Medium.ttf`,
            fontWeight: "medium",
        },
        {
            src: `/fonts/Noto-Naskh-Arabic/NotoNaskhArabic-SemiBold.ttf`,
            fontWeight: "semiBold",
        },
    ],
});
