import React from "react";
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from "@material-ui/core";
import styles from "./styles";

const CheckboxWrapper = ({ label, legend, checked, onChange }) => {
    const classes = styles();
    const handleChange = (e) => {
        const { checked } = e.target;
        onChange(checked);
    };

    const configCheckbox = {
        onChange: handleChange,
        checked: checked,
    };

    const configFormControl = {
        error: false,
    };

    return (
        <FormControl {...configFormControl}>
            {legend && <FormLabel component="legend">{legend}</FormLabel>}
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox {...configCheckbox} />}
                    label={label}
                    classes={{ label: classes.checkboxLabel }}
                />
            </FormGroup>
        </FormControl>
    );
};

export default CheckboxWrapper;
