import { firestore as db } from "../firebase";

export let getLinkedOutlets = async (supplierID) => {
    try {
        let linkedOutlets = [];

        //get number of outlet companies registered in this supplier account
        linkedOutlets = (
            await db.collection("Supplier_Outlet_Product").doc(supplierID).collection("Outlet_Product").get()
        ).docs;
        return linkedOutlets;
    } catch (error) {
        throw error;
    }
};

export let getLinkedProducts = async (outletID, supplierID) => {
    let linkedProducts = [];
    try {
        //get number of products registered in this outlet
        linkedProducts = (
            await db
                .collection("Supplier_Outlet_Product")
                .doc(supplierID)
                .collection("Outlet_Product")
                .doc(outletID)
                .collection("Product")
                .get()
        ).docs;
    } catch (error) {
        console.log(error);
    }

    return linkedProducts;
};
