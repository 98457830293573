import { makeStyles } from "@material-ui/styles";

const styles = makeStyles({
    pageRoot: {
        position: "relative",
        padding: "15px 0",
        maxWidth: "1280px",
        margin: "0 auto",
    },
});

export default styles;
