import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
// import { makeStyles } from "@material-ui/core/styles";
import logo from "../../../assets/svgs/eMaham-logo.svg";
import logoLight from "../../../assets/svgs/eMaham-light-logo.svg";
import logoWhite from "../../../assets/svgs/eMaham-white-logo.svg";

// const useStyles = makeStyles({});

let Logo = ({ variant, ...otherProps }) => {
    // const classes = useStyles(otherProps);
    let src = logo;
    switch (variant) {
        case "light":
            src = logoLight;
            break;
        case "white":
            src = logoWhite;
            break;
        default:
            src = logo;
            break;
    }

    return <img src={src} alt="eMaham logo" {...otherProps} />;
};

Logo.propTypes = {
    classes: PropTypes.object,
};

export default Logo;
