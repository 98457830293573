import { firestore as db } from "../firebase";

export let getSupplierProducts = async (supplierID) => {
    let products = [];

    try {
        products = (await db.collection("Product").where("supplier_id", "==", supplierID).get()).docs;
    } catch (error) {
        console.log(error);
    }

    return products;
};

export let getProduct = async (id) => {
    let product = null;
    try {
        product = await db.collection("Product").doc(id).get();
    } catch (error) {
        console.log(error);
    }

    return product;
};

export let getProducts = async (IDs) => {
    let products = [];

    try {
        products = await Promise.all(IDs.map(getProduct));
    } catch (error) {
        console.log(error);
    }
    return products;
};
