import { makeStyles } from "@material-ui/styles";

const styles = makeStyles({
    row: {
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
        borderRadius: "5px",
        margin: "10px 0",
        "& td": {
            paddingTop: "30px",
            paddingBottom: "30px",
        },
    },
});

export default styles;
