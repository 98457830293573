export const widthCheck = (screenW, breakpoints = [788, 522, 320, 0]) => {
    let screenBreak;

    for (let i = 0; i < breakpoints.length; i++) {
        if (screenW >= breakpoints[i]) {
            screenBreak = i;
            break;
        }
    }

    return screenBreak;
};
