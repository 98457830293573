import { lighten, makeStyles } from "@material-ui/core";
import React from "react";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import BusinessIcon from "@material-ui/icons/Business";
import BallotIcon from "@material-ui/icons/Ballot";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import COLORS from "../../../constants/colors";

const styles = makeStyles({
    root: {
        backgroundColor: lighten(COLORS.BLACK, 0.8),
        zIndex: 1,
        color: COLORS.WHITE,
        width: 50,
        height: 50,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
    },
    active: {
        backgroundImage: COLORS.GRADIANT_1,
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    },
    completed: {
        backgroundImage: COLORS.GRADIANT_1,
    },
});

const SignUpStepIcon = (props) => {
    const classes = styles();
    const { active, completed, icon } = props;

    const icons = {
        1: <AccountCircleIcon />,
        2: <BusinessIcon />,
        3: <BallotIcon />,
        4: <ContactPhoneIcon />,
    };

    return (
        <div className={`${classes.root} ${active ? classes.active : ""} ${completed ? classes.completed : ""}`}>
            {icons[String(icon)]}
        </div>
    );
};

export default SignUpStepIcon;
