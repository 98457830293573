import { alpha } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import COLORS from "../../../constants/colors";

// const CARD_HEIGHT = 330;
// const CARD_CONTENT_HEIGHT = 180;
// const CARD_HEADER_HEIGHT = (CARD_HEIGHT - CARD_CONTENT_HEIGHT) / 2;
// const CARD_FOOTER_HEIGHT = CARD_HEADER_HEIGHT;
const CARD_HEADER_HEIGHT = 51;
const CARD_FOOTER_HEIGHT = 51;

const styles = makeStyles({
    cardContainer: {
        height: "100%",
    },
    card: {
        position: "relative",
        // height: `${CARD_HEIGHT}px`,
        height: "100%",
    },

    cardHeader: {
        height: `${CARD_HEADER_HEIGHT}px`,
    },
    cardTitle: {
        letterSpacing: "2px",
    },

    cardContent: {
        maxHeight: `calc(100% - ${CARD_HEADER_HEIGHT}px - ${CARD_FOOTER_HEIGHT}px)`,
        position: "relative",
    },

    loader: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: `calc(100% - 48px)`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: alpha(COLORS.WHITE, 0.7),
    },

    cardActionArea: {
        height: `${CARD_FOOTER_HEIGHT}px`,
        textAlign: "right",
    },

    link: {
        marginRight: 16,
    },

    centerText: {
        textAlign: "center",
        position: "absolute",
        top: "calc(50% - 20px)",
        left: "50%",
        transform: "translate(-50%, -50%)",
        fontWeight: "bold",
        fontSize: "1rem",
    },

    legendsContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        width: "100%",
        position: "absolute",
        bottom: 8,
    },

    legend: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 6px",
    },

    legendSymbol: {
        width: 15,
        height: 15,
        display: "block",
        borderRadius: "50%",
    },

    legendLabel: {
        fontSize: "0.6rem",
        whiteSpace: "nowrap",
        marginLeft: 5,
    },
});

export default styles;
