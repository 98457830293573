import { makeStyles } from "@material-ui/core";
import COLORS from "../../../constants/colors";
import { darken } from "@material-ui/core";

export default makeStyles({
    secondMenuItem: {
        listStyle: "none",
        "& > button": {
            width: "100%",
            justifyContent: "flex-start",
            color: COLORS.WHITE,
            fontWeight: 400,
            textTransform: "none",
            fontSize: "0.7rem",
            padding: "15px 8px",

            // "&:after": {
            //     content: "'+'",
            //     position: "absolute",
            //     color: "inherit",
            //     right: "0",
            //     height: "auto",
            //     width: "20px",
            //     fontWeight: 400,
            //     fontSize: "1.3rem",
            //     textAlign: "center",
            // },
        },
    },

    // active: {
    //     "& > button": {
    //         "&:after": {
    //             content: "'-'",
    //         },
    //     },
    // },

    hidden: {
        display: "none",
    },

    "@media screen and (min-width: 1000px)": {
        secondMenuItem: {
            "& > button": {
                position: "relative",
                left: "-1px",
                padding: "15px 23px",
                borderLeft: `3px solid transparent`,
                borderRadius: "0",
                "&:before": {
                    content: "''",
                    display: "block",
                    position: "absolute",
                    top: "0",
                    left: "-1px",
                    height: "100%",
                    // width: "38vw",
                    width: "100%",
                    maxWidth: "397px",
                    zIndex: -1,
                },
            },
            "&:hover": {
                "& > button": {
                    borderLeftColor: COLORS.NAV_COLOR_3,
                    "&:before": {
                        backgroundColor: darken(COLORS.NAV_COLOR_1, 0.4),
                    },
                },
            },
        },

        active: {
            "& > button": {
                borderLeftColor: COLORS.NAV_COLOR_3,
                "&:before": {
                    backgroundColor: darken(COLORS.NAV_COLOR_1, 0.4),
                },
            },
        },
    },

    "@media screen and (min-width: 1400px)": {
        secondMenuItem: {
            "& > button": {
                fontSize: "0.75rem",
            },
        },
    },
});
