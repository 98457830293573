import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles({
    textFieldLabel: {
        color: "#000000",
        "&$disabled": {
            color: "#000000",
        },
    },
    textFieldRoot: {
        fontWeight: "600",
        backgroundColor: "white",
        height: "100%",
        "&$disabled": {
            fontWeight: "600",
            color: "#000000",
            backgroundColor: "white",
            "&::before": {
                border: 0,
            },
        },
    },
    //override with an empty style so the styles above take precedence
    disabled: {},
});

let CustomTextField = (props) => {
    const classes = useStyles(props);
    return (
        <TextField
            InputProps={{
                classes: {
                    root: classes.textFieldRoot,
                    disabled: classes.disabled,
                },
                ...props.inputProps,
            }}
            InputLabelProps={{
                classes: {
                    root: classes.textFieldLabel,
                    disabled: classes.disabled,
                },
            }}
            variant="filled"
            fullWidth
            multiline
            margin="dense"
            maxRows={1}
            {...props.rootProps}
        />
    );
};

CustomTextField.propTypes = {
    classes: PropTypes.object,
};

export default CustomTextField;
