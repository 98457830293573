import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TransferList from "../TransferList/TransferList";
// const useStyles = makeStyles({});

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: "12px",
        top: "12px",
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

let ProductsDialog = (props) => {
    // const classes = useStyles(props);
    //left, right, are objects that contains: {list: array of objects, set: function, title: string},
    //checked is similar but with no title
    const { handleClose, open, save, left, right, checked, disabled } = props;

    // console.log(left, right, checked);
    //rename the keys
    const leftSide = { leftList: left.list, setLeft: left.set, leftTitle: left.title, onLeftListFilter: left.onFilter };
    const rightSide = {
        rightList: right.list,
        setRight: right.set,
        rightTitle: right.title,
        onRightListFilter: right.onFilter,
    };
    const checkedProducts = { checkedList: checked.list, setChecked: checked.set };

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="xl">
            <DialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
                style={{ padding: "20px 56px", textAlign: "center" }}
            >
                Products
            </DialogTitle>
            <DialogContent dividers style={{ padding: "16px 24px" }}>
                <TransferList left={leftSide} right={rightSide} checked={checkedProducts} disabled={disabled} />
            </DialogContent>
            <DialogActions style={{ padding: "8px 24px" }}>
                <Button onClick={save} color="primary" style={{ padding: "6px 0px" }}>
                    {disabled ? "Close" : "Save"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProductsDialog;
