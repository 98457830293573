import { makeStyles } from "@material-ui/styles";

const styles = makeStyles({
    asterisk: {
        color: "red",
        fontWeight: "bold",
    },
});

export default styles;
