import { alpha } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import COLORS from "../../../constants/colors";

// const CARD_HEIGHT = 330;
// const CARD_CONTENT_HEIGHT = 280;
// const CARD_HEADER_HEIGHT = CARD_HEIGHT - CARD_CONTENT_HEIGHT;
const CARD_HEADER_HEIGHT = 51;

const styles = makeStyles({
    cardContainer: {
        height: "100%",
    },

    card: {
        overflow: "visible",
        position: "relative",
        height: `100%`,
    },

    cardHeader: {
        height: `${CARD_HEADER_HEIGHT}px`,
    },

    cardContent: {
        maxHeight: `calc(100% - ${CARD_HEADER_HEIGHT}px)`,
        position: "relative",
    },

    body: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: `100%`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: alpha(COLORS.WHITE, 0.7),
    },
});

export default styles;
