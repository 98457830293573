import { firestore as db } from "../firebase";

export let getEDRegularPrices = async (extraDisplayIDs = []) => {
    let prices = [];
    try {
        let ref = db.collection("Extra_Display_Price");

        if (extraDisplayIDs.length > 0) {
            prices = await Promise.all(
                extraDisplayIDs.map(async (id) => {
                    return await ref.doc(`${id}`).get();
                })
            );

            prices = prices.filter((p) => (p.exists ? true : false));
            // for (const id of extraDisplayIDs) {
            //     let price = await ref.doc(id).get();
            //     prices.push(price);
            // }
        } else {
            prices = (await ref.get()).docs;
        }
    } catch (error) {
        console.error(error);
    }

    return prices;
};
