import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";
import SubscriptionItem from "../subscription-item";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { useState } from "react";
import SubscriptionManagerDialog from "../subscription-manager-dialog";

const heads = ["Created at", "start", "end", "Slots", "Status", ""];

const SubscriptionList = (props) => {
    const classes = styles();
    const { subscriptions, refresh } = props;

    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState(null);

    const handleDialogClose = (ev, evType, requireRefresh = false) => {
        setDialogOpen(false);
        if (requireRefresh) refresh();
    };

    const handleDialogOpen = (subscription) => {
        setSelectedSubscription(subscription);
        setDialogOpen(true);
    };
    return (
        <>
            <Table className={classes.subscriptionTable}>
                <TableHead>
                    <TableRow>
                        {heads.map((head, index) => {
                            return (
                                <TableCell key={index} align="center">
                                    {head}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {subscriptions.map((subscription, index) => {
                        return (
                            <SubscriptionItem key={index} subscription={subscription} openDialog={handleDialogOpen} />
                        );
                    })}
                </TableBody>
            </Table>
            <SubscriptionManagerDialog
                open={dialogOpen && selectedSubscription !== null}
                onClose={handleDialogClose}
                subscription={selectedSubscription}
            />
        </>
    );
};

SubscriptionList.propTypes = {
    classes: PropTypes.object,
};

export default SubscriptionList;
