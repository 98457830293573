import React, { useCallback, useEffect, useRef } from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";

import { calculateTimeDuration, convertMinutesToHoursAndMinutes } from "../../../helpers/date-utils";
import { TASKS_STATUS } from "../../../constants/constants-lagacy";
import { getMerchandiserName } from "../../../services/firestore/Merchandiser";
import { DashboardCard } from "../../../core-ui/custom";
import EnhancedTable from "../../../components-lagacy/Table/EnhancedTable";
import { Link } from "react-router-dom";

const TABLE_HEADERS = [
    { id: "merchandiserName", label: "Merchandiser" },
    { id: "idealTime", label: "Ideal Avg. Time" },
    { id: "actualTime", label: "Actual Avg. Time" },
    { id: "branch", label: "Branch", textEllepsis: true },
];
const InstoreTimeTable = ({ tasks, merchandisers, loading = false }) => {
    const classes = styles();
    const [rowsData, setRowsData] = React.useState([]);

    const init = useCallback(async () => {
        try {
            setRowsData([]);
            await onTasksUpdate(tasks, merchandisers);
        } catch (error) {
            console.log(error);
        }
    }, [tasks, merchandisers]);

    const onTasksUpdate = async (tasks, merchandisers) => {
        //filter tasks by state
        const tasks_filtered = tasks.filter((task) =>
            [TASKS_STATUS.FINISHED, TASKS_STATUS.AUDITING, TASKS_STATUS.COMPLETED].includes(task.state)
        );

        // setup rowsData
        setupTableData(tasks_filtered, merchandisers);
    };

    const setupTableData = (tasks, merchandisers) => {
        const rowsData = [];
        // for each merchandiser, calculate avg visit time of all his tasks
        merchandisers.forEach((merchandiser) => {
            // merchandiser tasks
            const tasksOfMerchandiser = tasks.filter((task) => task.uid === merchandiser.uid);
            // for each task, calculate actual duration in minutes
            // const totalTime = tasksOfMerchandiser.reduce((total, task) => {
            //     const start = task.started_at?.toDate() ?? 0;
            //     const end = task.finished_at?.toDate() ?? 0;
            //     return calculateTimeDuration(start, end) + total;
            // }, 0);
            const totalTime = tasksOfMerchandiser.reduce(
                (total, task) => {
                    const start = task.started_at?.toDate() ?? 0;
                    const end = task.finished_at?.toDate() ?? 0;
                    const duration = task.duration;

                    total.actual += calculateTimeDuration(start, end);
                    total.ideal += duration;
                    return total;
                },
                { ideal: 0, actual: 0 }
            );

            // calculate actual avg time
            const actualAvgTime = tasksOfMerchandiser.length === 0 ? 0 : totalTime.actual / tasksOfMerchandiser.length;
            // calculate ideal avg time
            const idealAvgTime = tasksOfMerchandiser.length === 0 ? 0 : totalTime.ideal / tasksOfMerchandiser.length;

            rowsData.push({
                id: merchandiser.uid,
                merchandiserName: getMerchandiserName(merchandiser),
                actualTime: convertMinutesToHoursAndMinutes(actualAvgTime) || "-",
                idealTime: convertMinutesToHoursAndMinutes(idealAvgTime) || "-",
                branch: merchandiser.branch.En_name,
            });
        });

        setRowsData(rowsData);
    };
    useEffect(() => {
        init();
    }, [init]);

    return (
        <DashboardCard
            title={"Measured Time"}
            type="table"
            classes={{
                card: classes.card,
                cardHeader: classes.cardHeader,
                cardContent: classes.cardContent,
                cardActionArea: classes.cardActionArea,
            }}
            footerComponent={
                <Link
                    className={classes.link}
                    // to={{
                    //     pathname: PATHS.INSOURCE_VISITS_MANAGEMENT,
                    //     // search: generateQueryParams(filterValues).toString(),
                    // }}
                >
                    {`View Details >`}
                </Link>
            }
        >
            <div className={classes.tableWrapper}>
                <EnhancedTable
                    columns={TABLE_HEADERS}
                    rows={rowsData}
                    actions={[]}
                    updateSelected={() => {}}
                    loading={loading}
                    selectionDisabled
                    toolbarDisabled
                    paginationDisabled
                    emptyMessage="No Data"
                />
            </div>
        </DashboardCard>
    );
};

InstoreTimeTable.propTypes = {
    classes: PropTypes.object,
};

export default InstoreTimeTable;
