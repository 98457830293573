import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
        zIndex: 200,
        position: "relative",
    },
}));

const TransitionAlert = (props) => {
    const classes = useStyles();
    const { open, setOpen, className, children, action, disableExit, timer = -1, ...otherProps } = props;

    useEffect(() => {
        if (open && timer > 0) {
            setTimeout(() => {
                setOpen(false);
            }, timer);
        }
    }, [open]);

    return (
        <div className={`${classes.root} ${className ? className : ""}`}>
            <Collapse in={open}>
                <Alert
                    action={
                        <>
                            {action}
                            {!disableExit && (
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            )}
                        </>
                    }
                    {...otherProps}
                >
                    {children}
                </Alert>
            </Collapse>
        </div>
    );
};

export default TransitionAlert;
