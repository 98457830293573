import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import { COLORS } from "../../constants/constants-lagacy";

const useStyles = makeStyles({
    titleContainer: {
        width: "100%",
        fontSize: "4rem",
        fontWeight: "600",
        display: "inline-block",
        position: "relative",
    },
    title: {
        fontWeight: "500",
        textAlign: "left",
        paddingBottom: "3px",
        marginBottom: "0px",
        color: COLORS.accent,
    },
});

let PageSubHeader = (props) => {
    const classes = useStyles(props);
    return (
        <div className={classes.titleContainer}>
            <h4 className={classes.title}>{props.title}</h4>
            <Divider xs={12} />
        </div>
    );
};

PageSubHeader.propTypes = {
    classes: PropTypes.object,
};

export default PageSubHeader;
