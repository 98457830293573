import { firestore as db } from "../firebase";

export let getWeekendPrices = async (projectType, outlet_class_id = null, dayIDs = null) => {
    let prices = [];
    try {
        dayIDs = dayIDs || [];
        let ref = db.collection("Weekend_Price").where("project_type", "==", projectType);

        if (outlet_class_id) {
            ref = ref.where("class_id", "==", outlet_class_id);
        }
        if (dayIDs.length > 0) {
            prices = await loadWeekendPrices(outlet_class_id, projectType, dayIDs);
        } else {
            prices = (await ref.get()).docs;
        }
    } catch (error) {
        console.error(error);
    }

    return prices;
};

export let loadWeekendPrices = async (classID = null, projectType, workingDaysIDs) => {
    let weekendPrices = [];
    try {
        let weekendPriceRef = db.collection("Weekend_Price");

        if (classID) {
            weekendPrices = await Promise.all(
                workingDaysIDs.map(async (dayID) => {
                    const priceID = `${projectType}-${classID}-${dayID}`;
                    const weekendPrice = await weekendPriceRef.doc(priceID).get();
                    return weekendPrice;
                })
            );
            weekendPrices = weekendPrices.filter((wp) => (wp.exists ? true : false));
            // for (const dayID of workingDaysIDs) {
            //     const priceID = `${projectType}-${classID}-${dayID}`;
            //     const weekendPrice = await weekendPriceRef.doc(priceID).get();
            //     if (weekendPrice.exists) weekendPrices.push(weekendPrice);
            // }
        } else {
            weekendPrices = await Promise.all(
                workingDaysIDs.map(async (dayID) => {
                    let docs = (
                        await weekendPriceRef
                            .where("project_type", "==", projectType)
                            .where("day_id", "==", dayID)
                            .get()
                    ).docs;

                    return docs;
                })
            );

            weekendPrices = weekendPrices.reduce((arr, prices) => {
                return [...arr, ...prices];
            }, []);
            // for (const dayID of workingDaysIDs) {
            //     let docs = (await weekendPriceRef
            //         .where("project_type", "==", projectType)
            //         .where("day_id", "==", dayID)
            //         .get()).docs;

            //     weekendPrices = [...weekendPrices, ...docs];
            // }
        }
    } catch (err) {
        console.log(err);
    }

    return weekendPrices;
};
