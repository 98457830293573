import React, { useCallback, useEffect } from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";
import { CircularLoader, DashboardCard } from "../../../core-ui/custom";
import LineChart from "../../../core-ui/charts/line-chart";
import { formatDate } from "../../../helpers/date-utils";
import { Card } from "@material-ui/core";
import { Link } from "react-router-dom";
import PATHS from "../../../constants/paths";

const settings = {
    curve: "basis",

    enableGridX: false,
    margin: { top: 40, right: 80, bottom: 80, left: 60 },
    axisTop: null,
    axisRight: null,
    axisBottom: {
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 25,
        legend: "Outlets",
        legendOffset: 66,
        legendPosition: "middle",
    },
    axisLeft: {
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "No. Of SKUs",
        legendOffset: -40,
        legendPosition: "middle",
    },
    legends: [
        {
            anchor: "top",
            direction: "row",
            justify: false,
            translateX: 0,
            translateY: -30,
            itemsSpacing: 40,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
                {
                    on: "hover",
                    style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                    },
                },
            ],
        },
    ],
    pointSize: 1,
    pointBorderWidth: 2,
    pointLabelYOffset: -12,
    useMesh: true,
    // enableSlices: "y",
    // tooltip: (d) => {
    //     return (
    //         <Card style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
    //             <div style={{ width: 20, height: 20, backgroundColor: d.point.color, marginRight: 5 }}></div>
    //             <div style={{ marginRight: 5 }}>
    //                 <b>Out of Stock</b> {d.point.data.y}
    //             </div>
    //             <div style={{ marginRight: 5 }}>
    //                 <b>Date</b> {d.point.data.xFormatted}
    //             </div>
    //         </Card>
    //     );
    // },
};

const UnderConstructionChart = ({ title = "Soon", body = "Under Contruction" }) => {
    const classes = styles();

    return (
        <DashboardCard
            title={title}
            classes={{
                cardContainer: classes.cardContainer,
                card: classes.card,
                cardHeader: classes.cardHeader,
                cardContent: classes.cardContent,
            }}
        >
            <div className={classes.body}>{body}</div>
        </DashboardCard>
    );
};

export default UnderConstructionChart;
