// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/line
import { ResponsiveLine } from "@nivo/line";
import { useState } from "react";
import { useEffect } from "react";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const LineChart = ({ data, settings }) => {
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        let animation = setTimeout(() => setChartData(data), 1);

        return () => {
            clearTimeout(animation);
        };
    }, [data]);
    return <ResponsiveLine data={chartData} {...settings} />;
};

export default LineChart;
