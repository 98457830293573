import { lighten } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import COLORS from "../../constants/colors";

const styles = makeStyles({
    DateRangePickerWrap: {
        position: "relative",
        // margin: "20px 0",
        display: "inline-block",
    },

    dateRangeBtn: {
        backgroundColor: "transparent",
        border: "none",
        color: COLORS.BLACK,
        padding: "0",
        fontSize: "0.7rem",
        fontWeight: "bold",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: lighten(COLORS.BLACK, 0.95),
        },
    },

    startDate: {
        marginRight: 5,
    },

    endDate: {
        marginLeft: 5,
    },

    dateInput: {
        pointerEvents: "none",
        // width: "250px",

        "& .MuiInputBase-root": {
            color: COLORS.BLACK,
            fontSize: "0.8rem",
        },

        "& .MuiInputLabel-outlined": {
            fontSize: "0.7rem",
            transform: "translate(14px, 12px) scale(1)",
        },

        "& .MuiInputLabel-outlined.MuiInputLabel-marginDense": {
            fontSize: "0.7rem",
            transform: "translate(10px, 12px) scale(1)",
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(14px, -6px) scale(0.75)",
        },

        "& .MuiInputBase-root.MuiOutlinedInput-root": {
            padding: "6px 4px",
        },

        "& .MuiInputBase-root.MuiOutlinedInput-root .MuiInputBase-input.MuiOutlinedInput-input": {
            padding: "4px 4px",
        },

        "& .MuiOutlinedInput-notchedOutline legend": {
            fontSize: "0.5rem",
        },

        "& .MuiFormHelperText-root.Mui-error": {
            position: "absolute",
            top: "35px",
            left: 0,
            fontSize: "0.6rem",
            margin: 0,
        },
    },

    datePicker: {
        position: "absolute",
        top: "0",
        left: "0",
        zIndex: 999,
        boxShadow: "0px 0px 5px lightgray",
    },
    dateRangeFooter: {
        backgroundColor: "#fff",
        borderTop: "1px solid #e0e0e0",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "10px",
    },

    applyBtn: {
        backgroundColor: COLORS.SUBMIT,
        color: COLORS.WHITE,
        marginLeft: 5,
        "&:hover": {
            backgroundColor: lighten(COLORS.SUBMIT, 0.1),
        },
    },

    cancelBtn: {
        backgroundColor: COLORS.CANCEL,
        color: COLORS.WHITE,
        "&:hover": {
            backgroundColor: lighten(COLORS.CANCEL, 0.1),
        },
    },

    "@media (min-width: 900px)": {
        dateRangeBtn: {
            fontSize: "0.8rem",
        },
    },
});

export default styles;
