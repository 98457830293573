import { makeStyles } from "@material-ui/styles";

const styles = makeStyles({
    root: {
        zIndex: 10,
        position: "relative",
    },
    alertButton: {
        textTransform: "none",
        padding: "0px 5px",
        border: "1px solid #fff",
        marginLeft: "5px",
        color: "white",
        transition: "all 200ms linear",

        "&:hover": {
            backgroundColor: "white",
            color: "black",
        },
    },
});

export default styles;
