import { makeStyles } from "@material-ui/styles";

const styles = makeStyles({
    indicatorChartContainer: {
        position: "relative",
        // height: `139px`,
        // height: `85px`,
        height: "100%",

        // "@media (min-width: 850px)": {
        //     height: "85px",
        // },
    },
    card: {
        height: "100%",
    },
    indicatorTextWrap: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",

        "& h1, & h2": {
            margin: 0,
        },
    },
    indicatorTitle: {
        letterSpacing: ".0625rem",
        color: "#818ea3",
        fontSize: "0.9rem",
        textAlign: "center",
    },

    indicatorValue: {
        color: "#3d5170",
        fontSize: "1.75rem",
        fontWeight: 500,
    },

    rate: {
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
    },
});

export default styles;
