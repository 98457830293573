import { makeStyles } from "@material-ui/styles";

const styles = makeStyles({
    tableWrapper: {
        height: "230px",
        overflow: "auto",
        backgroundColor: "white",

        "& table": {
            whiteSpace: "normal",
            height: "100%",
        },

        "& table th": {
            fontSize: ".65rem",
            whiteSpace: "nowrap",
        },

        "& table td": {
            fontSize: ".6rem",
        },

        "& table td, & table th": {
            padding: "0 0 0 15px",
        },
    },
});

export default styles;
