// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import { ResponsiveBar } from "@nivo/bar";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const BarChart = ({ data, settings }) => (
    <ResponsiveBar
        data={data}
        keys={settings.keys}
        indexBy={settings.indexBy}
        layout={settings.layout}
        margin={{ top: 10, right: 30, bottom: 50, left: 60 }}
        padding={0.3}
        groupMode="grouped"
        maxValue={settings.maxValue ? settings.maxValue : "auto"}
        // valueScale={{ type: "linear" }}
        // indexScale={{ type: "band", round: true }}
        // colors={{ scheme: "nivo" }}
        colors={settings.colors}
        legends={settings.legends}
        label={settings.label}
        theme={settings.theme}
        gridXValues={settings.gridXValues}
        tooltip={settings.tooltip}
        borderColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={settings.axisBottom}
        axisLeft={settings.axisLeft}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={settings.labelTextColor}
    />
);

export default BarChart;
