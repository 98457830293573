import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import { COLORS } from "../../../constants/constants-lagacy";

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
}
const useStyles = makeStyles({
    root: {
        margin: "15px 0",
    },

    appBar: {
        backgroundColor: "#fff",
    },

    tabIndicator: {
        backgroundColor: COLORS.primary,
    },
    tabRoot: {
        color: "#151515",
        minWidth: "260px",
    },
    tabSelected: {
        color: COLORS.primary,
    },
});

let ProjectManagmentTabs = (props) => {
    const classes = useStyles(props);
    const { onTabSelect, tabIndex } = props;
    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar}>
                <Tabs
                    TabIndicatorProps={{
                        className: classes.tabIndicator,
                    }}
                    value={tabIndex}
                    onChange={onTabSelect}
                >
                    {props.isConfirmed ? null : (
                        <Tab
                            label="Coverage"
                            {...a11yProps(0)}
                            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                        />
                    )}
                    <Tab
                        label="Management"
                        {...a11yProps(1)}
                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                    />
                </Tabs>
            </AppBar>
        </div>
    );
};

ProjectManagmentTabs.propTypes = {
    classes: PropTypes.object,
};

export default ProjectManagmentTabs;
