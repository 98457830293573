import { makeStyles } from "@material-ui/styles";
import { COLORS } from "../../constants/constants-lagacy";

const styles = makeStyles({
    mdr: {
        padding: 8,
        margin: "20px",
    },

    pageHeader: {
        marginBottom: 8,
        fontSize: "0.8rem",
    },

    container: {
        overflowX: "scroll",
        overflowY: "hidden",
        whiteSpace: "nowrap",
    },

    notFound: {
        textAlign: "center",
        fontSize: "0.8rem",
    },
    taskCol: {
        backgroundColor: "pink",
        margin: "0",
        padding: "0",
        border: "solid 1px",
    },

    // table styles
    pdfButton: {
        marginTop: "12px",
        float: "right",
    },

    pdfIcon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "400",
        cursor: "pointer",
        color: "white",
        backgroundColor: COLORS.accent,
        borderRadius: "50%",
        width: "50px",
        height: "50px",
    },
    boldFont: {
        fontWeight: "bold",
    },

    tableContainer: {
        maxHeight: "calc(100vh - 100px)",
        overflow: "auto",
        width: "100%",
        // minWidth: "560px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    },
    table: {
        overflow: "auto",
        // minWidth: "560px",
        width: "100%",
        borderCollapse: "collapse",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: "14px",

        "& th": {
            // border: '1px solid black',
            border: "1px solid rgba(224, 224, 224,1)",
            color: COLORS.font,
            // backgroundColor: COLORS.primary,
            // backgroundColor: "#8b8b8b",
            textAlign: "center",
            fontSize: "0.8rem",
            padding: "5px 7px",
            whiteSpace: "nowrap",
        },
        "& th:last-child": {
            paddingRight: 0,
        },
        "& td": {
            // fontWeight: "700",
            fontSize: "0.75em",
            padding: "6px 12px",
            borderLeft: "2px solid rgba(224, 224, 224, 1)",
            borderRight: "2px solid rgba(224, 224, 224, 1)",
        },
        "& $textLeft": {
            textAlign: "left",
        },
    },
    textLeft: {
        textAlign: "left",
    },

    header: {
        position: "sticky",
        backgroundColor: "#0AA1DD",
        zIndex: 90,
    },
    headerColor: {
        color: "#A9333A",
    },
    excelIcon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "400",
        cursor: "pointer",
        color: "white",
        backgroundColor: "#1A4D2E",
        borderRadius: "50%",
        width: "50px",
        height: "50px",
    },

    darkHeader: {
        backgroundColor: "#397198",
    },

    productHeader: {
        top: 0,
        zIndex: 100,
        backgroundColor: COLORS.primary,
    },
    indexHeader: {
        left: 0,
    },

    prodNumberHeader: {
        left: "44px",
    },

    barcodeHeader: {
        left: "147px",
    },

    prodNameHeader: {
        left: "250px",
    },

    prodPackingHeader: {
        left: "704px",
    },

    firstRowHeader: {
        top: 0,
    },

    secondRowHeader: {
        top: "59px",
    },

    thirdRowHeader: {
        top: "94px",
    },

    fourthRowHeader: {
        top: "129px",
    },

    imageIcon: {
        color: "#606060",
        cursor: "pointer",
        position: "absolute",
        right: 0,
        "&:hover": {
            color: "#1a1a1a",
        },
    },
    productCell: {
        position: "sticky",
    },
    indexCell: {
        left: 0,
        textAlign: "center",
    },

    prodNoCell: {
        left: "44px",
        textAlign: "center",
    },

    barcodeCell: {
        left: "147px",
        textAlign: "center",
    },
    productNameCell: {
        left: "250px",
        textAlign: "left",
        whiteSpace: "nowrap",
    },
    packingCell: {
        left: "704px",
        textAlign: "center",
    },
    qtyCell: {
        textAlign: "center",
    },
    cellItem: {
        textAlign: "center",
        border: "solid 1px",
    },
    lightBlue: {
        backgroundColor: "#E8F9FD",
    },
    white: {
        backgroundColor: "#FFFFFF",
    },
});

export default styles;
