import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import logo from "../../../assets/images/logo.png";
import "../../../core-ui/core-styles/pdf/fonts";
import styles from "./styles";
import { getMerchandiserName } from "../../../services/firestore/Merchandiser";
import { formatDate, getDayName } from "../../../helpers/date-utils";
import { getDay } from "date-fns";
import { REPORT_IDS } from "../../../constants/constants-lagacy";
let rowsPerPage = 26;
let counter = 0;

let createRow = (row, style) => {
    if (!row)
        return (
            <View style={style} key={`PDF Row ${Math.random()}-${row.id}`}>
                <Text style={styles.lastCell}>Error getting the merchandiser's data</Text>
            </View>
        );

    function timestamp(time) {
        let date = time.toDate();
        return date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    }
    counter++;
    console.log(row);
    return (
        <View style={style} key={`PDF Row ${Math.random()}-${row.id}`}>
            <View style={styles.indexCell}>
                <Text>{counter}</Text>
            </View>
            <View style={styles.cell}>
                <Text>{getDayName(getDay(row.date))}</Text>
            </View>

            <View style={styles.cell}>
                <Text style={styles.cellText}>{formatDate(row.date)}</Text>
            </View>

            <View style={styles.rowDataGroup}>
                <View style={styles.lastDataRowGroup}>
                    <View style={styles.columnGroup}>
                        <View style={styles.cell}>
                            <Text>{row.totalDone}</Text>
                        </View>
                        <View style={styles.lastCell}>
                            <Text>{row.total}</Text>
                        </View>
                    </View>
                </View>
            </View>

            <View style={styles.cell}>
                <Text>{row.timeIn === "-" ? "-" : timestamp(row.timeIn)}</Text>
            </View>
            <View style={styles.cell}>
                <Text>{row.timeOut === "-" ? "-" : timestamp(row.timeOut)}</Text>
            </View>
            <View style={styles.lastCell}>
                <Text>{row.duration}</Text>
            </View>
        </View>
    );
};

let createPage = (merch, info, pageNum) => {
    let rowsToDisplay = merch.rows.slice(pageNum * rowsPerPage, pageNum * rowsPerPage + rowsPerPage);

    const companyLogo = info.companyData.logo;
    const companyName = info.companyData.company_name;
    const merchName = getMerchandiserName(merch);
    const start = formatDate(info.startDate, "/");
    const end = formatDate(info.endDate, "/");

    return (
        <Page size="A4" orientation="portrait" style={styles.page} key={`Attendance List ${Math.random()}-${pageNum}`}>
            <View style={styles.logoContainer}>
                {companyLogo && <Image style={styles.companyLogo} src={companyLogo} />}
                <View>
                    <Text style={styles.supplierName}>{companyName}</Text>
                </View>
            </View>

            <View style={styles.line}></View>

            <Text style={styles.header}>Merchandisers Attendance Report</Text>
            <View style={styles.headerContainer}>
                <View style={styles.left}>
                    <View>
                        <Text>
                            Merchandiser's Name: <Text style={styles.bold}>{merchName}</Text>
                        </Text>
                    </View>

                    <View>
                        <Text>
                            Date From: <Text style={styles.bold}>{start + "   "}</Text>
                        </Text>
                        <Text>
                            Date To: <Text style={styles.bold}>{end}</Text>
                        </Text>
                    </View>
                </View>
            </View>

            <View style={styles.content}>
                <View style={styles.table}>
                    {/* Table Header */}
                    <View style={styles.tableHeader}>
                        <View style={[styles.row, styles.headerRow]}>
                            <View style={styles.indexCell}>
                                <Text style={styles.headerCellText}></Text>
                            </View>
                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>Day</Text>
                            </View>

                            <View style={styles.headerCell}>
                                <Text>Date</Text>
                            </View>

                            {/* Cell Group spans mulitple rows */}
                            <View style={styles.rowGroup}>
                                <View style={styles.rowGroupRow}>
                                    <View style={styles.lastHeaderCell}>
                                        <Text style={styles.headerCellText}>Visits</Text>
                                    </View>
                                </View>
                                <View style={styles.lastRowGroupRow}>
                                    {/* Cell Group spans mulitple column */}
                                    <View style={styles.columnGroup}>
                                        <View style={styles.headerCell}>
                                            <Text style={styles.headerCellText}>D</Text>
                                        </View>
                                        <View style={styles.lastHeaderCell}>
                                            <Text style={styles.headerCellText}>P</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>

                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>Time in</Text>
                            </View>
                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>Time out</Text>
                            </View>
                            <View style={styles.lastHeaderCell}>
                                <Text style={styles.headerCellText}>Duration</Text>
                            </View>
                        </View>
                    </View>

                    {/* Table Body */}

                    <View style={styles.tableBody}>
                        {rowsToDisplay.map((row, index) => {
                            let style = styles.row;
                            if (index % 2 !== 0 && index === rowsToDisplay.length - 1) style = styles.lastRow;
                            else if (index % 2 === 0 && index !== rowsToDisplay.length - 1) style = styles.coloredRow;
                            else if (index % 2 === 0 && index === rowsToDisplay.length - 1)
                                style = styles.lastColoredRow;
                            return createRow(row, style);
                        })}
                    </View>
                </View>
            </View>
            {/* Footer */}
            <View style={styles.footerLine}></View>
            <View style={styles.idContainer}>
                <Text>{REPORT_IDS.MECHANDISERS_ATTENDANCE}</Text>
            </View>
            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
            />

            <View style={styles.logoFooterContainer}>
                <View style={styles.logoFooterFlex}>
                    <Text style={styles.logoFooterText}>Powered By:</Text>
                </View>
                <View style={styles.logoFooterFlex}>
                    <Image style={styles.logo} src={logo} />
                </View>
            </View>
        </Page>
    );
};
let createDoc = (info) => {
    const { suppliermerchandisers } = info;

    let pages = [];
    suppliermerchandisers.forEach((merch) => {
        counter = 0;
        const totalPages = Math.ceil(merch.rows.length / rowsPerPage);
        const lastPage = pages.length;
        for (let pageNum = lastPage; pageNum < lastPage + totalPages; pageNum++) {
            pages[pageNum] = createPage(merch, info, pageNum - lastPage);
        }
    });
    return pages;
};

// Create Document Component
const MerchandiserAttendanceReportPdf = (props) => {
    let document = createDoc(props.info);
    return <Document>{document}</Document>;
};

export default MerchandiserAttendanceReportPdf;
