import React from "react";
import styles from "./styles";
import { alpha, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import { useField } from "formik";
import COLORS from "../../../constants/colors";
import { RadioButton } from "../../custom";

const RadioGroupWrapper = ({ name, label, options }) => {
    const classes = styles({ options });
    const [field, meta, helpers] = useField(name);

    const handleChange = (e) => {
        const { value } = e.currentTarget;
        helpers.setValue(value);
    };

    const radioGroupConfig = {
        ...field,
        onChange: handleChange,
        className: classes.radioGroup,
    };

    const formControlConfig = {
        error: false,
        className: classes.formControl,
    };

    if (meta && meta.touched && meta.error) {
        formControlConfig.error = true;
    }

    return (
        <FormControl component="fieldset" {...formControlConfig}>
            <FormLabel focused={false} className={classes.label}>
                {label}
            </FormLabel>
            <RadioGroup {...radioGroupConfig}>
                {options.map((option, index) => {
                    return (
                        <RadioButton
                            key={`radio-option-${name}-${option.label}-${index}`}
                            value={option.value}
                            label={option.label}
                            selected={field.value === option.value}
                            color={option.color}
                            textColor={option.textColor}
                        />
                    );
                })}
            </RadioGroup>
        </FormControl>
    );
};

export default RadioGroupWrapper;
