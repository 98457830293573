import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { TableRow } from "@material-ui/core";
import { TextCell } from "../../../components-lagacy/Cell/Cells";
import { COLORS } from "../../../constants/constants-lagacy";

const useStyles = makeStyles({
    cell: {
        borderBottom: `2px solid ${COLORS.accent}`,
        backgroundColor: `${COLORS.accent}21`,
        textAlign: "center",
    },
});

let ScheduleHeaderRow = (props) => {
    const classes = useStyles(props);
    const { supplierBranch, numberOfColumns } = props;

    return (
        <TableRow>
            <TextCell text={supplierBranch.En_name} colSpan={numberOfColumns} className={classes.cell} />
        </TableRow>
    );
};

ScheduleHeaderRow.propTypes = {
    classes: PropTypes.object,
};

export default ScheduleHeaderRow;
