import { darken, lighten } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import COLORS from "../../constants/colors";

const styles = makeStyles({
    dialogRoot: {
        // width: "100%",
        // overflowY: "hidden",
    },

    cardContent: {
        padding: 0,
        paddingTop: "0 !important",
    },

    datePicker: {
        // position: "absolute",
        // top: "0",
        // left: "0",
        // zIndex: 999,
        boxShadow: "0px 0px 5px lightgray",
    },
    dateRangeFooter: {
        backgroundColor: "#fff",
        borderTop: "1px solid #e0e0e0",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "10px",
    },

    applyBtn: {
        backgroundColor: COLORS.SUBMIT,
        color: COLORS.WHITE,
        marginLeft: 5,
        "&:hover": {
            backgroundColor: lighten(COLORS.SUBMIT, 0.1),
        },
    },

    cancelBtn: {
        backgroundColor: COLORS.CANCEL,
        color: COLORS.WHITE,
        "&:hover": {
            backgroundColor: lighten(COLORS.CANCEL, 0.1),
        },
    },
});

export default styles;
