import { firestore as db } from "../firebase";

export let getWeekendEDPrices = async (extraDisplayIDs = [], dayIDs = []) => {
    let prices = [];
    try {
        let ref = db.collection("Weekend_Extra_Display_Price");

        if (dayIDs.length > 0) {
            prices = await loadWeekendExtraDisplayPrices(extraDisplayIDs, dayIDs);
        } else {
            prices = await Promise.all(
                extraDisplayIDs.map(async (id) => {
                    return (await ref.where("extra_display_id", "==", id).get()).docs;
                })
            );
            prices = prices.reduce((arr, prices) => [...arr, ...prices], []);

            // for (const extraDisplayID of extraDisplayIDs) {
            //     let weekendPrices = (await ref.where("extra_display_id", "==", extraDisplayID).get()).docs;
            //     prices = [...prices, ...weekendPrices];
            // }
        }
    } catch (error) {
        console.error(error);
    }

    return prices;
};

export let loadWeekendExtraDisplayPrices = async (extraDisplayIDs, workingDaysIDs) => {
    let weekendPrices = [];
    try {
        let weekendExtraDisplayPricesRef = db.collection("Weekend_Extra_Display_Price");

        weekendPrices = await Promise.all(
            extraDisplayIDs.map(async (exID) => {
                let prices = await Promise.all(
                    workingDaysIDs.map(async (dayID) => {
                        const priceID = `${exID}-${dayID}`;
                        return await weekendExtraDisplayPricesRef.doc(priceID).get();
                    })
                );
                prices = prices.filter((p) => (p.exists ? true : false));
                return prices;
            })
        );
        weekendPrices = weekendPrices.reduce((arr, prices) => [...arr, ...prices], []);

        // for (const extraDisplayID of extraDisplayIDs) {
        //     for (const dayID of workingDaysIDs) {
        //         const priceID = `${extraDisplayID}-${dayID}`;
        //         let weekendPrice = await weekendExtraDisplayPricesRef.doc(priceID).get();
        //         if (weekendPrice.exists) weekendPrices.push(weekendPrice);
        //     }
        // }
    } catch (err) {
        console.error(err);
    }
    return weekendPrices;
};
