import { darken } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import COLORS from "../../constants/colors";

const styles = makeStyles({
    filtersRoot: {
        width: "100%",
    },
    filter: {
        "& .MuiInputBase-root": {
            fontSize: "0.8rem",
        },

        "& .MuiInputLabel-outlined.MuiInputLabel-marginDense": {
            fontSize: "0.7rem",
            transform: "translate(10px, 12px) scale(1)",
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(14px, -6px) scale(0.75)",
        },

        "& .MuiInputBase-root.MuiOutlinedInput-root": {
            padding: "6px 4px",
        },

        "& .MuiInputBase-root.MuiOutlinedInput-root .MuiInputBase-input.MuiOutlinedInput-input": {
            padding: "4px 4px",
        },

        "& .MuiOutlinedInput-notchedOutline legend": {
            fontSize: "0.5rem",
        },
    },

    option: {
        padding: "2px 8px",
        fontSize: "0.8rem",
    },

    clearFiltersButton: {
        backgroundColor: COLORS.ACCENT,
        padding: "0px 16px",
        height: "35px",
        width: "calc(50% - 5px)",
        marginRight: "5px",
        "&:hover": {
            backgroundColor: darken(COLORS.ACCENT, 0.3),
        },
    },
    submitButton: {
        backgroundColor: COLORS.SUBMIT,
        padding: "0px 16px",
        height: "35px",
        width: "50%",
        "&:hover": {
            backgroundColor: darken(COLORS.SUBMIT, 0.3),
        },
    },
});

export default styles;
