import React from "react";
import { makeStyles } from "@material-ui/core";

const styles = makeStyles({
    text: {
        fontWeight: 600,
        fontSize: "2.55rem",
    },
    "@media screen and (min-width: 1200px)": {
        text: {
            fontSize: "3rem",
        },
    },
});
const Title = ({ children, className, ...otherProps }) => {
    const classes = styles();

    const config = {
        ...otherProps,
        className: `${classes.text} ${className ?? ""}`,
    };
    return <h1 {...config}>{children}</h1>;
};

export default Title;
