import { makeStyles } from "@material-ui/styles";
import COLORS from "../../constants/colors";

const styles = makeStyles({
    pageRoot: {
        padding: 20,
    },

    pageHeader: {
        color: COLORS.ACCENT,
    },
    content: {
        maxWidth: 920,
        margin: "15px auto 0 auto",
    },
    content: {
        maxWidth: 920,
        margin: "15px auto 0 auto",
    },
    cardContainer: {
        margin: "15px auto 0 auto",
        maxWidth: 720,
    },

    cardTitle: {
        textTransform: "uppercase",
        fontSize: "0.85rem",
        fontWeight: "bold",
        color: "grey",
    },
    formCard: {
        padding: 15,
    },

    input: {
        "& .MuiInputBase-root": {
            fontSize: "0.8rem",
        },

        "& .MuiInputLabel-outlined": {
            fontSize: "0.7rem",
            transform: "translate(14px, 12px) scale(1)",
        },

        "& .MuiInputLabel-outlined.MuiInputLabel-marginDense": {
            fontSize: "0.7rem",
            transform: "translate(10px, 12px) scale(1)",
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(14px, -6px) scale(0.75)",
        },

        "& .MuiInputBase-root.MuiOutlinedInput-root": {
            padding: "6px 4px",
        },

        "& .MuiInputBase-root.MuiOutlinedInput-root .MuiInputBase-input.MuiOutlinedInput-input": {
            padding: "4px 4px",
        },

        "& .MuiOutlinedInput-notchedOutline legend": {
            fontSize: "0.5rem",
        },

        "& .MuiFormHelperText-root.Mui-error": {
            position: "absolute",
            top: "35px",
            left: 0,
            fontSize: "0.6rem",
            margin: 0,
        },
    },

    inputRtl: {
        direction: "rtl",

        "& label": {
            direction: "ltr",
        },
    },

    fileButton: {
        width: "100%",
        height: 100,
        borderRadius: 5,
        backgroundColor: "#c3c3c3",

        "&:hover": {
            backgroundColor: "#c3c3c3",
        },
        "&:hover:before": {
            content: "'Change Health Card'",
            position: "absolute",
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            color: "white",
            fontSize: "0.65rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        "& img": {
            objectFit: "contain",
        },
    },

    fbTranparentBackground: {
        backgroundColor: "transparent",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },

    option: {
        padding: "2px 8px",
        fontSize: "0.8rem",
    },

    submitBtn: {
        width: 80,
        marginTop: 15,
        float: "right",
        textTransform: "none",
    },

    circularProgress: {
        width: "25px !important",
        height: "25px !important",
        padding: 2,
    },
});

export default styles;
