import React, { useCallback, useEffect } from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";
import { Button, CircularLoader, DashboardCard } from "../../../core-ui/custom";
import LineChart from "../../../core-ui/charts/line-chart";
import { formatDate } from "../../../helpers/date-utils";
import { Card } from "@material-ui/core";
import { Link } from "react-router-dom";
import PATHS from "../../../constants/paths";
import { useState } from "react";
import OutOfStockTableDialog from "../out-of-stock-table-dialog";

const settings = {
    curve: "basis",
    enableArea: true,
    enableGridX: false,
    margin: { top: 40, right: 40, bottom: 20, left: 60 },
    axisTop: null,
    axisRight: null,
    // axisBottom: {
    //     orient: "bottom",
    //     tickSize: 5,
    //     tickPadding: 5,
    //     tickRotation: 25,
    //     legend: "Products",
    //     legendOffset: 66,
    //     legendPosition: "middle",
    // },
    axisBottom: null,
    axisLeft: {
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Frequancy",
        legendOffset: -40,
        legendPosition: "middle",
    },
    yScale: {
        type: "linear",
        min: 0,
        max: 10,
        stacked: false,
        reverse: false,
    },
    legends: [
        {
            anchor: "top",
            direction: "row",
            justify: false,
            translateX: 0,
            translateY: -30,
            itemsSpacing: 40,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
                {
                    on: "hover",
                    style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                    },
                },
            ],
        },
    ],
    pointSize: 1,
    pointBorderWidth: 2,
    pointLabelYOffset: -12,
    useMesh: true,
    // enableSlices: "y",
    // tooltip: (d) => {
    //     return (
    //         <Card style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
    //             <div style={{ width: 20, height: 20, backgroundColor: d.point.color, marginRight: 5 }}></div>
    //             <div style={{ marginRight: 5 }}>
    //                 <b>Out of Stock</b> {d.point.data.y}
    //             </div>
    //             <div style={{ marginRight: 5 }}>
    //                 <b>Date</b> {d.point.data.xFormatted}
    //             </div>
    //         </Card>
    //     );
    // },
};

const OutOfStockLine = ({
    data = [],
    colors = ["#e3c381"],
    footerAction = () => {},
    lineSettings = {},
    loading = false,
}) => {
    const classes = styles();
    let content =
        data.length > 0 ? (
            <LineChart data={data} settings={{ ...settings, ...lineSettings, colors: colors }} />
        ) : (
            <span>No Out of Stock Data</span>
        );
    if (loading) {
        content = <CircularLoader className={classes.loader} />;
    }

    return (
        <DashboardCard
            title={"Out Of Stock"}
            classes={{
                cardContainer: classes.cardContainer,
                card: classes.card,
                cardHeader: classes.cardHeader,
                cardContent: classes.cardContent,
                cardActionArea: classes.cardActionArea,
            }}
            footerComponent={
                <Button className={classes.button} onClick={footerAction}>
                    {`View Details >`}
                </Button>
            }
        >
            {content}
        </DashboardCard>
    );
};

export default OutOfStockLine;
