import styles from "../../style.module.css";

const Handle = ({ isDragStarted = false, positionLeft, onDragStop, onDragStart, elementRefference }) => (
    <span
        onMouseDown={onDragStart}
        style={{ left: positionLeft }}
        onMouseUp={onDragStop}
        ref={elementRefference}
        className={`${styles["cd-handle"]} ${isDragStarted && styles["draggable"]}`}
    ></span>
);

export default Handle;
