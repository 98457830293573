import React from "react";
import { Button, TextField } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

import { useField } from "formik";
import styles from "./styles";
import { FileButton } from "..";

const FileUpload = ({ name, accept = "images", ...otherProps }) => {
    const [field, meta, helpers] = useField(name);
    const classes = styles();

    const handleFileSelection = (e) => {
        if (e.target.files.length <= 0) {
            helpers.setValue(null);
            return;
        }

        const file = e.target.files[0];
        helpers.setTouched(true);
        helpers.setValue(file);
    };

    const inputConfig = {
        endAdornment: (
            <>
                {field.value && (
                    <Button disableRipple onClick={() => helpers.setValue(null)} className={classes.cancelButton}>
                        <ClearIcon />
                    </Button>
                )}
                <FileButton
                    className={classes.uploadButton}
                    onChange={handleFileSelection}
                    accept={accept}
                    hoverText={null}
                >
                    Upload
                </FileButton>
            </>
        ),
    };

    const handleChange = (e) => {
        field.onChange(e);
    };

    const config = {
        ...field,
        ...otherProps,
        value: field.value ? field.value.name || field.value : "No File Selected",
        onChange: handleChange,
        onBlur: () => {},
        variant: "outlined",
        fullWidth: true,
        classes: {
            root: classes.root,
        },
        disabled: true,
        InputProps: {
            ...inputConfig,
        },
    };

    if (meta && meta.touched && meta.error) {
        config.error = true;
        config.helperText = meta.error;
    }

    return <TextField {...config} />;
};

export default FileUpload;
