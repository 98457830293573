import React, { useState, useCallback, useEffect } from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";
import { Header } from "../../core-ui/custom";
import { useAuth } from "../../contexts/auth-context";

const Asterisk = (props) => {
    const classes = styles();

    return (
        <>
            {props.children}
            <span className={classes.asterisk}>*</span>
        </>
    );
};

export default Asterisk;
