import React from "react";
import styles from "./styles";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useHistory } from "react-router";
import NavigationExpandedSubMenu from "../navigation-expanded-sub-menu";

const NavigationExpandedMenuItem = (props) => {
    const history = useHistory();
    const classes = styles();
    const { label, menu, link, index, activeMenuPath, level, onClick } = props;

    const handleButtonClick = () => {
        if (menu?.length > 0) {
            onClick(level, index);
        } else if (link) {
            history.push(link);
            onClick(0, -1);
        }
    };

    const buttonConfig = {
        endIcon: null,
        onClick: handleButtonClick,
    };

    if (menu?.length > 0) {
        if (activeMenuPath[level] !== index) buttonConfig.endIcon = <AddIcon />;
        else buttonConfig.endIcon = <RemoveIcon />;
    }

    if (activeMenuPath.length > level && activeMenuPath[level] !== index) return null;

    return (
        <li className={`${classes.secondMenuItem}`}>
            <Button {...buttonConfig}>{label}</Button>

            {activeMenuPath[level] === index && menu?.length > 0 && (
                <NavigationExpandedSubMenu
                    menu={menu}
                    level={level + 1}
                    onItemClick={onClick}
                    activeMenuPath={activeMenuPath}
                />
            )}
        </li>
    );
};

export default NavigationExpandedMenuItem;
