import React, { useEffect, useRef, useState } from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";
import { Button, DateRangePicker } from "../../core-ui/custom";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { TextField } from "@material-ui/core";
import { formatDate } from "../../helpers/date-utils";
import { DateRangeDialog } from "..";

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const DateRangePickerWrap = ({ ranges, onApply, ...otherProps }) => {
    const classes = styles();
    const datePickerRef = useRef();
    const [show, setShow] = useState(false);
    const [buffer, setBuffer] = useState(ranges[0] || {});
    const { startDate, endDate } = ranges[0];

    const startLabel = `${months[startDate.getMonth()]} ${
        startDate.getDate() < 10 ? `0${startDate.getDate()}` : startDate.getDate()
    }, ${startDate.getFullYear()}`;
    const endLabel = `${months[endDate.getMonth()]} ${
        endDate.getDate() < 10 ? `0${endDate.getDate()}` : endDate.getDate()
    }, ${endDate.getFullYear()}`;
    const dateRangeLabel = `${startLabel} - ${endLabel}`;

    const changeHandler = (dateRange) => {
        setBuffer(dateRange.selection);
    };

    const applyHandler = () => {
        setShow(false);
        onApply(buffer);
    };

    const cancelHandler = () => {
        setBuffer(ranges[0]);
        setShow(false);
    };

    // useEffect(() => {
    //     const handleOutsideAccountContentClick = (e) => {
    //         if (datePickerRef && !datePickerRef.current?.contains(e.target)) {
    //             cancelHandler();
    //         }
    //     };
    //     document.addEventListener("mousedown", handleOutsideAccountContentClick);

    //     return () => {
    //         document.removeEventListener("mousedown", handleOutsideAccountContentClick);
    //     };
    // }, [show]);

    return (
        <div className={classes.DateRangePickerWrap}>
            <Button className={classes.dateRangeBtn} onClick={() => setShow((prev) => !prev)}>
                {/* <b>{dateRangeLabel}</b>
                {show ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} */}
                <TextField
                    value={formatDate(startDate, "/")}
                    disabled
                    variant="outlined"
                    label="Start Date"
                    className={`${classes.dateInput} ${classes.startDate}`}
                />
                to
                <TextField
                    value={formatDate(endDate, "/")}
                    disabled
                    variant="outlined"
                    label="End Date"
                    className={`${classes.dateInput} ${classes.endDate}`}
                />
            </Button>
            {/* {show && (
                <div className={classes.datePicker} ref={datePickerRef}>
                    <DateRangePicker ranges={[buffer]} onChange={changeHandler} {...otherProps} />
                    <div className={classes.dateRangeFooter}>
                        <Button className={classes.cancelBtn} onClick={cancelHandler}>
                            Cancel
                        </Button>
                        <Button className={classes.applyBtn} onClick={applyHandler}>
                            Apply
                        </Button>
                    </div>
                </div>
            )} */}
            <DateRangeDialog
                applyHandler={applyHandler}
                buffer={buffer}
                cancelHandler={cancelHandler}
                changeHandler={changeHandler}
                onClose={() => setShow(false)}
                open={show}
                {...otherProps}
            />
        </div>
    );
};

DateRangePickerWrap.propTypes = {
    classes: PropTypes.object,
};

export default DateRangePickerWrap;
