import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import { Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useState } from "react";
import { useEffect } from "react";
import { getMerchandiserName } from "../../services/firestore/Merchandiser";
import { formatDate } from "../../helpers/date-utils";

// const useStyles = makeStyles({
//     table: { tableLayout: "auto", whiteSpace: "nowrap" },
//     tableCell: {
//         color: "#FFFFFF",
//         paddingLeft: "10px",
//         paddingRight: "10px",
//         paddingBottom: "5px",
//         paddingTop: "5px",
//         textAlign: "center",
//         backgroundColor: COLORS.primary,
//         position: "sticky",
//         top: -16,
//         zIndex: 100,
//     },
//     addButton: {
//         textTransform: "none",
//         color: "#FFFFFF",
//         backgroundColor: COLORS.submit,
//         marginBottom: "10px",
//         "&:hover": {
//             backgroundColor: darken(COLORS.submit, 0.2),
//         },
//     },
//     autoCompleteList: {
//         width: "100%",
//         height: "100%",
//     },
//     textCell: {
//         textAlign: "center",
//     },
//     buttonCell: {
//         textAlign: "center",
//         width: "25px",
//         padding: 0,
//     },
// });

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: "12px",
        top: "12px",
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);
// let tableHeads = [
//     { id: "product", label: "Product" },
//     { id: "contract_id", label: "Contract No." },
//     { id: "description", label: "Description" },
//     { id: "type", label: "Type" },
//     { id: "start", label: "Start Date" },
//     { id: "end", label: "End Date" },
//     { id: "actions", label: "" },
// ];
let RouteEditDialog = (props) => {
    // const classes = useStyles(props);
    const { route, onSubmit, merchandisersList, supervisorsList, open, inOperations = false } = props;
    const [name, setName] = useState("");
    const [merchandiser, setMerchandiser] = useState(null);
    const [supervisor, setSupervisor] = useState(null);
    const [travelTime, setTravelTime] = useState(route?.avg_travel_time || 0);

    const handleSubmit = () => {
        onSubmit(route, { name, merchandiser, supervisor, travelTime });
    };

    useEffect(() => {
        if (open) {
            if (inOperations) {
                const merch = merchandisersList.find((m) => m.info.uid === route.tasksOwner) || null;
                setMerchandiser(merch);
            } else {
                const merch = merchandisersList.find((m) => m.info.uid === route.merchandiser_id) || null;
                const supervisor = supervisorsList.find((s) => s.uid === route.supervisor_id) || null;
                setMerchandiser(merch);
                setSupervisor(supervisor);
            }
            setName(route?.name || "");
            setTravelTime(route?.avg_travel_time || 0);
        } else {
            setName("");
            setTravelTime(0);
            setMerchandiser(null);
            setSupervisor(null);
        }
    }, [open, route, merchandisersList, supervisorsList, inOperations]);

    return (
        <Dialog onClose={props.onClose} open={open} fullWidth={true} maxWidth="xl">
            <DialogTitle
                id="customized-dialog-title"
                onClose={props.onClose}
                style={{ padding: "20px 56px", textAlign: "center" }}
            >
                {props.title}
            </DialogTitle>
            <DialogContent dividers style={{ padding: "16px 24px" }}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Route Name"
                            value={name}
                            onChange={(ev) => {
                                setName(ev.target.value);
                            }}
                            disabled={inOperations}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Average Travel Time"
                            value={travelTime}
                            onChange={(ev) => {
                                setTravelTime(+ev.target.value);
                            }}
                            disabled={inOperations}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Autocomplete
                            id="merchandisers-list"
                            // className={classes.autoCompleteList}
                            options={merchandisersList}
                            groupBy={(option) =>
                                option.contract
                                    ? `${option.contract.number} (expires ${formatDate(
                                          option.contract.end_date.toDate()
                                      )})`
                                    : "Insource"
                            }
                            value={merchandiser}
                            getOptionLabel={(option) => getMerchandiserName(option.info)}
                            renderInput={(params) => (
                                <TextField {...params} label="Assign Merchandiser" variant="outlined" />
                            )}
                            onChange={(event, merchandiser) => setMerchandiser(merchandiser)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Autocomplete
                            id="supervisors-list"
                            // className={classes.autoCompleteList}
                            options={supervisorsList}
                            value={supervisor}
                            getOptionLabel={(option) => getMerchandiserName(option)}
                            renderInput={(params) => (
                                <TextField {...params} label="Assign Supervisor" variant="outlined" />
                            )}
                            onChange={(event, supervisor) => setSupervisor(supervisor)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ padding: "8px 24px" }}>
                <Button onClick={handleSubmit} color="primary" style={{ padding: "6px 0px" }}>
                    {props.submitText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RouteEditDialog;
