import React, { useCallback, useEffect } from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";
import { CircularLoader, DashboardCard } from "../../../core-ui/custom";
import LineChart from "../../../core-ui/charts/line-chart";
import { formatDate } from "../../../helpers/date-utils";
import { Card } from "@material-ui/core";
import { Link } from "react-router-dom";

const settings = {
    curve: "basis",
    enableArea: true,
    enableGridX: false,
    margin: { top: 10, right: 60, bottom: 30, left: 60 },
    axisTop: null,
    axisRight: null,
    axisBottom: null,
    pointSize: 1,
    pointBorderWidth: 2,
    pointLabelYOffset: -12,
    useMesh: true,
    // axisBottom: {
    //     orient: "bottom",
    //     tickSize: 5,
    //     tickPadding: 5,
    //     tickRotation: 25,
    //     legend: "Period",
    //     legendOffset: 66,
    //     legendPosition: "middle",
    // },
    axisLeft: {
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Out of Stock",
        legendOffset: -40,
        legendPosition: "middle",
    },
    // enableSlices: "y",
    tooltip: (d) => {
        return (
            <Card style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ width: 20, height: 20, backgroundColor: d.point.color, marginRight: 5 }}></div>
                <div style={{ marginRight: 5 }}>
                    <b>Out of Stock</b> {d.point.data.y}
                </div>
                <div style={{ marginRight: 5 }}>
                    <b>Date</b> {d.point.data.xFormatted}
                </div>
            </Card>
        );
    },
};

const OutOfStockTrendLine = ({ tasks, taskDetails, startDate, endDate, loading = false }) => {
    const classes = styles();
    const [lineData, setLineData] = React.useState([]);

    const init = useCallback(async () => {
        try {
            setLineData([]);
            setup(tasks, taskDetails, startDate, endDate);
        } catch (error) {
            console.log(error);
        }
    }, [tasks, taskDetails, startDate, endDate]);

    const setup = useCallback(async (tasks, taskDetails, startDate, endDate) => {
        try {
            const lineData = [
                {
                    id: "Out of Stock",
                    color: "hsl(84, 70%, 50%)",
                    data: [],
                },
            ];
            // traverse from start date to end date
            for (
                let currentDate = new Date(startDate);
                currentDate <= endDate;
                currentDate.setDate(currentDate.getDate() + 1)
            ) {
                // get task details for current date
                let totalDisplays = 0;
                let outOfStockDisplays = [];

                taskDetails.forEach((td) => {
                    //get task info
                    // const task = tasks.find((task) => `${task.task_id}` === `${td.task_id}`);
                    const task = td.task;

                    if (formatDate(task.date_time_from.toDate()) !== formatDate(currentDate)) return;
                    totalDisplays += 1;
                    // calculate the percentage of out of stock products
                    //determine if the product is out of stock
                    const isOutOfStock = td.quantity_on + td.quantity_added === 0;
                    if (!isOutOfStock) return;

                    outOfStockDisplays.push({
                        id: td.id,
                        productID: td.product_id,
                        outletBranchID: task.outlet_branch_id,
                    });
                });

                // calculate the percentage of out of stock products
                const percentage =
                    totalDisplays > 0 ? ((outOfStockDisplays.length / totalDisplays) * 100).toFixed(2) : 0;
                // push the data to the line chart
                lineData[0].data.push({
                    x: formatDate(currentDate),
                    y: Number(percentage),
                });
            }
            setLineData(lineData);
        } catch (error) {
            setLineData([]);
            console.log(error);
        }
    }, []);

    useEffect(() => {
        init();
    }, [init]);

    let content =
        lineData.length > 0 ? <LineChart data={lineData} settings={settings} /> : <span>No Out of Stock Trend</span>;
    if (loading) {
        content = <CircularLoader />;
    }

    return (
        <DashboardCard
            title={"Out of Stock Trend"}
            classes={{
                card: classes.card,
                cardHeader: classes.cardHeader,
                cardContent: classes.cardContent,
                cardActionArea: classes.cardActionArea,
            }}
            footerComponent={
                <Link
                    className={classes.link}
                    // to={{
                    //     pathname: PATHS.INSOURCE_VISITS_MANAGEMENT,
                    //     // search: generateQueryParams(filterValues).toString(),
                    // }}
                >
                    {`View Details >`}
                </Link>
            }
        >
            {content}
        </DashboardCard>
    );
};

OutOfStockTrendLine.propTypes = {
    classes: PropTypes.object,
};

export default OutOfStockTrendLine;
