import React, { Component } from "react";
//related to meterial ui package
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import ProjectManagementTabs from "../tabs/ProjectManagmentTabs";
import ProjectCoverage from "./ProjectCoverage";
import ProjectManagementContent from "./ProjectManagementContent";

//utils
import TransitionAlert from "../../../core-ui/custom/transition-alert";
import FullPageLoader from "../../../components-lagacy/Loader/FullPageLoader";
import { getWholeProject, syncProject } from "../../../services/firestore/Project";
import { getSupplier } from "../../../helpers/firestore-utils";

const useStyles = (theme) => ({
    container: {
        width: "100%",
        padding: "10px 20px",
        position: "relative",
    },

    contentCard: {
        width: "100%",
        // maxWidth: "1290px",
    },
    loader: {
        marginTop: "35px",
    },

    stickyAlert: {
        position: "sticky",
        top: 10,
        zIndex: 10,
    },
});

class ProjectController extends Component {
    state = {
        loading: true,
        tabIndex: 0,
        projectFullData: null,
        isConfirmed: false,
        supplier: null,

        alertOpened: false,
        alertMsg: "",
        alertProps: {
            variant: "filled",
            severity: "success",
        },
    };

    init = async () => {
        this.setState({ loading: true });
        try {
            const supplierID = this.props.supplierID;
            let requests = [getWholeProject(this.props.projectID, true), getSupplier(supplierID)];
            let [projectFullData, supplier] = await Promise.all(requests);

            this.setState({
                supplier: supplier,
                projectFullData: projectFullData,
                isConfirmed: projectFullData.data.isConfirmed,
                loading: false,
            });
        } catch (error) {
            console.log(error);
            this.setState({ loading: false });
        }
    };

    updateProjectData = (updatedProjectData) => {
        this.setState({ projectData: updatedProjectData });
    };

    sync = async (newProjectFullData, msg = "The Project's data has been successfully saved.") => {
        try {
            let updated = await syncProject(newProjectFullData);

            this.setState({
                projectFullData: { ...updated },
                alertMsg: msg,
                alertOpened: true,
            });
        } catch (error) {
            console.log(error);
        }
    };

    setAlertOpen = (bool) => {
        this.setState({ alertOpened: bool });
    };

    componentDidMount() {
        this.init();
    }

    render() {
        const classes = this.props.classes;
        return (
            <div
                style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column" }}
                className={classes.container}
            >
                <TransitionAlert
                    variant="filled"
                    open={this.state.alertOpened}
                    setOpen={this.setAlertOpen}
                    className={classes.stickyAlert}
                >
                    {this.state.alertMsg}
                </TransitionAlert>
                {this.state.loading ? (
                    <FullPageLoader loading={this.state.loading} className={classes.loader} />
                ) : (
                    <>
                        {/*         Tabs         */}
                        <ProjectManagementTabs
                            isConfirmed={this.state.isConfirmed}
                            tabIndex={this.state.tabIndex}
                            onTabSelect={(event, newIndex) => this.setState({ tabIndex: newIndex })}
                        />

                        {/*        Coverage        */}
                        {this.state.tabIndex === 0 && !this.state.isConfirmed && !this.state.loading ? (
                            <ProjectCoverage
                                projectFullData={this.state.projectFullData}
                                sync={this.sync}
                                projectType={this.props.projectType}
                            />
                        ) : null}

                        {/*        Management        */}

                        {(this.state.tabIndex === 1 || this.state.isConfirmed) && !this.state.loading ? (
                            <ProjectManagementContent
                                projectFullData={this.state.projectFullData}
                                supplier={this.state.supplier}
                                projectType={this.props.projectType}
                                syncProject={this.sync}
                                updateProjectData={this.updateProjectData}
                                onBackClicked={this.props.onBackClicked}
                            />
                        ) : null}
                    </>
                )}
            </div>
        );
    }
}

ProjectController.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(useStyles)(ProjectController);
