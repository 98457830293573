import { COLORS } from "../../../constants/constants-lagacy";
import { StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
    page: {
        // flexDirection: "row",
        // backgroundColor: "#E4E4E4",
        padding: "20px",
    },
    section: {
        margin: 10,
        padding: 10,
        // flexGrow: 1,
    },

    logoContainer: {
        display: "flex",
        width: "100%",
        height: "50px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },

    companyLogo: {
        width: "auto",
        height: "100%",
        display: "block",
        objectFit: "contain",
    },

    supplierHeaderText: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "center",
    },

    alignStart: {
        alignItems: "flex-start",
    },

    supplierText: {
        fontSize: 8,
        color: COLORS.accent,
    },

    supplierName: {
        fontSize: 12,
        fontWeight: "bold",
    },

    logoFooterContainer: {
        position: "absolute",
        bottom: 27,
        right: 30,
        fontSize: 8,
        color: "grey",

        height: "30px",
        justifyContent: "center",
    },

    logoFooterText: {
        fontSize: 12,
        justifyContent: "center",
        textAlign: "center",
    },

    logo: {
        width: "100%",
        height: "100%",
        display: "block",
        objectFit: "contain",
    },
    eMaham: {
        flex: "1",
        textAlign: "right",
    },

    red: {
        color: COLORS.accent,
    },

    line: {
        width: "100%",
        height: "2px",
        // padding: "0 20px",
        marginTop: "5px",
        backgroundColor: "grey",
    },

    header: {
        textAlign: "center",
        fontSize: 16,
        color: COLORS.accent,
        textDecoration: "underline",
        margin: "20px 0",
    },

    headerContainer: {
        width: "100%",
        fontSize: 8,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },

    left: {
        flex: 1,
        textAlign: "left",
    },
    right: {
        flex: 1,
        textAlign: "right",
    },

    bold: {
        fontFamily: "Roboto",
        fontWeight: "bold",
    },

    table: {
        width: "100%",
        margin: "5px 0 0 0",
        border: 1,
        fontFamily: "Roboto",
    },

    headerRow: {
        display: "flex",
        flexDirection: "row",
        fontSize: 8,
        borderBottom: 1,
        backgroundColor: COLORS.primary,
        color: "white",
        fontWeight: "bold",
    },

    row: {
        display: "flex",
        flexDirection: "row",
        fontSize: 8,
        borderBottom: 1,
        fontWeight: "bold",
    },

    coloredRow: {
        display: "flex",
        flexDirection: "row",
        fontSize: 8,
        borderBottom: 1,
        backgroundColor: "#eef5ff",
        fontWeight: "bold",
    },

    lastRow: {
        display: "flex",
        flexDirection: "row",
        fontSize: 8,
        fontWeight: "bold",
    },

    lastColoredRow: {
        display: "flex",
        flexDirection: "row",
        fontSize: 8,
        backgroundColor: "#eef5ff",
        fontWeight: "bold",
    },

    headerCell: {
        flex: 1,
        height: "100%",
        textAlign: "center",
        padding: "5px 0px",
        borderRight: 1,

        display: "flex",
        justifyContent: "center",
    },
    headerCellWide: {
        flex: 3,
        height: "100%",
        textAlign: "center",
        padding: "5px 0px",
        borderRight: 1,

        display: "flex",
        justifyContent: "center",
    },

    lastHeaderCell: {
        flex: 1,
        height: "100%",
        textAlign: "center",
        padding: "5px 0px",

        display: "flex",
        justifyContent: "center",
    },

    cell: {
        flex: 1.352,
        height: "100%",
        textAlign: "center",
        padding: "5px 0",
        borderRight: 1,

        display: "flex",
        justifyContent: "center",
    },

    dayCell: {
        flex: 0.373,
        height: "100%",
        textAlign: "center",
        padding: "5px 0",
        borderRight: 1,

        display: "flex",
        justifyContent: "center",
    },

    dayCellColored: {
        flex: 0.373,
        height: "100%",
        textAlign: "center",
        padding: "5px 0",
        borderRight: 1,
        backgroundColor: COLORS.accent,

        display: "flex",
        justifyContent: "center",
    },

    cellWide: {
        flex: 4.056,
        height: "100%",
        textAlign: "center",
        padding: "5px 0",
        borderRight: 1,

        display: "flex",
        justifyContent: "center",
    },

    lastCell: {
        flex: 1,
        height: "100%",
        textAlign: "center",
        padding: "5px 0",

        display: "flex",
        justifyContent: "center",
    },

    leftAlignCell: {
        flex: 1,
        height: "100%",
        textAlign: "left",
        padding: "5px 0",
        borderRight: 1,

        display: "flex",
        justifyContent: "center",
    },

    rowGroup: {
        flex: 2,
        height: "100%",
        textAlign: "center",
        borderRight: 1,

        display: "flex",
        flexDirection: "column",
    },

    lastRowGroup: {
        flex: 1,
        height: "100%",
        textAlign: "center",

        display: "flex",
        flexDirection: "column",
    },

    columnGroup: {
        flex: 1,
        height: "100%",
        textAlign: "center",

        display: "flex",
        flexDirection: "row",
    },

    rowGroupRow: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        borderBottom: 1,
    },

    lastRowGroupRow: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
    },

    cellText: {
        margin: "0 5px",
    },

    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    },
    authorInfo: {
        position: "absolute",
        fontSize: 12,
        bottom: 15,
        left: 15,
        right: 0,
        textAlign: "left",
        color: "grey",
    },
    footerLine: {
        position: "absolute",
        bottom: 75,
        left: 20,
        width: "100%",
        height: "2px",
        marginTop: "5px",
        backgroundColor: "grey",
    },
    footer: {
        position: "absolute",
        bottom: 20,
        left: 20,
        fontSize: 8,
        display: "flex",
        color: "grey",
    },
});
export default styles;
