import React, { useState, useEffect } from "react";
import styles from "./styles";
import { FileButton } from "..";
import imagePlaceholder from "../../../assets/images/placeholder.jpg";
import { useField } from "formik";

const ImageFileButton = ({ name, className, disabled = false, label = "Image", ...otherProps }) => {
    const [field, meta, helpers] = useField(name);
    const [imgURL, setImgURL] = useState(imagePlaceholder);
    const classes = styles();

    const handleFileSelection = (e) => {
        if (!field.value && e.target.files.length <= 0) {
            helpers.setValue(null);
            return;
        } else if (e.target.files.length <= 0) {
            return;
        }

        const file = e.target.files[0];
        helpers.setTouched(true);
        helpers.setValue(file);
    };

    const handleClearSelection = () => {
        helpers.setValue(null);
        setImgURL(imagePlaceholder);
    };

    const config = {
        iconButton: true,
        className: `${classes.imageFileButton} ${className ?? ""}`,
        ...otherProps,
    };

    useEffect(() => {
        if (field.value && typeof field.value === "object") {
            const fr = new FileReader();
            fr.onload = (ev) => {
                setImgURL(ev.target.result);
            };
            fr.readAsDataURL(field.value);
        } else if (field.value && typeof field.value === "string") {
            setImgURL(field.value);
        }
    }, [field.value]);

    if (meta && meta.touched && meta.error) {
    }

    return (
        <div className={classes.imageFileButtonWrap}>
            <span className={classes.title}>{label}</span>
            {!disabled && (
                <FileButton
                    onChange={handleFileSelection}
                    onClear={handleClearSelection}
                    clearable={field.value !== null}
                    accept="images"
                    {...config}
                >
                    <img src={imgURL} alt={name} />
                </FileButton>
            )}
            {disabled && field.value && (
                <a href={imgURL} target="_blank" className={classes.imageLink}>
                    <img src={imgURL} alt={name} />
                </a>
            )}
            {disabled && !field.value && (
                <a className={classes.imageLink}>
                    <img src={imgURL} alt={name} />
                </a>
            )}
            <span>{meta.error}</span>
        </div>
    );
};

export default ImageFileButton;
