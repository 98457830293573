import { firestore as db } from "../firebase";
import { COLLECTIONS } from "./constants";

export const getCurrentTerms = async () => {
    try {
        return (await db.collection(COLLECTIONS.TERMS).where("valid_end", "==", null).get()).docs[0];
    } catch (error) {
        throw error;
    }
};
