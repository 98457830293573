import { makeStyles } from "@material-ui/core";
import React from "react";
import { Button, SuccessIcon, Title, TitleDescription } from "../../../core-ui/custom";

const styles = makeStyles({
    signUpSuccess: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        maxWidth: "640px",
    },
});
const SignUpSuccess = (props) => {
    const classes = styles();
    const { email, onClick } = props;

    return (
        <div className={classes.signUpSuccess}>
            <SuccessIcon className={classes.check} />
            <Title>Sign Up Success!</Title>
            <TitleDescription>
                A verification link has been sent to <b>{email}</b>. Please verify your account before you sign in.
            </TitleDescription>
            <Button onClick={onClick}>Sign In</Button>
        </div>
    );
};

export default SignUpSuccess;
