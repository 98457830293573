import React from "react";

//related to meterial ui package
import styles from "./styles";
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EnhancedTable from "../../../components-lagacy/Table/EnhancedTable";

const OutOfStockTableDialog = ({ open, onClose, rows, loading = false }) => {
    const TABLE_HEADERS = [
        { id: "id", label: "No.", hidden: true },
        { id: "outlet", label: "Outlet", textEllepsis: true },
        { id: "outletBranch", label: "Outlet Branch", textEllepsis: true },
        { id: "city", label: "City" },
        { id: "product", label: "Product", textEllepsis: true },
        { id: "date", label: "Date", disablePadding: true, style: { maxWidth: 30 } },
        { id: "shelf", label: "Shelf", disablePadding: true, style: { maxWidth: 30, textAlign: "center" } },
        {
            id: "extraDisplays",
            label: "Extra Displays",
            disablePadding: true,
            style: { maxWidth: 50, textAlign: "center" },
        },
        { id: "warehouse", label: "Warehouse", disablePadding: true, style: { maxWidth: 41, textAlign: "center" } },
    ];
    const classes = styles();

    return (
        <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose} className={classes.dialogRoot}>
            <DialogTitle disableTypography className={classes.dialogTitle}>
                <Typography className={classes.titleText} variant="h5">
                    Out Of Stock Details
                </Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent className={classes.cardContent}>
                <div className={classes.legends}>
                    <span>Out Of stock in all displays</span>
                    <span>Out Of stock in some displays</span>
                </div>
                <div className={classes.tableWrapper}>
                    <EnhancedTable
                        columns={TABLE_HEADERS}
                        rows={rows}
                        actions={[]}
                        updateSelected={() => {}}
                        loading={loading}
                        selectionDisabled
                        toolbarDisabled
                        // paginationDisabled
                        emptyMessage="No Out of Stock Products"
                        stickyHeader={true}
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default OutOfStockTableDialog;
