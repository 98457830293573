import * as Yup from "yup";

const englishRegex = /[A-Za-z]/;
const arabicRegex = /[\u0600-\u06FF]/;

const MAX_IMAGE_SIZE = 1_048_576; //1MB
const SUPPORTED_IMAGE_FORMATS = ["image/jpeg", "image/png", "image/gif"];

export const STEP_ONE_VALIDATION_SCHEMA = Yup.object().shape({
    first_name: Yup.string().max(15, "Must be 15 characters or less").required("Required"),
    last_name: Yup.string().max(20, "Must be 20 characters or less").required("Required"),
    phone_number: Yup.number().integer("Invalid phone number").positive("Invalid phone number").required("Required"),
    email: Yup.string(),
});

export const STEP_TWO_VALIDATION_SCHEMA = Yup.object().shape({
    company_name: Yup.string().max(50, "Must be 50 characters or less").required("Required"),
    company_short_name_en: Yup.string()
        .matches(englishRegex, "Must be in English letters")
        .max(15, "Must be 15 characters or less")
        .required("Required"),
    company_short_name_ar: Yup.string()
        .matches(arabicRegex, "Must be in Arabic letters")
        .max(15, "Must be 15 characters or less")
        .required("Required"),
    company_city: Yup.object().required("Required").shape({
        label: Yup.string(),
        value: Yup.string().required(),
    }),
    company_location: Yup.string(),
    company_website_link: Yup.string(),
    company_email: Yup.string().email("Invalid email"),
    company_phone_number: Yup.number().integer("Invalid phone number").positive("Invalid phone number"),
    company_fax_number: Yup.number().integer("Invalid fax number").positive("Invalid fax number"),
    company_logo_image: Yup.mixed()
        .test(
            "image-format",
            "Only allowed .jpg, .png, .gif formats",
            (value) => !value || SUPPORTED_IMAGE_FORMATS.includes(value?.type)
        )
        .test("image-size", "Max size: 1 MB", (value) => !value || value?.size <= MAX_IMAGE_SIZE),
});

export const STEP_THREE_VALIDATION_SCHEMA = Yup.object().shape({
    commercial_registry_number: Yup.number().positive().integer(),
    commercial_registry_expiry_date: Yup.date().nullable(),
    municipal_license_number: Yup.number().positive().integer(),
    vat_number: Yup.number().positive().integer(),
    vat_image: Yup.mixed()
        .test(
            "image-format",
            "Only allowed .jpg, .png, .gif formats",
            (value) => !value || SUPPORTED_IMAGE_FORMATS.includes(value?.type)
        )
        .test("image-size", "Max size: 1 MB", (value) => !value || value?.size <= MAX_IMAGE_SIZE),
    commercial_registry_image: Yup.mixed()
        .test(
            "image-format",
            "Only allowed .jpg, .png, .gif formats",
            (value) => !value || SUPPORTED_IMAGE_FORMATS.includes(value?.type)
        )
        .test("image-size", "Max size: 1 MB", (value) => !value || value?.size <= MAX_IMAGE_SIZE),
});

export const STEP_FOUR_VALIDATION_SCHEMA = Yup.object().shape({
    contact_first_name: Yup.string().max(15, "Must be 15 characters or less"),
    contact_last_name: Yup.string().max(20, "Must be 20 characters or less"),
    contact_mobile_number: Yup.number().integer("Invalid mobile number").positive("Invalid mobile number"),
    contact_phone_number: Yup.number().integer("Invalid phone number").positive("Invalid phone number"),
    contact_external_phone_number: Yup.number().integer("Invalid phone number").positive("Invalid phone number"),
    contact_email: Yup.string().email("Invalid Email"),
    job_title: Yup.string(),
});
