import { Fragment } from "react";
import PropTypes from "prop-types";

import styles from "../../style.module.css";

const Image = ({ url, title = "", style = {} }) => (
    <Fragment>
        <img className={styles["comparing-image"]} style={style} src={url} alt={title} />
        {!!title && (
            <span className={styles["cd-image-label"]} data-type="modified">
                {title}
            </span>
        )}
    </Fragment>
);

Image.propTypes = {
    url: PropTypes.string.isRequired,
    title: PropTypes.string,
};

Image.defaultProps = {
    title: "",
};

export default Image;
