import { makeStyles } from "@material-ui/styles";

const styles = makeStyles({
    gallery: {
        padding: 8,
        margin: "0 auto",
    },

    galleryHeader: {
        marginBottom: 8,
    },

    contentCard: {
        padding: 8,
        margin: "0 auto",
        maxWidth: "100%",
    },

    galleryContent: {
        marginTop: 20,
    },

    checkboxGrid: {
        flexBasis: 0,
        whiteSpace: "nowrap",
    },
});

export default styles;
