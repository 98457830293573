import { makeStyles } from "@material-ui/styles";
import { COLORS } from "../../constants/constants-lagacy";

const styles = makeStyles({
    section: {
        padding: "20px",
    },
    containerDiv: {
        textAlign: "center",
        padding: "7px",
    },
    gallery: {
        padding: 8,
        margin: "0 auto",
    },
    headerColor: {
        color: "#A9333A",
    },

    galleryHeader: {
        marginBottom: 8,
    },
    flexing: {
        display: "flex",
        margin: 0,
        padding: 0,
    },
    padding: {
        padding: "10px",
    },
    alignRight: {
        textAlign: "right",
        paddingRight: "7px",
    },

    merchName: {
        textAlign: "left",
        fontSize: "14px",
        color: COLORS.accent,
        lineHeight: 1.3,
        padding: "3px",
    },

    contentCard: {
        padding: 8,
        margin: "0 auto",
        maxWidth: "100%",
    },

    galleryContent: {
        marginTop: 20,
    },

    checkboxGrid: {
        flexBasis: 0,
        whiteSpace: "nowrap",
    },
    excelIcon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "400",
        cursor: "pointer",
        color: "white",
        backgroundColor: "#1A4D2E",
        borderRadius: "50%",
        width: "50px",
        height: "50px",
    },
    container: {
        overflowY: "hidden",
        whiteSpace: "nowrap",
        textAlign: "center",
        maxWidth: "720px",
        margin: "0 auto",
    },
    taskCol: {
        backgroundColor: "pink",
        margin: "0",
        padding: "0",
        border: "solid 1px",
    },

    // table styles
    boldFont: {
        fontWeight: "bold",
        color: "#1F4690",
    },

    tableContainer: {
        maxHeight: "700px",
        overflow: "auto",
        // minWidth: "560px",
        maxWidth: "600px",
    },
    table: {
        // minWidth: "560px",
        maxWidth: "100%",
        borderCollapse: "collapse",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: "10px",

        "& th": {
            // border: '1px solid black',
            border: "1px solid rgba(224, 224, 224,1)",
            color: COLORS.font,
            backgroundColor: COLORS.primary,
            // backgroundColor: "#8b8b8b",
            textAlign: "center",
            fontSize: "1.2em",
            // padding: "5px 7px",
            whiteSpace: "nowrap",
            zIndex: 100,
        },
        "& th:last-child": {
            paddingRight: 0,
        },
        "& td": {
            position: "relative",
            textAlign: "center",
            fontWeight: "700",
            fontSize: "1.2em",
            borderLeft: "2px solid rgba(224, 224, 224, 1)",
            borderRight: "2px solid rgba(224, 224, 224, 1)",
        },
        "& $textLeft": {
            textAlign: "left",
        },
    },
    textLeft: {
        textAlign: "left",
    },
    headerRow: {
        backgroundColor: COLORS.primary,
    },
    header: {
        position: "sticky",
        top: "0px",
    },
    indexHeader: { width: "30px" },
    dayHeader: { width: "134px" },
    dateHeader: { width: "100px" },
    timeHeader: { width: "90px" },
    // durationHeader:{width:"50px"},
    secondHeader: {
        width: "40px",
        position: "sticky",
        top: "25px",
    },
    grid: {
        marginBottom: "10px",
    },
    pdfButton: {
        marginTop: "12px",
        float: "right",
    },

    pdfIcon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "400",
        cursor: "pointer",
        color: "white",
        backgroundColor: COLORS.accent,
        borderRadius: "50%",
        width: "50px",
        height: "50px",
    },
    pdfDiv: {
        display: "inline",
        textAlign: "right",
        position: "relative",
        bottom: "2.5em",
        right: "15.6em",
        marginLeft: "10px",
    },

    imageIcon: {
        color: "#606060",
        cursor: "pointer",
        position: "absolute",
        right: 0,
        "&:hover": {
            color: "#1a1a1a",
        },
    },
    blueCell: {},
    cellItem: {
        textAlign: "center",
        border: "solid 1px",
    },
    rowEven: {
        backgroundColor: "#E8F9FD",
    },
});

export default styles;
